import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type MeasurementType = {
  label: string;
  value: string;
  type: string;
};

type MeasurementResponseType = {
  typesOfMeasurementsLabel: MeasurementType[];
};
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetMeasurementTypes(options = {}) {
  return useQuery({
    queryKey: ["measurementTypes"],
    queryFn: async () => {
      await delay(1000); 
      return axiosInstance
        .get("/types-of-measurements")
        .then((res) => res.data);
    },
    select: (data: MeasurementResponseType) => data.typesOfMeasurementsLabel,
    retry: false,
    ...options,
    staleTime: 0, 
  });
}
