import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type windowAdittionLabel = {
  type: string;    
  label: string;   
  value: string;   
};

export default function useWindowAddition(options = {}) {
  return useQuery({
    queryKey: ["windowAddition"],
    queryFn: async () => {
      return axiosInstance
        .get("/window-addition")
        .then((res) => res.data);
    },
    select: (data: { windowAdittionLabel: windowAdittionLabel[] }) => data.windowAdittionLabel,
    retry: false,
    ...options,
  });
}