import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { BlindsInstallationType } from "types/MeasurementTypes";

export default function useGetBlindsInstallationTypes(options = {}) {
  return useQuery({
    queryKey: ["blindsInstallationTypes"],
    queryFn: async () => {
      return axiosInstance
        .get("/types-of-blinds-installation")
        .then((res) => res.data);
    },
    select: (data: { typesOfBlindsInstallationLabel: BlindsInstallationType[] }) => data.typesOfBlindsInstallationLabel,
    retry: false,
    ...options,
  });
}