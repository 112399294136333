import { OrderStatus, Button } from "components";
import { ROUTE_ENUM } from "enums";

const isInstallationToBeReleased = (status: string) =>
  status === "installationToBeReleased" && true;

const handleButtonClick = (
  e: any,
  record: any,
  navigate: any,
  setSearchParams: any,
  page: number | string,
  isMeasurer: boolean,
  activeFloatingMenuItem: any
) => {
  e.stopPropagation();
  setSearchParams({
    page,
    uuid: record.uuid,
    tab: activeFloatingMenuItem.value,
  });
  navigate(
    `${
      isMeasurer ? ROUTE_ENUM.ACTIVE_ORDER : ROUTE_ENUM.ARRANGING_MEASUREMENT
    }?${
      isMeasurer
        ? `orderUuid=${record.uuid}&page=${page}&tab=${activeFloatingMenuItem.value}`
        : `uuid=${record.uuid}&tab=${activeFloatingMenuItem.value}`
    }`
  );
};

export const desktopColumns = (
  navigate: any,
  setSearchParams: any,
  page: any,
  isMeasurer: boolean,
  activeFloatingMenuItem: any
) => [
  {
    title: "Nazwa zlecenia",
    dataIndex: "name",
    key: "name",
    width: 250,
  },
  {
    title: "Imię i nazwisko klienta",
    dataIndex: "clientName",
    key: "clientName",
    width: 220,
  },
  {
    title: "Telefon",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    width: 152,
  },
  {
    title: "Adres",
    dataIndex: "address",
    key: "address",
    width: 276,
  },
  {
    title: "Status",
    width: 212,
    render: (original: any) => (
      <div className="order-status__table">
        <OrderStatus status={original.status} />
        {isInstallationToBeReleased(original.status) && (
          <div className="order-status__counters">
            {original.arrangedInstallation}/{original.allInstallation}
          </div>
        )}
      </div>
    ),
  },
  {
    title: "Akcje",
    width: 144,
    render: (record: any) => {
      const isStatusFixedMeasurements = record.status === "fixedMeasurements";
      return (
        <Button
          onClick={(e: any) =>
            handleButtonClick(
              e,
              record,
              navigate,
              setSearchParams,
              page,
              isMeasurer,
              activeFloatingMenuItem
            )
          }
          className="order-status__action-btn"
          label={
            isMeasurer
              ? "Wykonaj"
              : isStatusFixedMeasurements
              ? "Edytuj"
              : "Umów"
          }
          size="small"
        />
      );
    },
  },
];

export const mobileColumns = (
  navigate: any,
  setSearchParams: any,
  page: any,
  isMeasurer: boolean,
  activeFloatingMenuItem: any
) => [
  {
    dataIndex: "status",
    key: "status",
    width: 20,
    render: (original: any) => <OrderStatus status={original.status} />,
  },
  {
    dataIndex: "name",
    key: "name",
    width: 350,
  },
  {
    width: 144,
    render: (record: any) => {
      const isStatusFixedMeasurements = record.status === "fixedMeasurements";

      return (
        <Button
          onClick={(e: any) =>
            handleButtonClick(
              e,
              record,
              navigate,
              setSearchParams,
              page,
              isMeasurer,
              activeFloatingMenuItem
            )
          }
          className="order-status__action-btn"
          label={
            isMeasurer
              ? "Wykonaj"
              : isStatusFixedMeasurements
              ? "Edytuj"
              : "Umów"
          }
          size="small"
        />
      );
    },
  },
];
