import "./user-status.scss";
import classNames from "classnames";

interface UserStatusType {
  status: string;
}

const UserStatus = ({ status }: UserStatusType) => {
  const circleClass = classNames("user-status__circle", {
    "user-status__circle--active": status === "active",
    "user-status__circle--blocked": status === "suspended",
    "user-status__circle--inactive": status === "inactive",
  });

  return (
    <div className="user-status">
      <span className="user-status__label">
        {status === "active" && "Aktywny"}
        {status === "suspended" && "Zablokowany"}
        {status === "inactive" && "Nieaktywny"}
      </span>
      <div className="user-status__circle-wrapper">
        <div className={circleClass} />
      </div>
    </div>
  );
};

export default UserStatus;
