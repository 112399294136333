import { useState } from "react";
import {
  Button,
  Modal,
  PasswordStrengthChecker,
  PasswordValidation,
  Input,
} from "components";
import "./change-password.scss";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { VALIDATION_MESSAGES } from "enums";
import { useResetAdminPassword } from "hooks/auth";

interface ChangePasswordType {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePassword = ({ isOpen, onClose }: ChangePasswordType) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const resetPassword = useResetAdminPassword({
    onSuccess: () => {
      onClose();
    },
  });

  const handleChangePassword = async ({
    currentPassword,
    newPassword,
  }: any) => {
    resetPassword.mutate({ currentPassword, newPassword });
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED),
    newPassword: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED),
    confirm_password: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED)
      .oneOf([Yup.ref("newPassword"), ""], VALIDATION_MESSAGES.PASSWORD_MATCH),
  });

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirm_password: "",
  };

  return (
    <>
      <Modal
        className="cpm"
        side="right"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">Zmiana hasła</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleChangePassword(values)}
          >
            {({ handleSubmit, errors, isSubmitting, values }) => (
              <form
                className="set-admin-password__form cpm__form"
                onSubmit={handleSubmit}
              >
                <Field
                  type="password"
                  label="Aktualne hasło"
                  name="currentPassword"
                  component={Input}
                  errors={errors}
                  onBlur={() => isPasswordValid && setShowValidation(false)}
                />
                <Field
                  type="password"
                  label="Nowe hasło"
                  name="newPassword"
                  component={Input}
                  errors={errors}
                  inputOnFocus={() => setShowValidation(true)}
                  onBlur={() => isPasswordValid && setShowValidation(false)}
                />
                <PasswordStrengthChecker
                  isHidden={!showValidation}
                  password={values.newPassword}
                />
                <PasswordValidation
                  className={` ${
                    showValidation ? "validation-item__wrapper--show" : ""
                  }`}
                  password={values.newPassword}
                  setIsValid={(value: boolean) => setIsPasswordValid(value)}
                />
                <Field
                  type="password"
                  label="Powtórz nowe hasło"
                  name="confirm_password"
                  component={Input}
                  errorBelow
                  errors={errors}
                  wrapperClassName="white form__field--gray"
                />
                <div className="modal-buttons">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button label="Potwierdź" type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default ChangePassword;
