import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { Toast } from "components"; 
import { TOAST_TYPES_ENUM } from 'enums'; 

type ResetAdminPasswordType = {
  currentPassword: string;
  newPassword: string;
};

const resetAdminPassword = async (
  credentials: ResetAdminPasswordType
): Promise<any> => {
  const { data } = await axiosConfig.put(`/users/admin/password/reset`, credentials);
  return data;
};

export const useResetAdminPassword = (
  options?: UseMutationOptions<any, Error, ResetAdminPasswordType>
) => {
  return useMutation<any, Error, ResetAdminPasswordType, unknown>({
    mutationKey: ["admin-password-reset"],
    mutationFn: resetAdminPassword,
    ...options,

    onMutate: async (variables) => {
      Toast({
        message: "Trwa zmiana hasła...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "admin-password-reset"
      });
      return options?.onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Hasło zostało zmienione.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "admin-password-reset"
      });
      return options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Nie udało się zmienić hasła.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "admin-password-reset",
      });
      return options?.onError?.( error, variables, context);
    },
  });
};
