/* eslint-disable react-hooks/exhaustive-deps */
import "./input.scss";
import { useState, useEffect } from "react";
import { FieldProps } from "formik";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import classNames from "classnames";

interface InputProps {
  label?: string;
  type: string;
  variant?: string;
  icon?: string;
  inputOnFocus?: any;
  maxLength?: number;
  placeholder?: string;
  rightPlaceholder?: string;
  initialValue?: any;
  bin?: boolean;
  binNumber?: number;
  onKeyDown: (e: any) => void;
  optional?: boolean;
  withDot?: boolean;
  size?: "large" | "small" | "medium";

  // formik;
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (data: any) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  onFocus: any;
  onBlur?: any;
  inputRef?: any;
}

const Input = ({
  label,
  bin = false,
  errors = {},
  form,
  field,
  variant,
  type,
  icon,
  inputOnFocus,
  onBlur,
  maxLength,
  onChange,
  placeholder,
  rightPlaceholder,
  initialValue = null,
  optional = false,
  withDot = false,
  inputRef,
  binNumber = 1,
  size = "large",
  onKeyDown,
}: InputProps & FieldProps) => {
  const [inputFirstPart, setInputFirstPart] = useState<any>("");

  useEffect(() => {
    if (inputRef && inputRef.current && field?.value === "") {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === "password";
  const isArrayField = field.name.includes(".");
  const dotIndex = field.name.indexOf(".");
  function removeNonNumericChars(inputString: any) {
    return inputString.replace(/\D/g, "");
  }
  const elementIndex = field.name.substring(dotIndex + 1);
  const fieldName = field.name.substring(0, dotIndex);

  useEffect(() => {
    if (isArrayField) {
      const parts = field.name.split(".");
      if (parts.length > 2) {
        const numberPart = parts[1];
        const textPart = parts.slice(2).join(".");
        setInputFirstPart(form.values[fieldName]?.[numberPart][textPart] || "");
      } else {
        setInputFirstPart(form.values[fieldName]?.[elementIndex] || "");
      }
    } else {
      setInputFirstPart(
        initialValue && type === "number"
          ? parseInt(initialValue, 10)
          : initialValue || (field.value && type === "number")
          ? parseInt(field.value, 10)
          : field.value || ""
      );
    }
  }, [form.values[fieldName], initialValue, field.value]);

  const handleDeleteItem = () => {
    const updatedArray = [...form.values[fieldName]];
    const afterRemoveString = removeNonNumericChars(elementIndex);
    if (afterRemoveString >= 0 && afterRemoveString < updatedArray.length) {
      updatedArray.splice(afterRemoveString, 1);
      form.setFieldValue(fieldName, updatedArray);
    }
  };

  function showError() {
    if (isArrayField) {
      if (dotIndex !== -1) {
        if (elementIndex.includes(".")) {
          const afterRemoveString = removeNonNumericChars(elementIndex);
          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][afterRemoveString] && (
                  <span>
                    {errors[fieldName] &&
                      errors[fieldName][afterRemoveString] &&
                      errors[fieldName][afterRemoveString].text}
                  </span>
                )}
            </div>
          );
        } else {
          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][elementIndex] && (
                  <span>
                    {errors[fieldName] && errors[fieldName][elementIndex]}
                  </span>
                )}
            </div>
          );
        }
      } else {
        return null;
      }
    } else if (errors && form.touched) {
      return (
        <div className="input__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      );
    }
  }

  const handleFirstPartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (
      (value.length > 0 && value.length > 1 && value.startsWith("0")) ||
      value === "0"
    ) {
      return;
    }

    if (withDot) {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    } else if (type === "number") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }
    form.setFieldValue(field.name, value);
    setInputFirstPart(value);
  };

  const inputClass = classNames("input", {
    "input--with-icon": variant === "password" || icon,
  });

  const inputIcon = () => {
    return (
      <>
        {bin && form.values[fieldName].length > binNumber && (
          <div
            onClick={() => handleDeleteItem()}
            className="input__icon input__icon--pointer"
          >
            <SVG type={SVG_TYPE.BIN} />
          </div>
        )}
        {icon && (
          <div className="input__icon">
            <SVG type={icon} />
          </div>
        )}
      </>
    );
  };

  const passwordIcon = () => {
    return (
      <>
        {isPassword ? (
          <span
            className="input__eye"
            onClick={() => setShowPassword(!showPassword)}
          >
            <SVG type={showPassword ? SVG_TYPE.EYE : SVG_TYPE.EYE_OFF} />
          </span>
        ) : null}
      </>
    );
  };

  return (
    <div className="input__wrapper">
      {label && (
        <label className="input__label">
          {label}{" "}
          {optional ? (
            <span className="input__optional">(opcjonalnie)</span>
          ) : (
            ""
          )}
        </label>
      )}
      <div className="input__content">
        <input
          ref={inputRef}
          {...field}
          className={inputClass}
          name={field.name}
          onFocus={() => inputOnFocus && inputOnFocus()}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          onChange={(e) => {
            handleFirstPartChange(e);
            onChange && onChange(e);
          }}
          maxLength={
            maxLength ? maxLength : rightPlaceholder === "mm" ? 6 : maxLength
          }
          id={field.name}
          onBlur={(e) => onBlur && onBlur(e)}
          value={inputFirstPart}
          placeholder={placeholder}
          type={
            isPassword
              ? showPassword
                ? "text"
                : "password"
              : type === "number"
              ? "tel"
              : type
          }
        />
        {rightPlaceholder && (
          <div className="input__placeholder-right">{rightPlaceholder}</div>
        )}
        {inputIcon()}
        {passwordIcon()}
      </div>
      {showError()}
    </div>
  );
};

export default Input;
