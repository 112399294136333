import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Button, Textarea } from "components";
import { ExtraWork } from "features/addingMeasurement/Form";
import { VALIDATION_MESSAGES } from "enums";
import { usePutExternalDoorsClientInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";
import { TypeOfInstalation, SpoutLevel } from "features/addingMeasurement";
import "./floor-general-information.scss";

interface ExternalDoorInRowGeneralInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const ExternalDoorInRowGeneralInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: ExternalDoorInRowGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementClientInfo = measurementInfo?.measurementClientInfo;

  const { mutate: externalDoorClientInfo } = usePutExternalDoorsClientInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
    onError: () => {
      console.error("Błąd podczas aktualizacji danych klienta.");
    },
  });

  const validationSchema = Yup.object().shape({
    spoutLevel: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test({
        name: "spoutLevel-conditions",
        exclusive: true,
        message: VALIDATION_MESSAGES.REQUIRED,
        test: function (value) {
          if (value === "measuredFromStairLevel") {
            return !!this.parent.typeOfStars && !!this.parent.starsMeasurement;
          }
          return true;
        },
      }),
    typeOfInstallationUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value))
        )
      )
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "has-at-least-one-loose-string",
        VALIDATION_MESSAGES.REQUIRED,
        (array) => array.some((item) => typeof item === "string")
      ),
  });

  const handleOnSubmit = (values: any) => {
    if (values.spoutLevel !== "measuredFromStairLevel") {
      delete values.typeOfStars;
      delete values.starsMeasurement;
    }
    delete values.constructionSiteComments;
    externalDoorClientInfo(values);
  };

  const typeOfInstallationComments =
    initialMeasurementClientInfo?.typeOfInstallationUuids?.find(
      (item: any) => item.comments
    )?.comments;

  type AnyObject = { [key: string]: any };

  const replaceCommentsKey = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map((item: any) => replaceCommentsKey(item));
    } else if (typeof obj === "object" && obj !== null) {
      return Object.entries(obj).reduce((acc: AnyObject, [key, value]) => {
        acc[key === "comments" ? "comment" : key] = replaceCommentsKey(value);
        return acc;
      }, {} as AnyObject);
    }
    return obj;
  };

  return (
    <div>
      <div className="am-heading">Informacje ogólne</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          typeOfInstallationUuids:
            initialMeasurementClientInfo?.typeOfInstallationUuids
              ? replaceCommentsKey(
                  initialMeasurementClientInfo?.typeOfInstallationUuids
                )
              : [],
          typeOfInstallationComments: typeOfInstallationComments || "",
          spoutLevel: initialMeasurementClientInfo?.spoutLevel || "",
          typeOfStars: initialMeasurementClientInfo?.typeOfStars || "",
          starsMeasurement:
            initialMeasurementClientInfo?.starsMeasurement || "",
          preparationOfOpeningsForTripleLayerInstallation:
            initialMeasurementClientInfo?.preparationOfOpeningsForTripleLayerInstallation ||
            "nobody",
          changeOfOpeningDimensions:
            initialMeasurementClientInfo?.changeOfOpeningDimensions || "nobody",
          constructionSiteCleanupInvestor:
            initialMeasurementClientInfo?.constructionSiteCleanupInvestor ||
            false,
          comments: initialMeasurementClientInfo?.comments || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form
              className="amfs__form form floor-general-information"
              onSubmit={handleSubmit}
            >
              <ScrollToError />
              <Field
                values={values}
                errors={errors}
                label="Nazwa materiału"
                name="typeOfInstallationUuids"
                component={TypeOfInstalation}
                type={"externalDoorsInRawCondition"}
              />
              <Field errors={errors} name="spoutLevel" component={SpoutLevel} />
              <Field values={values} externalDoor component={ExtraWork} />
              <div className={`am-separator am-separator--no-margin-top`} />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ExternalDoorInRowGeneralInformation;
