/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, SVG } from "components";
import { UserNameAndBadge } from "features/employeeRates";
import "./user-history-rate.scss";
import { SVG_TYPE } from "enums";
import moment from "moment";

interface UserHistoryRateType {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  userRates: any;
}

const UserHistoryRate = ({
  isOpen,
  onClose,
  user,
  userRates,
}: UserHistoryRateType) => {
  return (
    <>
      <Modal
        className="user-history-rate user-history-rate__modal"
        side="right"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading__with-close">
            <div className="modal-heading">Historia stawek</div>
            <div className="modal-heading__close" onClick={() => onClose()}>
              Zamknij <SVG type={SVG_TYPE.CLOSE} />
            </div>
          </div>
          <div>
            <UserNameAndBadge
              className="user-history-rate__user-badge"
              user={user}
            />
          </div>
          <div className="user-history-rate-list">
            {userRates?.map((item: any, index: any) => {
              const isNow = item.toDate === "now";
              return (
                <div
                  key={`${item.toDate}${index}`}
                  className="user-history-rate-list-item"
                >
                  <div className="user-history-rate-list-item__left">
                    {isNow
                      ? `od ${item.fromDate}`
                      : `${item.fromDate} - ${moment(
                          item.toDate,
                          "D/M/YYYY"
                        ).format("DD/MM/YYYY")}`}
                  </div>
                  <div className="user-history-rate-list-item__right">
                    {item.rate} zł/h
                  </div>
                </div>
              );
            })}
          </div>
          <div className="modal-buttons">
            <Button label="zamknij" onClick={() => onClose()} />
          </div>
        </>
      </Modal>
    </>
  );
};

export default UserHistoryRate;
