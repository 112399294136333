import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { OrderType } from "types/OrderTypes"; 


export default function useGetOrderDetails(uuid: string | null, isAvailable: boolean = false) {
  return useQuery({
    queryKey: ["orderDetails", uuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/orders/${uuid}`)
        .then((res) => res.data);
    },
    select: (data: { order: OrderType }) => data?.order,
    retry: false,
    enabled: !!uuid && !!isAvailable,
  });
}