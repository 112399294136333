import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type GeneralInfoData = {
  measurementUuid: string;
  typeOfInstallationUuids: Array<{ uuid: string, comment?: string }>;
  spoutLevel: 'fromTheProject' | 'specifiedByInvestor' | 'specifiedByContractor' | 'measuredFromStairLevel';
  typeOfStars?: 'concrete' | 'selfSupporting';
  starsMeasurement?: string;
  roomListUuids: Array<{ uuid: string, measurement: string }>;
  kitchenWindowConnectedToTheCountertop: boolean;
  kitchenWindowConnectedToTheCountertopMeasurement?: string;
  doesTheClientPlanBlindsShutters: boolean;
  typeOfBlindInstallationUuids?: Array<{ uuid: string, comment?: string }>;
  extraWorkUuids?: any 
};

const usePutGenerallInfoMeasurement = (
  options?: UseMutationOptions<any, Error, GeneralInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editGeneralInfo'],
    mutationFn: async (data) => {
      return axiosInstance.put(`/measurement/client-info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych pomiarowych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-client-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane pomiaru zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-client-info',
      });
      queryClient.invalidateQueries({ queryKey: ['clientInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych pomiaru.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-client-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutGenerallInfoMeasurement;
