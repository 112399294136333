import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { PhoneNumberTypes } from 'types/AuthTypes';

const sendSms = async (credentials: PhoneNumberTypes) => {
  const { phoneNumber, phonePrefixUuid } = credentials;

  const response = await axiosConfig.get(`/auth/get-sms`, {
    params: {
      phoneNumber,
      phonePrefixUuid,
    },
  });
  return response.data;
};

export const useSendSms = (options?: UseMutationOptions<any, Error, PhoneNumberTypes, unknown>) => {
  return useMutation({
    ...options,
    mutationKey: ['sendSms'],
    mutationFn: sendSms,
    onMutate: async (variables) => {
      Toast({
        message: 'Wysyłanie SMS w toku...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'send-sms',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'SMS wysłany pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'send-sms',
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas wysyłania SMS.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'send-sms',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
