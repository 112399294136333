import { Page, PageContent, UserAccessPanel, Button } from "components";
import { ROUTE_ENUM } from "enums";
import { usePasswordRecovery } from "hooks/auth/PasswordRecoveryHook";
import "./send-restart-password.scss";

const SendRestartPassword = () => {
  const { mutate: recoverPassword } = usePasswordRecovery();
  const recoveryPasswordEmail = sessionStorage.getItem("recoveryPasswordEmail");

  const handleSendLink = () => {
    recoveryPasswordEmail && recoverPassword({ email: recoveryPasswordEmail });
  };

  return (
    <Page>
      <PageContent>
        <UserAccessPanel
          goBack={ROUTE_ENUM.LOGIN}
          heading="Wysłano link do resetu hasła"
        >
          <div className="uap__description send-restart-password__description">
            Jeśli podany przez Ciebie adres Email jest zgodny z adresem
            wprowadzonym przy zakładaniu konta, to powinieneś otrzymać wiadomość
            na swoją skrzynkę mailową.
          </div>

          <div className="uap__bold-paragraph send-restart-password__bold">
            W celu zresetowania hasła, kliknij w przesłany link.
          </div>
          <div className="uap__description send-restart-password__description send-restart-password--link">
            link nie dotarł?
          </div>
          <div className="uap__button">
            <Button
              onClick={() => handleSendLink()}
              label="Wyślij link ponownie"
            />
          </div>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default SendRestartPassword;
