import { Input, Select, Textarea } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
} from "features/addingMeasurement";

interface TiltingGateFormType {
  touched: any;
  errors: any;
  validateField: any;
}

const TiltingGateForm = ({
  touched,
  errors,
  validateField,
}: TiltingGateFormType) => {
  return (
    <>
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips"
        touched={touched}
        name="roomName"
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="holeSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="holeSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          label="Sposób otwierania"
          name="open"
          type="number"
          options={[
            {
              value: "hands",
              label: "Ręczna",
            },
            {
              value: "automatic",
              label: "Automatyczna",
            },
          ]}
          component={Select}
          white
        />
        <Field
          label="Rodzaj szyny"
          name="open"
          type="number"
          options={[
            {
              value: "chain",
              label: "Łańcuch",
            },
            {
              value: "nosymetric",
              label: "Pasek",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          label="Typ bramy"
          name="open"
          type="number"
          options={[
            {
              value: "ocieplona",
              label: "Ocieplona",
            },
            {
              value: "nieocieplona",
              label: "Nieocieplona",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label={`Kolor`}
          name={`color`}
          optional
          component={Input}
        />
      </SingleSectionElement>
      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        name="comment"
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default TiltingGateForm;
