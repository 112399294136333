import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig'; 
import { Toast } from 'components'; 
import { TOAST_TYPES_ENUM } from 'enums'; 
import { queryClient } from 'App'; 


type EditUserRateType = {
  uuid: string;
  rate: number;
  date: string; 
};

const useEditEmployeeRate = (
  options?: UseMutationOptions<any, Error, EditUserRateType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editUserRate'],
    mutationFn: async ({ uuid, rate, date }) => {
      return axiosInstance.patch(`/users/rates/${uuid}`, {
        rate,
        date,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania edycji stawki użytkownika...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-user-rate',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Stawka użytkownika zaktualizowana pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-user-rate',
      });
      queryClient.invalidateQueries({ queryKey: ['userRates'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji stawki użytkownika.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-user-rate',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useEditEmployeeRate;
