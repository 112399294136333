import { Button, Modal } from "components";
import { useDeleteUser } from "hooks/users";

interface DeleteUserType {
  user: any;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteUser = ({ isOpen, onClose, user }: DeleteUserType) => {
  const { mutate: deleteUser } = useDeleteUser();

  const handleDeleteUser = async (uuid: string) => {
    await deleteUser({ uuid });
    setTimeout(() => {
      const element = document.querySelector(".rc-table-row-level-0");
      if (!element) {
        document.getElementById("pagination-last-page")?.click();
      }
    }, 400);
    onClose();
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Usuwanie użytkownika</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć użytkownika
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button
              onClick={() => handleDeleteUser(user.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteUser;
