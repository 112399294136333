import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { queryClient } from 'App';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';


interface UploadFileData {
  parentUuid: string;
  type: string;
  files: File[];
}

const useUploadFile = (
  options?: UseMutationOptions<any, Error, UploadFileData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['uploadFile'],
    mutationFn: async (data: UploadFileData) => {
      const formData = new FormData();
      data.files.forEach((file) => {
        formData.append('files', file);
      });

      const params = new URLSearchParams({
        parentUuid: data.parentUuid,
        type: data.type,
      });

      return axiosInstance.post(`/files?${params.toString()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Trwa przetwarzanie pliku.',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'send-file',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
      
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['measurementInfo', variables.parentUuid] });
      Toast({
        message: 'Plik został zapisany',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'send-file',
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Plik nie został zapisany',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'send-file',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUploadFile;
