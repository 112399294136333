import { USER_ROLE } from "enums"

const employeeRoleOptions = [
  {
    label: 'Grafikowiec',
    value: USER_ROLE.scheduleManager,
  },
  {
    label: 'Pomiarowiec',
    value: USER_ROLE.measurer,
  },
  {
    label: 'Monter',
    value: USER_ROLE.fitter,
  },  
  {
    label: 'Doradca',
    value: USER_ROLE.advisor,
  },
]

export default employeeRoleOptions;