enum MEASUREMENTS_TYPE {
  WINDOWS_IN_ROW = "windowsInRawCondition",
  WINDOWS_REPLACEMENT = "windowsReplacement",
  EXTERNAL_DOORS_IN_ROW = "externalDoorsInRawCondition",
  EXTERNAL_DOORS_REPLACEMENT = "externalDoorsReplacement",
  GARAGE_DOOR = "garageDoor",
  ROLLER_BLINDS_AND_BLINDS = "rollerBlindsAndBlinds",
  WINDOW_SILLS = "windowSills",
  INTERIOR_DOORS = "interiorDoors",
  FLOORS = "floors",
  SKIRTING_BOARDS = "skirtingBoards",
}

export default MEASUREMENTS_TYPE;
