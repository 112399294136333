import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { MountingAccessoriesType } from 'types/MeasurementTypes'; 

export default function useGetMountingAccessories(types: any) {
  return useQuery({
    queryKey: ['mountingAccessories', types],
    queryFn: async () => {
      return axiosInstance
        .get(`/installation-elements?types=${types}`,)
        .then((res) => res.data);
    },
    select: (data: { installationElementsLabel: MountingAccessoriesType[]}) => data.installationElementsLabel,
    retry: false,
  });
}