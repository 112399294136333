import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';

export default function useGetTimeManagement(orderUuid: string | null, options = {}) {
  return useQuery({
    queryKey: ['timeManagement', orderUuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/activity/time-management?orderUuid=${orderUuid}`)
        .then((res) => res.data);
    },
    select: (data: {activityMeasurementLabel:any[]}) => data.activityMeasurementLabel,
    retry: false,
    enabled: !!orderUuid,
    ...options,
  });
}