import { Button, Input, SVG } from "components";
import { FieldArray, Field } from "formik";
import "./floor-area.scss";
import { SVG_TYPE } from "enums";

interface FloorAreaType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
}

const FloorArea = ({ errors, form, validateField, field }: FloorAreaType) => {
  return (
    <div className="hole-dimension floor-area">
      <div>
        <FieldArray
          name="floorArea"
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading">
                <h3>Powierzchnia podłogi</h3>
              </div>
              <div className="floor-area__equation">
                <>
                  <Field
                    errors={errors}
                    maxLength={30}
                    rightPlaceholder="m2"
                    name="test"
                    type="number"
                    component={Input}
                  />
                  <span className="plus">
                    <SVG type={SVG_TYPE.PLUS} />
                  </span>
                  <Field
                    errors={errors}
                    maxLength={30}
                    name="test2"
                    rightPlaceholder="m2"
                    type="number"
                    component={Input}
                  />
                </>
                {form.values?.floorArea.map((_: any, index: any, id: any) => (
                  <>
                    <span>
                      <SVG type={SVG_TYPE.PLUS} />
                    </span>
                    <Field
                      errors={errors}
                      key={index}
                      maxLength={30}
                      name={`floorArea_rodzaj.${index}`}
                      rightPlaceholder="m2"
                      type="number"
                      component={Input}
                    />
                  </>
                ))}
                <span>
                  <SVG type={SVG_TYPE.EQUAL} />
                </span>
                <Field
                  errors={errors}
                  maxLength={30}
                  rightPlaceholder="m2"
                  type="number"
                  name={`equal`}
                  component={Input}
                />
              </div>
              <Button
                stroke
                label="Dodaj kolejną"
                size="small"
                className="door-sills__add-button"
                onClick={() => {
                  arrayHelpers.push("");
                }}
              />
            </div>
          )}
        />
        <div className="floor-area__ended">
          <Field
            label="Zapas"
            errors={errors}
            maxLength={2}
            rightPlaceholder="%"
            name="test"
            type="number"
            component={Input}
          />
          <Field
            label="Powierzchnia podłogi z zapasem"
            errors={errors}
            maxLength={2}
            rightPlaceholder="%"
            name="test"
            type="number"
            component={Input}
          />
        </div>
        <div className="am-separator" />
      </div>
    </div>
  );
};

export default FloorArea;
