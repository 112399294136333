import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type AddActivityTimeData = {
  dateStart: string;
  dateEnd: string;
  orderUuid: string;
  activityUuid: string;
  comment?: string;
};

type AddActivityTimeResponse = {
  uuid: string;
  dateStart: string;
  dateEnd: string;
  countingTime: boolean;
  comment?: string;
  countedTime: string;
  firstDate: string;
  activityName?: string;
};

const useAddActivityTime = (
  options?: UseMutationOptions<AddActivityTimeResponse, Error, AddActivityTimeData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['addActivityTime'],
    mutationFn: async (data: AddActivityTimeData) => {
      return axiosInstance.post(`/activity/time`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: `Dodawanie czasu aktywności...`,
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'add-activity-time',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: `Czas aktywności został dodany pomyślnie.`,
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'add-activity-time',
      });
      queryClient.invalidateQueries({ queryKey: ['timeManagement'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: `Błąd podczas dodawania czasu aktywności.`,
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'add-activity-time',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddActivityTime;
