import "./user-badge.scss";
import { roleInfo } from "enums/userEnum";

type RoleKeys = keyof typeof roleInfo;

interface UserBadgeType {
  role?: RoleKeys;
  status?: string;
}

const UserBadge = ({ role, status }: UserBadgeType) => {
  const firstLetterRole = role && roleInfo[role]?.jobTitle[0];
  const restOfTheTitleRole = role && roleInfo[role]?.jobTitle.slice(1);

  const firstLetterStatus = status && status.slice(0, 1);
  const colorBadge = () => {
    if (role) {
      return `${roleInfo[role]?.color}`;
    } else if (status === "Pomiar") {
      return "#38D58B";
    } else {
      return "#FFBDCC";
    }
  };

  return (
    <>
      <div style={{ backgroundColor: colorBadge() }} className="user-badge">
        <span className="user-badge__first-letter">
          {firstLetterRole}
          {firstLetterStatus}
        </span>
        <span className="user-badge__rest-of-title">
          {role && restOfTheTitleRole}
        </span>
      </div>
    </>
  );
};

export default UserBadge;
