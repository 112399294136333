/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./supplier-table.scss";
import Table from "rc-table";
import { isMobile } from "utils";
import { Pagination } from "components";
import { mobileColumns, desktopColumns } from "./utils";
import { useGetSuppliers } from "hooks/supplier";
import { useSearchParams } from "react-router-dom";

interface SupplierTableType {
  floatingMenuItem: { value: string; label: string };
  editSupplier: (material: any) => void;
  deleteSupplier: (material: any) => void;
  page: number;
  setPage: (page: number) => void;
}

const SupplierTable = ({
  floatingMenuItem,
  editSupplier,
  deleteSupplier,
  page,
  setPage,
}: SupplierTableType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, refetch } = useGetSuppliers({ page });

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("tab", floatingMenuItem.value);
    // newSearchParams.set("page", pageInLink || "1");
    setSearchParams(newSearchParams, { replace: true });
    refetch();
  }, [floatingMenuItem.value]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("tab", floatingMenuItem.value);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams, { replace: true });
  }, [page]);

  const isLastElement = data?.data.length === 1;

  return (
    <div className="users-table">
      <Table
        rowKey="id"
        className="user-table"
        data={data?.data}
        columns={
          isMobile()
            ? mobileColumns(editSupplier, deleteSupplier)
            : desktopColumns(
                data?.meta?.currentPage || 0,
                data?.meta?.perPage || 0,
                editSupplier,
                deleteSupplier,
                isLastElement
              )
        }
      />

      {data && data.data.length === 0 && (
        <div className="users-table__no-data">
          <div className="users-table__no-data--paragraph">
            Nie dodano jeszcze żadnych Dostawców.
          </div>
          <div className="users-table__no-data--paragraph">
            Kliknij w przycisk “Dodaj” i wprowadź pierwszego dostawcę.
          </div>
        </div>
      )}
      {data?.meta && data?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={data?.meta?.lastPage}
          currentPage={data?.meta?.currentPage || page}
          onPageChange={(e) => setPage(e)}
        />
      )}
    </div>
  );
};

export default SupplierTable;
