import { ReactNode } from "react";
import classNames from "classnames";
import "./page.scss";

interface PageProps {
  children: ReactNode;
  className?: string;
}

const Page = ({ children, className }: PageProps) => {
  const classes = classNames("page", className);

  return <div className={classes}>{children}</div>;
};

export default Page;
