import "./adding-measurement-first-step.scss";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { VALIDATION_MESSAGES } from "enums";
import { Button, Loader, Select } from "components";
import { useSearchParams } from "react-router-dom";
import {
  useGetMeasurementTypes,
  useStartMeasurement,
} from "hooks/measurements";
import { useState } from "react";

interface AddingMeasurementFirstStepType {
  setStep: (step: number) => void;
  selectedOptions: string | undefined;
  setFormType: (name: string) => void;
}

const AddingMeasurementFirstStep = ({
  setStep,
  selectedOptions,
  setFormType,
}: AddingMeasurementFirstStepType) => {
  const { data: measurementTypes } = useGetMeasurementTypes();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const orderUuid = searchParams.get("orderUuid");
  const measurementUuid = searchParams.get("measurementUuid");
  const selectedMeasurement = measurementTypes?.find(
    (item) => item.type === selectedOptions
  );

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementUuid", data.measurement.uuid);
      newSearchParams.set(
        "measurementPage",
        data.measurement.lastFinishedPage + 1 || "2"
      );
      setIsLoading(false);
      setSearchParams(newSearchParams, { replace: true });
      setStep(data.measurement.lastFinishedPage + 1 || 2);
    },
  });

  const validationSchema = Yup.object().shape({
    typeOfMeasurementUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = ({ typeOfMeasurementUuid, orderUuid }: any) => {
    const findFormType = measurementTypes?.find(
      (measurementTypes) => measurementTypes.value === typeOfMeasurementUuid
    )?.type;
    setFormType(findFormType || "");
    setIsLoading(true);
    startMeasurement.mutate({
      typeOfMeasurementUuid,
      orderUuid,
      measurementUuid,
    });
  };

  return (
    <div className="amfs">
      {isLoading ? (
        <Loader width="240px" height="240px" />
      ) : (
        <>
          <div className="am-heading">Wybierz rodzaj pomiaru</div>
          <Formik
            initialValues={{
              typeOfMeasurementUuid: selectedMeasurement?.value || "",
              orderUuid,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <form className="amfs__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Rodzaj pomiaru"
                  name="typeOfMeasurementUuid"
                  component={Select}
                  options={measurementTypes}
                  white
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default AddingMeasurementFirstStep;
