import { ContextMenu, SVG } from "components";
import { SVG_TYPE } from "enums";
import { materialType } from "types/MaterialTypes";

const items = (
  editSupplier: (material: materialType) => void,
  deleteSupplier: (material: materialType) => void,
  isLastElement: boolean
) => {
  return (material: materialType) => {
    const actions = [
      {
        name: "Edytuj",
        action: () => editSupplier(material),
      },
      {
        name: "Usuń",
        action: () => deleteSupplier({ ...material, isLastElement }),
      },
    ];
    return actions;
  };
};

export const mobileColumns = (
  editSupplier: (material: materialType) => void,
  deleteSupplier: (material: materialType) => void
) => [
  {
    key: "name",
    dataIndex: "name",
    width: "100%",
    render: (values: any) => (
      <div className="assembly-material__name">{`${values}`}</div>
    ),
  },
  {
    width: "100px",
    render: (values: any) => (
      <div className="supplier-table__actions">
        <div
          className="supplier-table__actions-item"
          onClick={() => deleteSupplier(values)}
        >
          Usuń
        </div>
        <div
          className="supplier-table__actions-item"
          onClick={() => editSupplier(values)}
        >
          Edytuj
        </div>
      </div>
    ),
  },
];

export const desktopColumns = (
  currentPage: number,
  perPage: number,
  editSupplier: (material: materialType) => void,
  deleteSupplier: (material: materialType) => void,
  isLastElement: boolean
) => [
  {
    title: "Lp.",
    key: "index",
    width: 68,
    render: (value: any, record: any, index: number) =>
      (currentPage - 1) * perPage + index + 1,
  },
  {
    title: "Nazwa",
    key: "name",
    dataIndex: "name",
    width: 1123,
  },

  {
    title: "Akcje",
    width: 88,
    render: (item: any) => (
      <ContextMenu
        data={item}
        items={items(editSupplier, deleteSupplier, isLastElement)(item)}
      >
        <SVG type={SVG_TYPE.ELLIPSIS} />
      </ContextMenu>
    ),
  },
];
