import { Button, Input } from "components";
import { FieldArray, Field } from "formik";
import "./door-sills.scss";

interface DoorSillsType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
}

const DoorSills = ({ errors, form, validateField, field }: DoorSillsType) => {
  return (
    <div className="hole-dimension door-sills">
      <div>
        <FieldArray
          name="activities"
          render={(arrayHelpers) => (
            <div>
              <div className="am-section-heading door-sills__heading">
                <h3>
                  Listwy progowe <span>(opcjonalne)</span>
                </h3>
                {form.values?.activities.length === 0 && (
                  <Button
                    size="small"
                    stroke
                    label="Dodaj listwę"
                    onClick={() => {
                      arrayHelpers.push("");
                    }}
                  />
                )}
              </div>
              {form.values?.activities.map((_: any, index: any, id: any) => (
                <>
                  <Field
                    errors={errors}
                    key={index}
                    label={`Rodzaj`}
                    maxLength={30}
                    name={`activities_rodzaj.${index}`}
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    key={index}
                    maxLength={30}
                    label={`Kolor`}
                    name={`activities_kolor.${index}`}
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    key={index}
                    label={`Długość`}
                    type="number"
                    maxLength={6}
                    rightPlaceholder="mm"
                    name={`activities_dlugosc.${index}`}
                    component={Input}
                  />
                  <Field
                    errors={errors}
                    key={index}
                    label={`Ilość`}
                    type="number"
                    maxLength={4}
                    rightPlaceholder="szt."
                    name={`activities_ilosc.${index}`}
                    component={Input}
                  />
                  {form.values?.activities?.length > index + 1 && (
                    <div className="am-separator" />
                  )}
                </>
              ))}
              {form.values?.activities.length > 0 && (
                <Button
                  stroke
                  label="Dodaj kolejną"
                  size="small"
                  className="door-sills__add-button"
                  onClick={() => {
                    arrayHelpers.push("");
                  }}
                />
              )}
              <div className="am-separator" />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default DoorSills;
