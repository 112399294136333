import { useState } from "react";
import { VALIDATION_MESSAGES } from "enums";
import { Button, Input } from "components";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { ScrollToError } from "utils";
import {
  ConstructionSite,
  BuildingMaterialComponent,
  MountingAccessories,
  SingleSectionElement,
} from "features/addingMeasurement";
import "./windows-replacement-installation-information.scss";
import { usePutWindowReplacementConstructionInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";

interface WindowsReplacementGeneralInformationType {
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const WindowsReplacementGeneralInformation = ({
  measurementInfo,
  setStep,
  step,
  prevStep,
}: WindowsReplacementGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const initialMeasurementInstallationInfo =
    measurementInfo?.measurementInstallationInfo;
  const [searchParams, setSearchParams] = useSearchParams();

  const mutation = usePutWindowReplacementConstructionInfo({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "4");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  interface ObjectWithComment {
    comment: string;
  }

  const isObjectWithComment = (value: any): value is ObjectWithComment => {
    return (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value) &&
      typeof value.comment === "string" &&
      value.comment.trim() !== ""
    );
  };

  const validationSchema = Yup.object().shape({
    // constructionSiteUuids: Yup.array()
    //   .of(
    //     Yup.mixed().test(
    //       "is-string-or-object",
    //       VALIDATION_MESSAGES.REQUIRED,
    //       (value) =>
    //         typeof value === "string" ||
    //         (typeof value === "object" &&
    //           value !== null &&
    //           !Array.isArray(value))
    //     )
    //   )
    //   .required(VALIDATION_MESSAGES.REQUIRED)
    //   .test(
    //     "has-at-least-one-loose-string",
    //     VALIDATION_MESSAGES.REQUIRED,
    //     (array) => array.some((item) => typeof item === "string")
    //   ),
    constructionSiteUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object-with-comment",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value) &&
              typeof (value as ObjectWithComment).comment === "string" &&
              (value as ObjectWithComment).comment.trim() !== "")
        )
      )
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "has-at-least-one-loose-string-or-object-with-comment",
        VALIDATION_MESSAGES.REQUIRED,
        (array) =>
          array.some(
            (item) => typeof item === "string" || isObjectWithComment(item)
          )
      ),
    theBuildingIsMadeOf: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test("is-other-check", VALIDATION_MESSAGES.REQUIRED, function (value) {
        const { theBuildingIsMadeOfComment } = this.parent;
        if (value === "other" && !theBuildingIsMadeOfComment) {
          return false;
        }
        return true;
      }),
    theBuildingIsMadeOfComment: Yup.string(),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const constructionSiteComments =
    initialMeasurementInstallationInfo?.constructionSiteUuids.find(
      (item: any) => item.comment
    )?.comment;

  const initialValues = {
    measurementUuid: measurementInfo?.uuid,
    constructionSiteUuids:
      initialMeasurementInstallationInfo?.constructionSiteUuids || [],
    constructionSiteComments: constructionSiteComments || "",
    installationElementsUuids:
      initialMeasurementInstallationInfo?.installationElementsUuids || [],
    plannedAssemblyTime:
      initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
    numberOfAssemblyTeams:
      initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
    theBuildingIsMadeOf:
      initialMeasurementInstallationInfo?.theBuildingIsMadeOf || "",
    theBuildingIsMadeOfComment:
      initialMeasurementInstallationInfo?.theBuildingIsMadeOfComment || "",
  };

  const handleOnSubmit = (values: any) => {
    delete values.constructionSiteComments;
    mutation.mutate(values);
  };

  return (
    <div>
      <div className="am-heading">Informacje ogólne</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form wrgi" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                type={measurementInfo?.typeOfMeasurement}
                values={values}
                errors={errors}
                name="constructionSiteUuids"
                component={ConstructionSite}
              />
              <Field
                errors={errors}
                name="theBuildingIsMadeOf"
                component={BuildingMaterialComponent}
              />
              <Field
                type={measurementInfo?.typeOfMeasurement}
                errors={errors}
                name="installationElementsUuids"
                component={MountingAccessories}
              />
              <SingleSectionElement
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  type="number"
                  rightPlaceholder="h"
                  maxLength={6}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  type="number"
                  component={Input}
                  maxLength={3}
                />
              </SingleSectionElement>
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button type="submit" label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WindowsReplacementGeneralInformation;
