import { Input, StaticAlert } from "components";
import { Field } from "formik";

import "./hole-dimension.scss";

interface HoleDimensionType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  label: string;
  valueFirst?: string;
  valueSecond?: string;
  aboveZero?: boolean;
}

const HoleDimension = ({
  errors,
  form,
  field,
  label,
  validateField,
  valueFirst,
  valueSecond,
  aboveZero = false,
}: HoleDimensionType) => {
  return (
    <div className="hole-dimension">
      <div className="am-section-heading">{label}</div>
      <Field
        label="Szerokość"
        name={`${valueFirst ? valueFirst : "holeSizeWidth"}`}
        type="number"
        rightPlaceholder="mm"
        component={Input}
      />
      <Field
        label="Wysokość"
        name={`${valueSecond ? valueSecond : "holeSizeHeight"}`}
        type="number"
        rightPlaceholder="mm"
        component={Input}
      />
      {aboveZero && (
        <Field
          label="Wysokość od poziomu zero"
          name="heightFromZeroLevel"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      )}

      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={
              valueFirst && valueSecond
                ? (errors[valueFirst] && errors[valueFirst]) ||
                  (errors[valueSecond] && errors[valueSecond])
                : (errors["holeSizeWidth"] && errors["holeSizeWidth"]) ||
                  (errors["holeSizeHeight"] && errors["holeSizeHeight"]) ||
                  (aboveZero &&
                    errors["heightFromZeroLevel"] &&
                    errors["heightFromZeroLevel"])
            }
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}

      <div className="am-separator" />
    </div>
  );
};

export default HoleDimension;
