import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig"; 
import { ratesHistoryType } from 'types/EmployeeRatesTypes';
import { MetaType } from "types/UtilsTypes";

export default function useGetEmployeeRateHistory(uuid: string) {
  return useQuery({
    queryKey: ["userRateHistory", uuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/users/rates/history/${uuid}`)
        .then((res) => res.data);
    },
    select: (data: { user: { ratesHistory: ratesHistoryType[], meta: MetaType } }) => data.user.ratesHistory, 
    retry: false,
    enabled: !!uuid,
  });
}