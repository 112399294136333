import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { LocationType } from "types/UtilsTypes";

export default function useGetLocations() {
  return useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      return axiosInstance
        .get(`/location/label`)
        .then((res) => res.data);
    },
    select: (data: {locationSerialize:LocationType[]} ) => data.locationSerialize,
    retry: false,
  });
}