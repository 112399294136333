import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig'; 
import { Toast } from 'components'; 
import { TOAST_TYPES_ENUM } from 'enums'; 
import { queryClient } from 'App'; 

type DeleteMeasurementType = {
  measurementUuid: string;
};

const useDeleteMeasurement = (
  orderUuid: string | null, 
  options?: UseMutationOptions<any, Error, DeleteMeasurementType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['deleteMeasurement'],
    mutationFn: async ({ measurementUuid }) => {
      const url = `/measurement?measurementUuid=${encodeURIComponent(measurementUuid)}`;
      return axiosInstance.delete(url)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania usunięcia pomiaru...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'delete-measurement',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Pomiar został pomyślnie usunięty.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'delete-measurement',
      });
      queryClient.invalidateQueries({ queryKey: ['measurements', orderUuid] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas usuwania pomiaru.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'delete-measurement',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useDeleteMeasurement;