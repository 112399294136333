import { useState } from "react";
import "./calendar-page.scss";
import { Calendar } from "components";
import moment from "moment";

const CalendarPage = () => {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);

  return (
    <div className="calendar-page">
      <Calendar date={date} setDate={setDate} main size="100%" />
    </div>
  );
};

export default CalendarPage;
