import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Button, Textarea, Switch } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";
import {
  FloorLayingPattern,
  SingleSectionElement,
} from "features/addingMeasurement";
import "./floor-general-information.scss";
import { usePutFloorClientInfo } from "hooks/measurements";

interface FloorGeneralInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const FloorGeneralInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: FloorGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: editGeneralInfo } = usePutFloorClientInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  const measurementClientInfo = measurementInfo?.measurementClientInfo;

  const validationSchema = Yup.object().shape({
    flooLayingPattern: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    finishingWorks: Yup.string()
      .oneOf(["completed", "inProgress"], VALIDATION_MESSAGES.REQUIRED)
      .required(VALIDATION_MESSAGES.REQUIRED),
    comments: Yup.string().test(
      "is-comments-required",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { finishingWorks } = this.parent;
        if (finishingWorks === "inProgress") {
          return value != null && value.trim() !== "";
        }
        return true;
      }
    ),
  });

  const handleOnSubmit = (values: any) => {
    delete values.constructionSiteComments;
    editGeneralInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          comments: measurementClientInfo?.comments || "",
          finishingWorks: measurementClientInfo?.finishingWorks || "",
          flooLayingPattern: measurementClientInfo?.flooLayingPattern || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          const inProgress = values.finishingWorks === "inProgress";
          return (
            <form
              className={`amfs__form form floor-general-information ${
                inProgress ? "floor-general-information--active" : ""
              }`}
              onSubmit={handleSubmit}
            >
              <ScrollToError />
              <Field
                type={measurementInfo?.typeOfMeasurement}
                values={values}
                errors={errors}
                name="flooLayingPattern"
                component={FloorLayingPattern}
              />
              <SingleSectionElement
                touched={touched}
                name="whoIsToPrepareTheOpening"
                errors={errors}
              >
                <Field
                  label={`Prace wykończeniowe`}
                  name="finishingWorks"
                  first="Zakończone"
                  second="W trakcie"
                  stringMode
                  valueFirst="completed"
                  valueSecond="inProgress"
                  component={Switch}
                />
              </SingleSectionElement>
              {inProgress && (
                <SingleSectionElement
                  touched={touched}
                  name="comments"
                  errors={errors}
                >
                  <Field
                    className="am-margin-top"
                    label="Dodatkowe uwagi"
                    name="comments"
                    component={Textarea}
                    maxLength={250}
                  />
                </SingleSectionElement>
              )}
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FloorGeneralInformation;
