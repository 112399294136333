import "./file-item.scss";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import { MEASUREMENTS_TYPE_TRANSLATIONS } from "enums";
import { MEASUREMENTS_TYPE } from "types/OrderTypes";

interface FileItemType {
  name: MEASUREMENTS_TYPE;
  url?: string;
}

const FileItem = ({ name, url }: FileItemType) => {
  const handleDownloadFileItem = () => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="file-item">
      <p className="file-item__label">{MEASUREMENTS_TYPE_TRANSLATIONS[name]}</p>
      {url && (
        <div
          className="file-item__icon-wrapper"
          onClick={() => handleDownloadFileItem()}
        >
          <SVG className="file-item__icon" type={SVG_TYPE.DOWNLOAD_FILE} />
        </div>
      )}
    </div>
  );
};

export default FileItem;
