import { useState, useEffect } from "react";
import {
  Page,
  PageContent,
  UserAccessPanel,
  Button,
  Input,
  StaticAlert,
} from "components";
import { Field, Formik } from "formik";
import { ROUTE_ENUM, VALIDATION_MESSAGES } from "enums";
import { usePasswordRecovery } from "hooks/auth";
import * as Yup from "yup";
import "./forget-password.scss";

const ForgetPassword = () => {
  const [showError, setShowError] = useState(false);
  const [showGoBack, setShowGoBack] = useState(true);
  const recoveryAgain = sessionStorage.getItem("recoveryAgain");

  useEffect(() => {
    if (recoveryAgain) {
      setShowGoBack(false);
    }
  }, [recoveryAgain]);

  const { mutate: recoverPassword } = usePasswordRecovery({
    onError: (error) => {
      setShowError(true);
    },
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .email(VALIDATION_MESSAGES.EMAIL),
  });

  const handleSubmit = async (values: { email: string }) => {
    sessionStorage.setItem("recoveryPasswordEmail", values.email);
    await recoverPassword(values);
  };

  return (
    <Page>
      <PageContent>
        <UserAccessPanel
          goBack={showGoBack ? ROUTE_ENUM.LOGIN : null}
          heading="Nie pamiętasz hasła?"
        >
          <div className="uap__description forget-password__description">
            Wprowadź swój adres Email, aby otrzymać instrukcje dotyczące
            resetowania hasła.
          </div>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleSubmit(values)}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <form className="forget-password__form" onSubmit={handleSubmit}>
                <StaticAlert show={showError} label="Nieprawidłowy email" />
                <Field
                  errors={errors}
                  label="Adres email"
                  name="email"
                  component={Input}
                />
                <div className="uap__button">
                  <Button type="submit" label="Wyślij" />
                </div>
              </form>
            )}
          </Formik>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default ForgetPassword;
