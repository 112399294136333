import { useEffect } from 'react';
import { useFormikContext } from 'formik';

type ScrollToErrorType = {
  errorDimension?: boolean;
}
const ScrollToError = ({errorDimension = false}: ScrollToErrorType) => {
  const { errors, isSubmitting } = useFormikContext();
  useEffect(() => {
    const topElements = document.getElementsByClassName('am__content');
    if (topElements.length > 0) {
      topElements[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
    } else {
      const errorElements = document.getElementsByClassName('error-dimension');
      if (errorElements.length > 0) {
        errorElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, []);
  

  useEffect(() => {
    if (Object.keys(errors).length !== 0 && isSubmitting) {
      const errorKeys = Object.keys(errors);
        if(errorKeys.length > 0) {
          const lastErrorElement = document.getElementsByClassName('static-alert--show');
          lastErrorElement[0] && lastErrorElement[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }
    
    if(isSubmitting && errorDimension) {
      const errorElements = document.getElementsByClassName('error-dimension');
      if (errorElements.length > 0) {
        errorElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors, isSubmitting, errorDimension]);

  return null;
};

export default ScrollToError;
