import { ReactNode, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import "./context-menu.scss";

interface ContextMenuType {
  className?: string;
  children: ReactNode;
  data?: any;
  items: {
    name: any;
    disabled?: boolean;
    action: (data: any) => void;
  }[];
  position?: "top" | "bottom" | "left" | "right";
}

const ContextMenu = ({
  data,
  children,
  items,
  position = "left",
  className,
}: ContextMenuType) => {
  const [isActive, setIsActive] = useState(false);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target as Node)
    ) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const contextMenuClass = classNames(
    "context-menu",
    className,
    `context-menu--${position}`,
    {
      "context-menu--active": isActive,
    }
  );
  return (
    <div className="context-menu__wrapper">
      <div className="context-menu__content">
        <div
          className={`context-menu__trigger ${
            isActive ? "context-menu__trigger--disable" : ""
          }`}
          onClick={() => {
            setIsActive(true);
          }}
        >
          {children}
        </div>
        <div className={contextMenuClass} ref={contextMenuRef}>
          {items.map((item: any) => {
            return (
              <div
                onClick={() => {
                  if (!item.disabled) {
                    item.action(data);
                    setIsActive(false);
                  } else {
                    setIsActive(false);
                  }
                }}
                className={`context-menu__item ${
                  item.disabled ? "context-menu__item--disabled" : ""
                }`}
                key={item.name}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;
