import { useState, useEffect } from "react";
import { StaticAlert, CheckboxPart, Textarea } from "components";
import { useGetInstallationTypes } from "hooks/measurements";
import { InstallationType } from "types/MeasurementTypes";
import { Field } from "formik";
import "./type-of-instalation.scss";

interface TypeOfInstalationType {
  field: any;
  errors?: any;
  form: any;
  touched?: any;
  values?: any;
  type?: string;
}

const TypeOfInstalation = ({
  errors,
  form,
  field,
  values,
  type,
}: TypeOfInstalationType) => {
  const { data: installationTypes } = useGetInstallationTypes(type);
  const [typeOfInstallationUuids, setTypeOfInstallationUuids] = useState<any>(
    values?.typeOfInstallationUuids || []
  );

  useEffect(() => {
    setTypeOfInstallationUuids(values.typeOfInstallationUuids);
  }, [values?.typeOfInstallationUuids]);

  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray = typeOfInstallationUuids.find(
      (item: any) => item === uuid
    );
    if (!isUuidInArray) {
      const values = [...typeOfInstallationUuids, uuid];
      setTypeOfInstallationUuids(values);
      form.setFieldValue(field.name, values);
    } else {
      const values = typeOfInstallationUuids.filter(
        (item: any) => item !== uuid
      );
      setTypeOfInstallationUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  const handleWriteComment = (e: any) => {
    const commentItem =
      installationTypes &&
      installationTypes.find((item: any) => item.type === "comments");
    const isCommentInArray = typeOfInstallationUuids.find(
      (item: any) => item.comment
    );
    if (commentItem && !isCommentInArray) {
      const values = [
        ...typeOfInstallationUuids,
        { comment: e, uuid: commentItem?.value },
      ];
      setTypeOfInstallationUuids(values);
      form.setFieldValue(field.name, values);
    } else if (commentItem && isCommentInArray) {
      const newTypeOfInstallationUuids = typeOfInstallationUuids.filter(
        (item: any) => item.uuid !== commentItem?.value
      );
      const values = [
        ...newTypeOfInstallationUuids,
        { comment: e, uuid: commentItem?.value },
      ];
      setTypeOfInstallationUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  return (
    <div className="type-of-instalation">
      <div className="am-section-heading">Rodzaj montażu</div>
      <div className="am-section-checkbox">
        {installationTypes &&
          installationTypes.length > 0 &&
          installationTypes.map((item: InstallationType) => {
            if (item.type !== "comments") {
              return (
                <CheckboxPart
                  checked={values?.typeOfInstallationUuids.find(
                    (uuid: string) => uuid === item.value
                  )}
                  key={item.value}
                  label={item.label}
                  uuid={item.value}
                  onChange={(uuid) => handleClickCheckbox(uuid)}
                />
              );
            }
            return false;
          })}
      </div>
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <Field
        label="Dodatkowe uwagi"
        maxLength={250}
        name="typeOfInstallationComments"
        component={Textarea}
        onChange={(e: any) => handleWriteComment(e)}
      />
      <div className="am-separator" />
    </div>
  );
};

export default TypeOfInstalation;
