import { Button, Modal } from "components";

interface CloseMeasurementType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const CloseMeasurement = ({ isOpen, onClose, data }: CloseMeasurementType) => {
  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Zakończ zlecenie</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zakończyć zlecenie
            <span>{data.label}</span>?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={() => console.log(data.uuid)} label="Potwierdź" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default CloseMeasurement;
