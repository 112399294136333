/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { USER_ROLE, ROUTE_ENUM } from "enums";
import { useGetMe } from "hooks/auth";
import { ProtectedRouteProps } from "types/routeTypes";
import { DashboardRouter } from "pages";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAdminOnly = false,
}) => {
  const location = useLocation();
  const { data: authorizedUser, isLoading, refetch } = useGetMe();

  useEffect(() => {
    refetch();
  }, [location]);

  if (isLoading) {
    return null;
  }

  const isAuthenticated = !!authorizedUser?.role;
  const role = authorizedUser?.role;
  const isCorrect = isAdminOnly
    ? isAuthenticated && role === USER_ROLE.admin
    : isAuthenticated;

  const user = sessionStorage.getItem("loginAdmin");
  let route;
  if (user) {
    route = ROUTE_ENUM.LOGIN;
  } else {
    route = ROUTE_ENUM.HOME;
  }

  return isCorrect ? <DashboardRouter /> : <Navigate to={route} />;
};

export default ProtectedRoute;
