import moment from "moment";
import "./mobile-week-calendar.scss";
import SVG from "components/SVG";
import { SVG_TYPE } from "enums";

interface MobileWeekCalendarType {
  date: string | null;
  setDate: (date: string) => void;
  calendarView: "month" | "week" | "day" | "agenda";
  main?: boolean;
  isActiveBack?: boolean;
  arrangingMeasurement?: (isOpen: boolean) => void;
}

const MobileWeekCalendar = ({
  date,
  setDate,
  calendarView,
  arrangingMeasurement,
  main,
  isActiveBack,
}: MobileWeekCalendarType) => {
  const handlePreviousWeek = () => {
    if ((isActiveBack && !main) || main) {
      const newDate = moment(date, "YYYY-MM-DD")
        .subtract(1, "week")
        .format("YYYY-MM-DD");
      setDate(newDate);
    }
  };

  const handleNextWeek = () => {
    const newDate = moment(date, "YYYY-MM-DD")
      .add(1, "week")
      .format("YYYY-MM-DD");
    setDate(newDate);
  };

  const startOfWeek = moment(date, "YYYY-MM-DD").startOf("week");
  const days = Array.from({ length: 7 }).map((_, index) =>
    startOfWeek.clone().add(index, "days")
  );

  const dateAfterFormat =
    date && moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

  const today = moment().startOf("day");

  return (
    <div className={`week-bar week-bar--${calendarView}`}>
      <div className="week-tile-view">
        <div className="week-bar__navigation week-bar__navigation--left">
          <div
            className={`week-bar__navigation-button ${
              !isActiveBack && !main
                ? "week-bar__navigation-button--disabled"
                : ""
            }`}
            onClick={handlePreviousWeek}
          >
            <SVG type={SVG_TYPE.CHEVRON_LEFT} />
          </div>
        </div>
        {days.map((day, index) => {
          const calendarDate = day.format("YYYY-MM-DD");
          const isActiveDate = calendarDate === dateAfterFormat;
          const isDayBefore = day.isBefore(today, "day");
          const firstDay = index === 0;

          return (
            <div
              key={calendarDate}
              className={`week-tile-view__day ${
                firstDay ? "week-tile-view__day--first-day" : ""
              } ${isActiveDate ? "week-tile-view__day--active" : ""} ${
                !main && isDayBefore ? "week-tile-view__day--disabled" : ""
              }`}
              onClick={() => {
                if (!isDayBefore) {
                  setDate(calendarDate);
                }
              }}
            >
              <span className="week-tile-view__day-label">
                {day.format("dd")}
              </span>
              <span className="week-tile-view__day-number">
                {day.format("D")}
              </span>
            </div>
          );
        })}
        <div className="week-bar__navigation week-bar__navigation--right">
          <div className="week-bar__navigation-button" onClick={handleNextWeek}>
            <SVG type={SVG_TYPE.CHEVRON_RIGHT} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileWeekCalendar;
