import { Button, Modal, Input } from "components";
import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { useAddSupplier } from "hooks/supplier";
import * as Yup from "yup";

interface AddSupplierType {
  isOpen: boolean;
  onClose: () => void;
}

const AddSupplier = ({ isOpen, onClose }: AddSupplierType) => {
  const { mutate: addSupplier } = useAddSupplier({
    onSuccess: () => {
      onClose();
      setTimeout(() => {
        document.getElementById("pagination-last-page")?.click();
      }, 100);
    },
  });

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(
        /^[^\s].*[^\\s]?$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
  });

  const handleOnSubmit = async (values: any) => {
    addSupplier({ ...values });
  };
  return (
    <>
      <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Dodawanie dostawcy</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Nazwa dostawcy"
                  name="name"
                  component={Input}
                  maxLength={30}
                />
                <div className="modal-buttons">
                  <Button stroke onClick={() => onClose()} label="Anuluj" />
                  <Button onClick={() => handleSubmit()} label="Potwierdź" />
                </div>
              </form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default AddSupplier;
