import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type FloorInfoData = {
  measurementUuid: string;
  roomName: string;
  directionOfLayingThePanels: string;
  conditionOfTheSpout: 'normal' | 'toImprove';
  conditionOfTheSpoutWork?: 'investor' | 'martdom'; // wymagane, jeśli toImprove
  conditionOfTheSpoutComments?: string; // wymagane, jeśli toImprove
  floorHeating: boolean;
  connectingPanelsToAnotherFloor: boolean;
  differentFloorLevel?: string; // wymagane, jeśli connectingPanelsToAnotherFloor = true
  wallStrips: string;
  typeOfStrips: string;
  stripColor: string;
  internalCorner?: string;
  externalCorner?: string;
  connector?: string;
  rightEnding?: string;
  leftEnding?: string;
  floorSpace: string;
  reserve: string;
  floorSpaceWithReserve: string;
  comments?: string;
};

const usePutFloorInfo = (
  options?: UseMutationOptions<any, Error, FloorInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editFloorsInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/floors/info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych podłogi...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-floors-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane podłogi zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-floors-info',
      });
      queryClient.invalidateQueries({ queryKey: ['floorsInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych podłogi.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-floors-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutFloorInfo;
