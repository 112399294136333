/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Page,
  PageContent,
  UserAccessPanel,
  Button,
  Input,
  Select,
  PhoneWithPrefix,
  StaticAlert,
} from "components";
import { PhoneNumberTypes } from "types/AuthTypes";
import { useNavigate } from "react-router";
import { Field, Formik } from "formik";
import { ROUTE_ENUM, USER_ROLE } from "enums";
import { useSendSms, useGetMe } from "hooks/auth";
import { usePhonePrefixes } from "hooks/utils";
import "./login-by-tel.scss";

const LoginBySms = () => {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { data } = usePhonePrefixes();
  const { data: authorizedUser, isLoading } = useGetMe();

  useEffect(() => {
    if (authorizedUser) {
      if (authorizedUser.role === USER_ROLE.admin) {
        navigate(ROUTE_ENUM.DASHBOARD_STATISTICS);
      } else {
        navigate(ROUTE_ENUM.ORDERS_LIST);
      }
    }
  }, [authorizedUser]);

  const { mutate } = useSendSms({
    onSuccess: (data) => {
      navigate(ROUTE_ENUM.LOGIN_SMS_CODE);
    },
    onError: (error) => {
      setShowError(true);
    },
  });

  const handleSubmit = (values: PhoneNumberTypes) => {
    sessionStorage.setItem("phoneNumber", JSON.stringify(values));
    mutate(values);
  };

  const phonePrefixOptions =
    data &&
    data?.phonePrefixSerialize &&
    data?.phonePrefixSerialize.length > 0 &&
    data?.phonePrefixSerialize.map((item: any) => ({
      label: item.phonePrefix,
      value: item.value,
    }));

  return (
    <>
      {!isLoading && !authorizedUser && (
        <Page>
          <PageContent>
            <UserAccessPanel heading="Logowanie">
              <div className="uap__description login-by-tel__description">
                Wpisz numer telefonu, żeby się zalogować. Aby upewnić się, że
                ten numer należy do Ciebie, wyślemy Ci darmowy kod dostępu.
              </div>
              <Formik
                initialValues={{
                  phonePrefixUuid:
                    data && phonePrefixOptions
                      ? phonePrefixOptions[0].value
                      : "+ 48",
                  phoneNumber: "",
                }}
                enableReinitialize
                onSubmit={async (values: any) => handleSubmit(values)}
              >
                {({ handleSubmit, errors, isSubmitting, setFieldValue }) => {
                  return (
                    <form
                      className="login-by-tel__form form"
                      onSubmit={handleSubmit}
                    >
                      <StaticAlert
                        show={showError}
                        label="Nieprawidłowy numer telefonu"
                      />
                      <PhoneWithPrefix>
                        <Field
                          label="Telefon"
                          name="phonePrefixUuid"
                          required
                          size="medium"
                          disableMobileKeyboard
                          component={Select}
                          errors={errors}
                          options={phonePrefixOptions}
                        />
                        <Field
                          onChange={() => setShowError(false)}
                          name="phoneNumber"
                          type="number"
                          required
                          component={Input}
                        />
                      </PhoneWithPrefix>
                      <div className="uap__button">
                        <Button type="submit" label="Wyślij kod dostępu" />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </UserAccessPanel>
          </PageContent>
        </Page>
      )}
    </>
  );
};

export default LoginBySms;
