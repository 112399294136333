import { useState, useEffect } from "react";
import "./checkbox-part.scss";
import classNames from "classnames";

interface CheckboxPartProps {
  label?: string;
  uuid: string;
  onChange: (uuid: string) => void;
  className?: string;
  checked?: boolean;
  required?: boolean;
}

const CheckboxPart = ({
  label,
  uuid,
  onChange,
  className = "",
  checked = false,
  required,
}: CheckboxPartProps) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleTrigerCheckbox = () => {
    setIsChecked(!isChecked);
    onChange(uuid);
  };
  const checkboxClassName = classNames("checkbox-part__wrapper", className, {
    "checkbox-part__wrapper--active": isChecked,
    "radiobox__wrapper--active": isChecked && className === "radiobox",
  });

  return (
    <div className={checkboxClassName}>
      <div
        className="checkbox-part__click"
        onClick={() => handleTrigerCheckbox()}
      />
      <input
        id="label"
        type="checkbox"
        className="checkbox-part"
        checked={isChecked}
        onChange={() => handleTrigerCheckbox()}
      />
      <label htmlFor="label" className="checkbox-part-label">
        {label}
      </label>
      {required && <div className="checkbox-part__required">Wymagane</div>}
    </div>
  );
};

export default CheckboxPart;
