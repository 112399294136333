import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig'; 
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums'; 
import { queryClient } from 'App'; 
import { addAdminType } from 'types/UsersTypes'; 

const useAddAdmin = (
  options?: UseMutationOptions<any, Error, addAdminType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['addAdmin'],
    mutationFn: async ({ email, firstName, lastName }) => {
      return axiosInstance.post('/users/admin', {
        email,
        firstName,
        lastName,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania dodania administratora...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'add-admin',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Administrator dodany pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'add-admin',
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas dodawania administratora.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'add-admin',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddAdmin;
