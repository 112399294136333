import { useState } from "react";
import { Modal, Button } from "components";
import { useNavigate } from "react-router";
import { ROUTE_ENUM, SVG_TYPE } from "enums";
import "./close-measurement.scss";
import { useSearchParams } from "react-router-dom";

const CloseMeasurement = () => {
  const [isOpenCloseModal, setIsOpenCloseModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const orderDetailsUrl = `?page=${page}&orderUuid=${orderUuid}`;

  const handleSubmit = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}${orderDetailsUrl}`);
  };

  return (
    <>
      <Modal
        className="am-close-modal"
        isOpened={isOpenCloseModal}
        onClose={() => setIsOpenCloseModal(false)}
      >
        <>
          <div className="modal-heading">Zamknij dodawanie pomiaru</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zamknąć dodawanie pomiaru? Niezapisane
            dane zostaną utracone.
          </div>
          <div className="modal-buttons">
            <Button
              stroke
              onClick={() => setIsOpenCloseModal(false)}
              label="Anuluj"
            />
            <Button onClick={() => handleSubmit()} label="Potwierdź" />
          </div>
        </>
      </Modal>
      <div className="am-top">
        <Button
          label="Zamknij"
          stroke
          size="medium"
          svgType={SVG_TYPE.CLOSE}
          onClick={() => setIsOpenCloseModal(true)}
        />
      </div>
    </>
  );
};

export default CloseMeasurement;
