import { useState, useEffect } from "react";
import {
  Page,
  PageContent,
  UserAccessPanel,
  Button,
  PasswordStrengthChecker,
  PasswordValidation,
  Input,
} from "components";
import { Formik, Field } from "formik";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import * as Yup from "yup";
import "./set-admin-password.scss";
import { useCheckToken, useSetPassword } from "hooks/auth";
import { useNavigate } from "react-router";

const SetAdminPassword = () => {
  const navigate = useNavigate();
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { mutate: setPassword } = useSetPassword();

  const { mutate: mutateCheckToken } = useCheckToken({
    onError: (error: Error) => {
      navigate(ROUTE_ENUM.ACTIVATE_LINK_EXPIRED);
    },
  });

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";

  useEffect(() => {
    if (token) {
      mutateCheckToken({ token });
    }
  }, [mutateCheckToken, token]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED),
    confirm_password: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED)
      .oneOf([Yup.ref("password"), ""], VALIDATION_MESSAGES.PASSWORD_MATCH),
  });

  const initialValues = {
    password: "",
    activateToken: token,
    confirm_password: "",
  };

  const handleSubmitForm = async (values: typeof initialValues) => {
    !showValidation &&
      setPassword({
        activateToken: token,
        password: values.password,
      });
  };

  return (
    <Page>
      <PageContent>
        <UserAccessPanel heading="Utwórz hasło">
          <div className="uap__description set-admin-password__description">
            Rejestracja użytkownika panelu administracyjnego
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            isInitialValid={false}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={async (values) => handleSubmitForm(values)}
          >
            {({ handleSubmit, errors, isSubmitting, values }) => (
              <form
                className="set-admin-password__form"
                onSubmit={handleSubmit}
              >
                <Field
                  type="password"
                  label="Hasło"
                  name="password"
                  variant="password"
                  component={Input}
                  wrapperClassName="white form__field--gray"
                  errors={errors}
                  inputOnFocus={() => setShowValidation(true)}
                  onBlur={() => isPasswordValid && setShowValidation(false)}
                />
                <PasswordStrengthChecker
                  isHidden={!showValidation}
                  password={values.password}
                />
                <PasswordValidation
                  className={` ${
                    showValidation ? "validation-item__wrapper--show" : ""
                  }`}
                  password={values.password}
                  setIsValid={(value: boolean) => setIsPasswordValid(value)}
                />
                <Field
                  type="password"
                  variant="password"
                  label="Powtórz hasło"
                  name="confirm_password"
                  component={Input}
                  errors={errors}
                  wrapperClassName="white form__field--gray"
                />

                <div className="uap__button">
                  <Button label="Potwierdź" type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default SetAdminPassword;
