import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { MetaType } from "types/UtilsTypes";

type AssemblyMaterialType = {
  uuid: string;
  name: string;
  cost: string;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetAssemblyMaterials(status: string, page: number, activeSupplier: string, searchWord: any = "") {
  return useQuery({
    queryKey: ["assemblyMaterials", status, page],
    queryFn: async () => {
      await delay(1000); 
      return axiosInstance
        .get(`/assembly-materials?status=${status}&page=${page}&supplierUuid=${activeSupplier}&search=${searchWord}`)
        .then((res) => res.data);
    },
    select: (data: { assemblyMaterials: { data: AssemblyMaterialType[], meta: MetaType } }) => ({
      data: data?.assemblyMaterials?.data,
      meta: data?.assemblyMaterials?.meta,
    }),
    retry: false,
    enabled: status !== 'supplier',
    staleTime: 0, 
  });
}
