import { useQuery } from "@tanstack/react-query";
import axiosInstance from 'axiosConfig';


const usePhonePrefixes = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return axiosInstance
        .get(`/phonePrefixes/label`)
        .then((res) => res.data);
    },
    select: (data) =>
      data,
    retry: false,
  });
}


export default usePhonePrefixes;