import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type WindowsReplacementInfoData = {
  measurementUuid: string;
  windowsReplacementInstallation: Array<{ uuid: string, comment?: string }>;
  typesOfOldWindows: 'plastic' | 'woodenSwedish' | 'woodenCrates' | 'steel';
  oldWindowDisposal: boolean;
  spoutLevel: 'real' | 'specifiedByInvestor' | 'specifiedByContractor';
  roomListUuids: Array<{ uuid: string, measurement: string }>;
  kitchenWindowConnectedToTheCountertop: boolean;
  kitchenWindowConnectedToTheCountertopMeasurement?: string;
  oldWindowDismantlingInvestor: boolean;
  floorFurnitureProtection: boolean;
  preparationOfOpeningsForTripleLayerInstallation?: boolean | null;
  changeOfOpeningDimensions?: boolean | null;
  constructionSiteCleanupInvestor: boolean;
  additionWorkComment?: string;
};

const usePutWindowsReplacementClientInfo = (
  options?: UseMutationOptions<any, Error, WindowsReplacementInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editWindowsReplacementInfo'],
    mutationFn: async (data) => {
      return axiosInstance.put(`/measurement/windows-replacement/client-info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych wymiany okien...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-windows-replacement-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane wymiany okien zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-windows-replacement-info',
      });
      queryClient.invalidateQueries({ queryKey: ['windowsReplacementInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych wymiany okien.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-windows-replacement-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutWindowsReplacementClientInfo;
