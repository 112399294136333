export const checkAvailableHours = (events: any, newEvent: any): { start: string, end: string } | null => {
  const earliestTime = 6 * 60; // 6:00 AM
  const latestTime = 22 * 60; // 10:00 PM
  const duration = 60; // 1 hour

  const eventIntervals = events.map((event: any) => ({
    start: timeStringToMinutes(event.start),
    end: timeStringToMinutes(event.end),
  }));

  const isTimeSlotFree = (start: number, end: number): boolean => {
    return !eventIntervals.some((interval: any) =>
      (start >= interval.start && start < interval.end) ||
      (end > interval.start && end <= interval.end) ||
      (start <= interval.start && end >= interval.end)
    );
  };

  let newEventStartTime = timeStringToMinutes(newEvent.start);
  let newEventEndTime = timeStringToMinutes(newEvent.end);

  for (const interval of eventIntervals) {
    if (
      (newEventStartTime >= interval.start && newEventStartTime < interval.end) ||
      (newEventEndTime > interval.start && newEventEndTime <= interval.end) ||
      (newEventStartTime <= interval.start && newEventEndTime >= interval.end)
    ) {
      // console.log('termin jest zajęty', interval);

      for (let time = earliestTime; time <= latestTime - duration; time += 15) { // checking every 10 minutes for a free slot
        const potentialStart = time;
        const potentialEnd = time + duration;
        
        if (isTimeSlotFree(potentialStart, potentialEnd)) {
          // console.log('znaleziono wolny termin', minutesToTimeString(potentialStart), minutesToTimeString(potentialEnd));
          return { start: minutesToTimeString(potentialStart), end: minutesToTimeString(potentialEnd) };
        }
      }

      return null; // No available slot found
    }
  }

  // console.log('termin jest wolny');
  return { start: newEvent.start, end: newEvent.end };
};


const timeStringToMinutes = (time: string): number => {
  const [hoursStr, minutesStr] = time.split(':').map(str => str.trim());
  const hours = parseInt(hoursStr, 10);
  const minutes = minutesStr ? parseInt(minutesStr, 10) : 0; 
  return hours * 60 + minutes;
};

const minutesToTimeString = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};




// export const checkAvailableHours = (events: any, newEvent: any): { start: string, end: string } | null => {
//   const earliestTime = 6 * 60;
//   const latestTime = 22 * 60;
//   const newEventStartTime = timeStringToMinutes(newEvent.start);
//   const newEventEndTime = timeStringToMinutes(newEvent.end);

//   const eventIntervals = events.map((event: any) => ({
//     start: timeStringToMinutes(event.start),
//     end: timeStringToMinutes(event.end),
//   }));

//   eventIntervals.sort((a:any , b: any) => a.start - b.start);
//   let start = earliestTime;

//   for (const interval of eventIntervals) {
//     if (interval.start - start >= 60) {
//       if (newEventStartTime >= start && newEventEndTime <= interval.start) {
//         return { start: minutesToTimeString(start), end: minutesToTimeString(start + 60) };
//       }
//     } 
//     start = interval.end; 
//   }
  
//   if (latestTime - start >= 60) {
//     return { start: minutesToTimeString(start), end: minutesToTimeString(start + 60) };
//   } 
//   return {start: '', end: ''};
// };