/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { isMobile } from "utils";
import classNames from "classnames";

export type View = "month" | "week" | "day" | "agenda";

export function useCalendarState(
  initialDate: string,
  setDate: any,
  main: boolean,
  arrangingMeasurement?: (isOpen: boolean) => void
) {
  const [view, setView] = useState<View>("month");
  const [currentToolbar, setCurrentToolbar] = useState(null);
  const [activeDay, setActiveDay] = useState<string | null>(initialDate);
  const isMonthView = view === "month";
  const isDayView = view === "day";
  const isWeekView = view === "week";

  const minTime = new Date();
  minTime.setHours(6, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(22, 0, 0);

  const dateSplit = initialDate.split(" ");
  const timePart = dateSplit[1] ? `${dateSplit[1]}` : "";

  const formats = {
    dayFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, "dddd D", culture),
  };

  const localizer = momentLocalizer(moment);
  localizer.formats = {
    ...localizer.formats,
    weekdayFormat: (date, culture, localizer: any) =>
      localizer.format(date, isMobile() ? "dd" : "dddd", culture),
  };

  const getDisplayedWeekRange = (currentDate: any) => {
    const startOfWeek = moment(currentDate).startOf("week");
    const endOfWeek = moment(currentDate).endOf("week");

    let dates = [];
    for (
      let date = startOfWeek;
      date.diff(endOfWeek) <= 0;
      date.add(1, "days")
    ) {
      dates.push(date.format("YYYY-MM-DD"));
    }
    return dates;
  };

  const DayColumnWrapper = ({ children, date }: any) => {
    const weekDates = getDisplayedWeekRange(activeDay);
    useEffect(() => {
      if (arrangingMeasurement) {
        const handleClickRow = (event: any) => {
          const clickedElement = event.currentTarget;
          const dateString = clickedElement.getAttribute("data-date");
          const dateParts = dateString.split("/");
          const startTime = clickedElement.getAttribute("data-start-time");
          const endTime = clickedElement.getAttribute("data-end-time");
          const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
          const dateTimeRange = `${formattedDate} ${startTime}-${endTime}`;
          setDate && setDate(dateTimeRange);
        };

        const timeColumnsNodeList =
          document.querySelectorAll(".rbc-time-column");
        const timeColumns = Array.from(timeColumnsNodeList).slice(1);

        timeColumns.forEach((col, colIndex) => {
          const timeslotGroups = col.querySelectorAll(".rbc-timeslot-group");
          timeslotGroups.forEach((group, index) => {
            const timeForThisTile = moment()
              .hour(6)
              .minutes(0)
              .seconds(0)
              .add(index, "hours");
            const endTimeForThisTile = moment(timeForThisTile).add(1, "hours");
            const adjustedColIndex = colIndex;
            let currentDate = weekDates[adjustedColIndex]
              ? moment(weekDates[adjustedColIndex])
              : "";
            let currentDateAttribute = "";
            let uniqueId = "";
            if (currentDate !== "" && moment.isMoment(currentDate)) {
              if (isDayView) {
                currentDateAttribute = moment(activeDay).format("DD/MM/YYYY");
                uniqueId =
                  moment(activeDay).format("DDMMYYYY") +
                  "-" +
                  timeForThisTile.format("HH");
              } else {
                currentDateAttribute = currentDate.format("DD/MM/YYYY");
                uniqueId =
                  currentDate.format("DDMMYYYY") +
                  "-" +
                  timeForThisTile.format("HH");
              }
            }

            group.setAttribute("data-date", currentDateAttribute);
            group.setAttribute(
              "data-start-time",
              timeForThisTile.format("H:mm")
            );
            group.setAttribute(
              "data-end-time",
              endTimeForThisTile.format("H:mm")
            );
            if (uniqueId !== "") {
              group.setAttribute("id", `art-${uniqueId}`);
            }
            group.addEventListener("click", handleClickRow);
          });
        });
      }
    }, [weekDates]);

    const isBeforeToday = moment(date).isBefore(moment(), "day");
    const dayOfWeek = moment(date).day();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
    const dayColumnClass = classNames("rbc-day-slot rbc-time-column", {
      "rbc-day-slot--before-today": isBeforeToday && !main,
      "rbc-day-slot--weekend": isWeekend,
    });

    const now = moment();
    const isToday = now.isSame(date, "day");
    const calendarStartHour = 6;
    const calendarEndHour = 22;
    const minutesSinceStartOfDay = now.diff(
      moment(now).startOf("day"),
      "minutes"
    );
    const totalCalendarMinutes = (calendarEndHour - calendarStartHour) * 60;
    const minutesSinceCalendarStart =
      minutesSinceStartOfDay - calendarStartHour * 60;
    const currentTimeIndicatorPosition = isToday
      ? (minutesSinceCalendarStart / totalCalendarMinutes) * 100
      : null;

    return (
      <div className={dayColumnClass}>
        {children}
        {isToday && isDayView && (
          <div
            className="calendar-day__hour-line"
            style={{
              top: `calc(${currentTimeIndicatorPosition}%)`,
            }}
          />
        )}
      </div>
    );
  };

  return {
    view,
    setView,
    currentToolbar,
    setCurrentToolbar,
    activeDay,
    setActiveDay,
    minTime,
    maxTime,
    isMonthView,
    isDayView,
    isWeekView,
    dateSplit,
    timePart,
    formats,
    localizer,
    DayColumnWrapper,
  };
}
