import { Button, Modal } from "components";
import { useDeleteSupplier } from "hooks/supplier";

interface DeleteSupplierType {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  material: any;
  setPage: (page: number) => void;
  page: number;
}

const DeleteSupplier = ({
  isOpen,
  onClose,
  data,
  material,
  page,
  setPage,
}: DeleteSupplierType) => {
  const { mutate: deleteSupplier } = useDeleteSupplier({
    onSuccess: () => {
      onClose();
    },
  });

  const handleDelete = async () => {
    await deleteSupplier({ uuid: data.uuid });
    if (material.isLastElement) {
      setPage(page - 1);
    }
    onClose();
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Usuwanie dostawcy</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć dostawcę
            <span>{data.name}</span>?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={() => handleDelete()} label="Potwierdź" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteSupplier;
