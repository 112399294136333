import { useSearchParams } from "react-router-dom";
import {
  WindowsReplacementGeneralInformation,
  WindowsReplacementInstallationInformation,
  WindowsReplacementMeasurementValues,
} from "./components";

interface WindowsReplacementType {
  setStep: (page: number) => void;
  step: number;
  measurementInfo: any;
  prevStep: () => void;
}

const WindowsReplacementForms = ({
  step,
  setStep,
  measurementInfo,
  prevStep,
}: WindowsReplacementType) => {
  const [searchParams] = useSearchParams();
  const measurementUuid = searchParams.get("measurementUuid");

  const handleShowForm = (step: number, measurementUuid: string | null) => {
    switch (step) {
      case 2:
        return (
          <WindowsReplacementGeneralInformation
            measurementUuid={measurementUuid}
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <WindowsReplacementInstallationInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <WindowsReplacementMeasurementValues
            setStep={setStep}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      default:
        return <p>def</p>;
    }
  };

  return handleShowForm(step, measurementUuid);
};

export default WindowsReplacementForms;
