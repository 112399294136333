import { Input, StaticAlert, Textarea } from "components";
import { Field } from "formik";

import "./joinery-dimension.scss";

interface JoineryDimensionType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  label: string;
}

const JoineryDimension = ({
  errors,
  form,
  field,
  label,
  validateField,
}: JoineryDimensionType) => {
  return (
    <div className="hole-dimension">
      <div className="am-section-heading">{label}</div>
      <Field
        label="Szerokość"
        name="joinerySizeWidth"
        type="number"
        rightPlaceholder="mm"
        component={Input}
        onChange={() => validateField("holeSizeWidth")}
      />
      <Field
        label="Wysokość"
        name="joinerySizeHeight"
        type="number"
        rightPlaceholder="mm"
        onChange={() => validateField("holeSizeHeight")}
        component={Input}
      />

      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={
              (errors["joinerySizeWidth"] && errors["joinerySizeWidth"]) ||
              (errors["joinerySizeHeight"] && errors["joinerySizeHeight"])
            }
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}

      <Field
        label="Dodatkowe uwagi"
        name="windowComment"
        component={Textarea}
        onChange={(e: string) => form.setFieldValue("windowComment", e)}
        maxLength={250}
      />

      <div className="am-separator" />
    </div>
  );
};

export default JoineryDimension;
