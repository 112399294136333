enum VALIDATION_MESSAGES {
  REQUIRED = 'To pole jest wymagane',
  MIN3 = 'Minimum 3 znaki',
  MIN10 = 'Minimum 10 znaków',
  MAX150 = 'Maksymalnie 150 znaków',
  PHONE_LENGTH = 'Numer telefonu musi mieć 9 cyfr',
  TERMS = 'Musisz zaakceptować regulamin',
  EMAIL = 'Niepoprawny adres email',
  PASSWORD_LENGTH = 'Hasło musi mieć minimum 8 znaków',
  MAX_ONE_SPACE = 'Możesz użyć maksymalnie jednej spacji',
  PASSWORD_MISMATCH = 'Hasła muszą być takie same',
  CANNOT_SET_OLD_PASSWORD = 'Nowe hasło nie może być takie samo jak aktualne',
  CANNOT_START_WITH_SPACE = 'Hasło nie może zaczynać się spacją',
  MIN_LENGTH_3 = 'Pole musi mieć minimum 3 znaki',
  PASSWORD_MATCH = 'Hasła muszą być takie same',
  CANNOT_START_AND_END_WITH_SPACE = 'Pole nie może zaczynać się ani kończyć spacją',
}

export default VALIDATION_MESSAGES;
