import {
  WindowsInRowFormGeneralInformation,
  WindowsInRowFormInstallationInformation,
  WindowsInRowFormMeasurementValues,
} from "./components";
import { useSearchParams } from "react-router-dom";

interface WindowsInRowFormType {
  setStep: (page: number) => void;
  step: number;
  measurementInfo: any;
  prevStep: () => void;
}
const WindowsInRowForms = ({
  step,
  setStep,
  measurementInfo,
  prevStep,
}: WindowsInRowFormType) => {
  const [searchParams] = useSearchParams();
  const measurementUuid = searchParams.get("measurementUuid");

  const handleShowForm = (step: number, measurementUuid: string | null) => {
    switch (step) {
      case 2:
        return (
          <WindowsInRowFormGeneralInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <WindowsInRowFormInstallationInformation
            setStep={setStep}
            step={step}
            measurementUuid={measurementUuid}
            measurementInfo={measurementInfo}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <WindowsInRowFormMeasurementValues
            measurementUuid={measurementUuid}
            measurementInfo={measurementInfo}
            prevStep={prevStep}
            setStep={setStep}
          />
        );
      default:
        return <p>default</p>;
    }
  };

  return handleShowForm(step, measurementUuid);
};

export default WindowsInRowForms;
