/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button, FloatingMenu } from "components";
import {
  DeleteUser,
  BlockUser,
  AddUser,
  EditUser,
  UsersTable,
} from "features/users";
import { UserType } from "types/UsersTypes";
import { useResendActivationLink } from "hooks/users";
import { useGetLocations } from "hooks/utils";
import { useSearchParams } from "react-router-dom";

const Users = () => {
  const { mutate: resendActivationLink } = useResendActivationLink();
  const [searchParams, setSearchParams] = useSearchParams();
  const userLocation = searchParams.get("location");
  const linkPage = searchParams.get("page");
  const [modalManage, setModalManage] = useState({ type: "", user: {} });
  const [page, setPage] = useState(Number(linkPage) || 1);

  const { data: locations } = useGetLocations();
  const modifiedLocations = [
    { label: "Wszyscy pracownicy", value: "1" },
    ...(locations || []),
    { label: "Administratorzy", value: "0" },
  ];
  const locationFromLink = modifiedLocations.find(
    (l) => l.value === userLocation
  );
  const [activeFloatingMenuItem, setActiveFloatingMenuItem] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (locationFromLink) {
      setActiveFloatingMenuItem(locationFromLink);
    }
  }, [locations]);

  const isAdminTab = activeFloatingMenuItem.value === "0";
  const isDeleteUserOpenModal = modalManage.type === "delete";
  const isBlockUserOpenModal = modalManage.type === "block";
  const isAddUserOpenModal = modalManage.type === "addUser";
  const isEditUserOpenModal = modalManage.type === "editUser";
  const clearModalManage = () => {
    setModalManage({ type: "", user: modalManage.user });
  };
  const setDeleteUser = (user: UserType) =>
    setModalManage({ type: "delete", user });
  const setBlockUser = (user: UserType) =>
    setModalManage({ type: "block", user });
  const setAddUser = () => setModalManage({ type: "addUser", user: {} });
  const setEditUser = (user: UserType) =>
    setModalManage({ type: "editUser", user });
  const resendActivateMail = (email: string) => {
    resendActivationLink({ email });
  };

  const handleChangeTab = (item: any) => {
    setPage(1);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("location", item.value);
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <DeleteUser
        user={modalManage.user}
        isOpen={isDeleteUserOpenModal}
        onClose={() => clearModalManage()}
      />
      <BlockUser
        user={modalManage.user}
        isOpen={isBlockUserOpenModal}
        onClose={() => clearModalManage()}
      />
      <AddUser
        isAdminForm={isAdminTab}
        isOpen={isAddUserOpenModal}
        onClose={() => clearModalManage()}
      />
      <EditUser
        user={modalManage.user}
        isAdminForm={isAdminTab}
        isOpen={isEditUserOpenModal}
        onClose={() => clearModalManage()}
      />
      <div className="dashboard-tab-name-with-button dashboard-tab-name-with-button--only-mobile">
        <div className="dashboard-tab-name">Użytkownicy</div>
        <Button className="btn-plus" label="+" onClick={() => setAddUser()} />
      </div>
      <div>
        <div className="floating-menu-with-button">
          <FloatingMenu
            items={modifiedLocations}
            activeFloatingMenuItem={activeFloatingMenuItem}
            setActiveFloatingMenuItem={setActiveFloatingMenuItem}
            onClick={(item) => handleChangeTab(item)}
          />
          <Button size="medium" onClick={() => setAddUser()} label="Dodaj +" />
        </div>
        <UsersTable
          page={page}
          setPage={setPage}
          isAdminTab={isAdminTab}
          activeFloatingMenuItem={activeFloatingMenuItem}
          editUser={setEditUser}
          blockUser={setBlockUser}
          deleteUser={setDeleteUser}
          resendActivateMail={resendActivateMail}
        />
      </div>
    </>
  );
};

export default Users;
