import { useEffect, useState } from "react";
import "./sms-code-input.scss";

interface Props {
  onCodeChange: (code: string) => void;
  className?: string;
}

interface CodeType {
  [key: number]: string | number;
}

function SmsCodeInput({ className = "", onCodeChange }: Props) {
  const [code, setCode] = useState<CodeType>({ 1: "", 2: "", 3: "", 4: "" });
  const [focusIndex, setFocusIndex] = useState<number>(0);

  const handleCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const newNumber = event.target.value.replace(/\D/g, ""); // Only allow digits
    const newCode = { ...code, [i]: newNumber };
    setCode(newCode);
    if (i < 3 && newNumber.length > 0) {
      setFocusIndex(i + 1);
    }
    onCodeChange(Object.values(newCode).join(""));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const BACKSPACE = 8;
    const ARROW_LEFT = 37;
    const ARROW_RIGHT = 39;

    if (event.keyCode === BACKSPACE && focusIndex > 0 && !code[focusIndex]) {
      setFocusIndex(focusIndex - 1);
    } else if (event.keyCode === ARROW_LEFT && focusIndex > 0) {
      setFocusIndex(focusIndex - 1);
    } else if (event.keyCode === ARROW_RIGHT && focusIndex < 3) {
      setFocusIndex(focusIndex + 1);
    }
  };

  useEffect(() => {
    const input = document.getElementById(`sms-code-input--${focusIndex}`);
    if (input) {
      input.focus();
    }
  }, [focusIndex]);

  const renderSquares = () => {
    const squares = [];
    for (let i = 0; i < 4; i++) {
      const isActive = focusIndex === i;
      const isFilled = !!code[i];
      squares.push(
        <input
          key={i}
          id={`sms-code-input--${i}`}
          className={`sms-code-input sms-code-input__square  ${className} ${
            isActive ? "sms-code-input--active" : ""
          } ${isFilled ? "sms-code-input--filled" : ""}`}
          type="tel"
          maxLength={1}
          value={code[i] || ""}
          onChange={(e) => handleCodeChange(e, i)}
          onKeyDown={handleKeyDown}
          onFocus={() => setFocusIndex(i)}
        />
      );
    }
    return squares;
  };

  return <div className="sms-code-input__wrapper">{renderSquares()}</div>;
}

export default SmsCodeInput;
