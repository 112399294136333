import { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { SingleSectionElement } from "features/addingMeasurement";
import { Button, Input, Textarea } from "components";
import { VALIDATION_MESSAGES } from "enums";
import { usePutFloorInstallationInfo } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";

interface FloorInstallationInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const FloorInstallationInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: FloorInstallationInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementInstallationInfo =
    measurementInfo?.measurementInstallationInfo;

  const { mutate: editInstallationInfo } = usePutFloorInstallationInfo({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "4");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
  });

  const validationSchema = Yup.object().shape({
    screedHumidity: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    airHumidity: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const handleOnSubmit = (values: any) => {
    setStep(step + 1);
    editInstallationInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          screedHumidity:
            initialMeasurementInstallationInfo?.screedHumidity || "",
          airHumidity: initialMeasurementInstallationInfo?.airHumidity || "",
          numberOfAssemblyTeams:
            initialMeasurementInstallationInfo?.numberOfAssemblyTeams || "",
          plannedAssemblyTime:
            initialMeasurementInstallationInfo?.plannedAssemblyTime || "",
          comments: initialMeasurementInstallationInfo?.comments || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                touched={touched}
                name="screedHumidity"
                errors={errors}
              >
                <Field
                  label="Wilgotność wylewki"
                  name="screedHumidity"
                  withDot
                  rightPlaceholder="%"
                  maxLength={5}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="airHumidity"
                errors={errors}
              >
                <Field
                  label="Wilgotność powietrza"
                  name="airHumidity"
                  withDot
                  maxLength={5}
                  type="number"
                  rightPlaceholder="%"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  maxLength={6}
                  type="number"
                  rightPlaceholder="h"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  maxLength={3}
                  type="number"
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FloorInstallationInformation;
