import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type ToggleAssemblyMaterialVisibilityType = {
  uuid: string;
};

const useToggleAssemblyMaterialVisibility = (
  options?: UseMutationOptions<any, Error, ToggleAssemblyMaterialVisibilityType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['toggleAssemblyMaterialVisibility'],
    mutationFn: async ({ uuid }) => {
      return axiosInstance.put(`/assembly-materials/hide/${uuid}`).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania zmiany widoczności materiału montażowego...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'toggle-assembly-material-visibility',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Widoczność materiału montażowego zmieniona pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'toggle-assembly-material-visibility',
      });
      queryClient.invalidateQueries({ queryKey: ['assemblyMaterials'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas zmiany widoczności materiału montażowego.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'toggle-assembly-material-visibility',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useToggleAssemblyMaterialVisibility;
