import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface DeleteActivityData {
  activitiesUuid: string;
}

const useDeleteActivity = (
  options?: UseMutationOptions<any, Error, DeleteActivityData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['deleteActivity'],
    mutationFn: async (data: DeleteActivityData) => {
      return axiosInstance.delete(`/activity`, { data }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Usuwanie czynności...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'delete-activity',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Czynność została pomyślnie usunięta.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'delete-activity',
      });
      queryClient.invalidateQueries({ queryKey: ['userActivities'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas usuwania czynności.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'delete-activity',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useDeleteActivity;
