import { Modal, SVG } from "components";
import { SVG_TYPE, ROUTE_ENUM } from "enums";
import moment from "moment";
import { useNavigate } from "react-router";

interface CalendarEventModalType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const CalendarEventModal = ({
  isOpen,
  onClose,
  data,
}: CalendarEventModalType) => {
  const navigate = useNavigate();

  const handleClickEdit = () => {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${data.original.uuid}&tab=${data.original.status}&edit=true`
    );
  };

  const handleClose = () => {
    onClose();
  };

  const formattedDate = moment(data?.original?.date, "DD/MM/YYYY").format(
    "dddd, D MMMM"
  );

  return (
    <>
      <Modal
        className="modal--calendar-event-modal"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="cpp__wrapper" onClick={(e) => e.stopPropagation()}>
            <div className="cpp__content" onClick={(e) => e.stopPropagation()}>
              <div className="cpp__top" onClick={(e) => e.stopPropagation()}>
                <div className="cpp__actions">
                  <div
                    className="cpp__action"
                    onClick={() => handleClickEdit()}
                  >
                    <SVG type={SVG_TYPE.EDIT_PENCIL} />
                  </div>
                  <div className="cpp__action" onClick={() => handleClose()}>
                    <SVG type={SVG_TYPE.CLOSE_CIRCLE} />
                  </div>
                </div>
              </div>
              <div className="cpp__event">
                <div className="cpp__event-name">{data?.original?.title}</div>
                <div className="cpp__event-date">
                  {formattedDate} {data?.original?.start}-{data?.original?.end}
                </div>
                <div className="cpp__event-address">
                  {data?.original?.address}
                </div>
                <div className="cpp__event-user">
                  <div
                    style={{ backgroundColor: data?.original?.userColor }}
                    className="cpp__event-user-box"
                  ></div>
                  <div className="cpp__event-user-name">
                    {data?.original?.userName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default CalendarEventModal;
