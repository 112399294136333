import "./order-status.scss";
import classNames from "classnames";

interface OrderStatusType {
  status?: string;
  className?: string;
}

const OrderStatus = ({ status, className = "" }: OrderStatusType) => {
  const orderStatusClass = classNames("order-status", className, {
    "order-status--to-determine":
      status === "measurementsToBeReleased" ||
      status === "installationToBeReleased",
    "order-status--fixedMeasurements": status === "fixedMeasurements",
    "order-status--verification": status === "weryfikacja",
    "order-status--measurement": status === "Montaż",
    "order-status--fitter": status === "Pomiar",
  });

  return (
    <div className={orderStatusClass}>
      <div className="order-status__label">
        {status === "measurementsToBeReleased" && "Pomiar do ustalenia"}
        {status === "installationToBeReleased" && "Montaż do ustalenia"}
        {status === "fixedMeasurements" && "Pomiar ustalony"}
        {status === "weryfikacja" && "Weryfikacja"}
        {status === "Montaż" && "Montaż"}
        {status === "Pomiar" && "Pomiar"}
      </div>
    </div>
  );
};

export default OrderStatus;
