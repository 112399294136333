import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type InteriorDoorsInfoData = {
  measurementUuid: string;
  roomName: string;
  durability: 'left' | 'right';
  openningDirection: 'inward' | 'outward';
  holeSizeWidth: string;
  holeSizeHeight: string;
  customDoors: boolean;
  doorSize?: string; 
  doorWidth?: string; 
  doorHeight?: string; 
  wallThickness: string;
  verticalDeviation: string;
  wereTheWallsFullyFinishedDuringTheMeasurement: boolean;
  whatIsThePlannedWallThickness?: string;
  doorFrameDimensions: string;
  widthOfStrips: '60' | '90';
  wasTheFloorFinishedDuringTheMeasurement: boolean;
  whatIsThePlannedCladdingThickness?: string; 
  isTheOpeningCorrect: boolean;
  whatShouldBeTheCorrectWidth?: string;
  whatShouldBeTheCorrectHeight?: string; 
  whoIsToPrepareTheOpening: 'investor' | 'martdom';
  cuttingOfStrips: boolean;
  numberOfCuts?: string; 
  manufacturer?: string;
  model?: string;
  color?: string;
  lockType?: 'standard' | 'magnetic';
  lock?: 'economy' | 'key' | 'wc' | 'patented';
  ventilation?: 'lack' | 'ventilationUndercut' | 'ventilationAbbreviation' | 'ventilationSleeves';
  edgeType?: 'rebated' | 'flush' | 'reversible' | 'sliding';
  frameType?: 'adjustable' | 'fixed' | 'tunnel' | 'none';
};

const usePutInteriorDoorsInfo = (
  options?: UseMutationOptions<any, Error, InteriorDoorsInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editInteriorDoorsInfo'],
    mutationFn: async (data) => {
      return axiosInstance.put(`/measurement/interior-doors/info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych drzwi wewnętrznych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-interior-doors-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane drzwi wewnętrznych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-interior-doors-info',
      });
      queryClient.invalidateQueries({ queryKey: ['interiorDoorsInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych drzwi wewnętrznych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-interior-doors-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutInteriorDoorsInfo;
