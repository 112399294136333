import { Button, Modal } from "components";
import { useDeleteActivityTime } from "hooks/activity-time";
interface DeleteWorkingTimeType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteWorkingTime = ({
  isOpen,
  onClose,
  data,
}: DeleteWorkingTimeType) => {
  const { mutate: deleteActivity } = useDeleteActivityTime({
    onSuccess: () => {
      onClose();
    },
  });

  const handleDeleteTime = (timeManagementUuid: string) => {
    deleteActivity({ timeManagementUuid });
  };
  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Usuwanie czasu pracy</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć dodany czas pracy w elemencie
            <span>
              {data?.item?.activityName ? data.item.activityName : data.name}
            </span>
            ?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button
              onClick={() => handleDeleteTime(data.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteWorkingTime;
