import { useSearchParams } from "react-router-dom";
import {
  InteriorDoorInstallationInformation,
  InteriorDoorMeasurementValues,
} from "./components";

interface InteriorDoorFormType {
  setStep: (page: number) => void;
  step: number;
  measurementInfo: any;
  prevStep: () => void;
}

const InteriorDoorForm = ({
  step,
  setStep,
  measurementInfo,
  prevStep,
}: InteriorDoorFormType) => {
  const [searchParams] = useSearchParams();
  const measurementUuid = searchParams.get("measurementUuid");

  const handleShowForm = (step: number, measurementUuid: string | null) => {
    switch (step) {
      case 2:
        return (
          <InteriorDoorInstallationInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <InteriorDoorMeasurementValues
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      default:
        return <p>default</p>;
    }
  };

  return handleShowForm(step, measurementUuid);
};

export default InteriorDoorForm;
