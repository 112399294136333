import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type EditAssemblyMaterialType = {
  uuid: string;
  cost: string;
  name: string;
  date: string;
  supplierUuid: string;
};

const useEditAssemblyMaterial = (
  options?: UseMutationOptions<any, Error, EditAssemblyMaterialType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editAssemblyMaterial'],
    mutationFn: async ({ uuid, cost, name, date, supplierUuid }) => {
      return axiosInstance.put(`/assembly-materials/${uuid}`, {
        cost,
        name,
        date,
        supplierUuid,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania edycji materiału montażowego...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-assembly-material',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Materiał montażowy zaktualizowany pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-assembly-material',
      });
      queryClient.invalidateQueries({ queryKey: ['assemblyMaterials'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji materiału montażowego.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-assembly-material',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useEditAssemblyMaterial;
