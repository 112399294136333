import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonRow = ({ width = "100%", height = 20, count = 1 }) => {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <div key={index} style={{ margin: "10px 0" }}>
            <Skeleton width={width} height={height} />
          </div>
        ))}
    </>
  );
};

export default SkeletonRow;
