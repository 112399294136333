import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { ConstructionSiteType } from "types/MeasurementTypes";

export default function useGetConstructionSites(types: any) {
  return useQuery({
    queryKey: ["constructionSites", types],
    queryFn: async () => {
      const response = await axiosInstance.get(`/construction-sites?types=${types}`);
      return response.data;
    },
    select: (data: { constructionSitesLabel: ConstructionSiteType[] }) => data.constructionSitesLabel,
    retry: false,
  });
}
