/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Modal, Button, SVG } from "components";
import { SVG_TYPE } from "enums";
import { useGetAssemblyMaterialHistory } from "hooks/assembly-materials";
import "./history-assembly-material.scss";

interface HideAssemblyMaterialsType {
  isOpen: boolean;
  material: any;
  onClose: () => void;
}

const HistoryAssemblyMaterial = ({
  isOpen,
  material,
  onClose,
}: HideAssemblyMaterialsType) => {
  const { data, refetch } = useGetAssemblyMaterialHistory(
    material.uuid,
    isOpen
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return (
    <Modal
      className="history-assembly-material history-assembly-material__modal"
      side="right"
      isOpened={isOpen}
      onClose={() => onClose()}
    >
      <>
        <div className="modal-heading__with-close">
          <div className="modal-heading">Historia cen</div>
          <div className="modal-heading__close" onClick={() => onClose()}>
            Zamknij <SVG type={SVG_TYPE.CLOSE} />
          </div>
        </div>
        <div className="history-assembly-material__name">{data?.name}</div>

        <div className="history-assembly-material-list">
          {data?.costHistory &&
            data?.costHistory.length > 0 &&
            data?.costHistory.map((item: any) => {
              const isNow = item.toDate === "now";
              return (
                <div className="history-assembly-material-list-item">
                  <div className="history-assembly-material-list-item__left">
                    {isNow
                      ? `od ${item.fromDate}`
                      : `${item.fromDate} - ${item.toDate}`}
                  </div>
                  <div className="history-assembly-material-list-item__right">
                    {item.cost} zł/h
                  </div>
                </div>
              );
            })}
        </div>
        <div className="modal-buttons">
          <Button label="zamknij" onClick={() => onClose()} />
        </div>
      </>
    </Modal>
  );
};

export default HistoryAssemblyMaterial;
