import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type BuildingMaterial = 'ceramicBlock' | 'yTong' | 'silicate' | 'woodenStructure' | 'other';

type ExternalDoorsInstallationInfoData = {
  measurementUuid: string;
  theBuildingIsMadeOf: BuildingMaterial;
  theBuildingIsMadeOfComment?: string;
  isThereAPlanForARoofAboveTheDoor: boolean;
  plannedAssemblyTime: string; 
  numberOfAssemblyTeams: string; 
  comments?: string;
};

const usePutExternalDoorsInstallationInfo = (
  options?: UseMutationOptions<any, Error, ExternalDoorsInstallationInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editExternalDoorsInstallationInfo'],
    mutationFn: async (data: ExternalDoorsInstallationInfoData) => {
      return axiosInstance.put(`/measurement/external-doors-in-raw-condition/installation-info`, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych dotyczących montażu drzwi zewnętrznych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-external-doors-installation-info',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane dotyczące montażu drzwi zewnętrznych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-external-doors-installation-info',
      });
      queryClient.invalidateQueries({ queryKey: ['externalDoorsInstallationInfo'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych dotyczących montażu drzwi zewnętrznych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-external-doors-installation-info',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutExternalDoorsInstallationInfo;
