import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { queryClient } from 'App';
import { StartMeasurementTypes } from 'types/MeasurementTypes';

const useStartMeasurement = (
  options?: UseMutationOptions<any, Error, StartMeasurementTypes>
) => {
  return useMutation({
    ...options,
    mutationKey: ['startMeasurement'],
    mutationFn: async ({typeOfMeasurementUuid, orderUuid, measurementUuid}: StartMeasurementTypes) => {
      return axiosInstance.post('/measurement', {
        orderUuid,
        typeOfMeasurementUuid,
        measurementUuid,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['measurements'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useStartMeasurement;
