import { Input, Select, Textarea, Switch } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
  TypeOfRadiobox,
} from "features/addingMeasurement";

interface SectionalGateFormType {
  touched: any;
  errors: any;
  validateField: any;
}

const SectionalGateForm = ({
  touched,
  errors,
  validateField,
}: SectionalGateFormType) => {
  return (
    <>
      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Czy podczas pomiaru były tynki?`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Czy planowany jest styropian na suficie?`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Czy są przeszkody utrudniające montaż (wyłaz, dzrwi, belki)?`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
      </SingleSectionElement>
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Wysokość nadproża"
          rightPlaceholder="mm"
          number
          name={`color`}
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Szerokość przestrzeni bocznej prawej"
          rightPlaceholder="mm"
          number
          name={`color`}
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Szerokość przestrzeni bocznej lewej"
          rightPlaceholder="mm"
          number
          name={`color`}
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Głębokość garażu"
          rightPlaceholder="mm"
          number
          name={`color`}
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips"
        touched={touched}
        name="roomName"
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="holeSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="holeSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      </SingleSectionElement>
      <Field
        errors={errors}
        name="theBuildingIsMadeOf"
        label="Typ prowadzenia"
        options={[
          {
            label: "Przód",
            value: "ceramicBlock",
            uuid: "ceramicBlock",
          },
          {
            label: "Tył",
            value: "woodenStructure",
            uuid: "woodenStructure",
          },
          {
            label: "Bok",
            value: "yTong",
            uuid: "yTong",
          },
        ]}
        component={TypeOfRadiobox}
      />
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          label="Sposób otwierania"
          name="open"
          type="number"
          options={[
            {
              value: "hands",
              label: "Ręczna",
            },
            {
              value: "automatic",
              label: "Automatyczna",
            },
          ]}
          component={Select}
          white
        />
        <Field
          label="Rodzaj szyny"
          name="open"
          type="number"
          options={[
            {
              value: "chain",
              label: "Łańcuch",
            },
            {
              value: "nosymetric",
              label: "Pasek",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Rozblokowanie napędu z zewnątrz?`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Drzwi w bramie?`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
        <Field
          label="Położenie drzwi (widok od wewnątrz)"
          name="open"
          type="number"
          options={[
            {
              value: "right",
              label: "Po prawej",
            },
            {
              value: "left",
              label: "Po lewej",
            },
            {
              value: "middle",
              label: "Po środku",
            },
          ]}
          component={Select}
          white
        />
        <Field
          label="Stronność drzwi"
          name="open"
          type="number"
          options={[
            {
              value: "left",
              label: "Po lewej",
            },
            {
              value: "right",
              label: "Po prawej",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Dodatkowe podwieszki do prowadnic`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
        <Field
          className="am-margin-top"
          errors={errors}
          label="Dodatkowe uwagi"
          name="comment"
          component={Textarea}
          maxLength={250}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Typ bramy segmentowej"
          name="color"
          optional
          component={Input}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label={`Kolor`}
          name={`color`}
          optional
          component={Input}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Rodzaj przetłoczeń"
          name="color"
          optional
          component={Input}
        />
      </SingleSectionElement>

      <SingleSectionElement
        touched={touched}
        name="isTheOpeningCorrect"
        errors={errors}
      >
        <Field
          label={`Przeszklenia w bramie`}
          name="isTheOpeningCorrect"
          component={Switch}
        />
      </SingleSectionElement>

      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        name="comment"
        optional
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default SectionalGateForm;
