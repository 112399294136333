import { useState } from "react";
import { Modal, Switch, Input, Button, StaticAlert } from "components";
import { Field, FieldArray, Formik } from "formik";
import "./manage-activity-list.scss";
import * as Yup from "yup";
import { useCreateActivity, useUpdateActivity } from "hooks/activity";
import { VALIDATION_MESSAGES } from "enums";

interface ManageActivityListType {
  isOpen: boolean;
  onClose: () => void;
  order: any;
  isMeasurer: boolean;
  isEdit?: boolean;
  page: number;
}

const ManageActivityList = ({
  isOpen,
  onClose,
  isEdit,
  order,
}: ManageActivityListType) => {
  const [isToLittle, setIsToLittle] = useState(false);
  const validationSchema = Yup.object().shape({
    activities: Yup.array()
      .of(
        Yup.string()
          .required(VALIDATION_MESSAGES.REQUIRED)
          .min(3, VALIDATION_MESSAGES.MIN3)
          .matches(
            /^\S.*\S$/,
            VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
          )
      )
      .min(1, "Musisz podać co najmniej jedną czynność."),
  });
  const mutation = useCreateActivity();

  const editmutation = useUpdateActivity();

  const handleSubmitForm = (e: any) => {
    const isMany = e.numberOfActivities === "many";
    const min2 = e.activities.length >= 2;
    if (isMany) {
      if (min2) {
        setIsToLittle(false);
        isEdit ? editmutation.mutate(e) : mutation.mutate(e);
        onClose();
      } else {
        setIsToLittle(true);
      }
    } else {
      isEdit ? editmutation.mutate(e) : mutation.mutate(e);
      onClose();
    }
  };

  return (
    <Modal
      side="right"
      className="order-preview"
      isOpened={isOpen}
      onClose={() => {
        onClose();
        setIsToLittle(false);
      }}
    >
      <div className="modal-heading">
        <div className="modal-heading">
          {isEdit ? "Edycja" : "Dodawanie"} czynności
        </div>
        <Formik
          initialValues={{
            numberOfActivities: isEdit ? order.numberOfActivities : "one",
            activities: isEdit ? order.activities : [""],
            isMeasurement: isEdit ? order.isMeasurement : true,
            isInstallation: isEdit ? order.isInstallation : true,
            activitiesUuid: isEdit ? order.uuid : "",
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitForm(values)}
        >
          {({ handleSubmit, values, setFieldValue, errors }) => {
            const isActiveButton =
              values.numberOfActivities === "many" &&
              values.activities.length < 10;
            const handleActivitySwitchChange = (e: any) => {
              const isChecked = e.target.checked;
              const newValue = isChecked ? "one" : "many";
              if (isChecked) {
                setFieldValue("activities", [`${values.activities[0]}`]);
                setIsToLittle(false);
              }
              setFieldValue("numberOfActivities", newValue);
            };
            return (
              <form className="mal mal__form form" onSubmit={handleSubmit}>
                <Field
                  secondary
                  first="Jedna czynność"
                  second="Czynność do wyboru"
                  valueFirst="one"
                  valueSecond="many"
                  stringMode
                  name="numberOfActivities"
                  component={Switch}
                  className="switch__wrapper--form"
                  onChange={handleActivitySwitchChange}
                />
                <FieldArray
                  name="activities"
                  render={(arrayHelpers) => (
                    <div className="mal__form-activities">
                      {values?.activities.map((_: any, index: any) => (
                        <Field
                          errors={errors}
                          key={index}
                          label={`Nazwa czynności`}
                          name={`activities.${index}`}
                          component={Input}
                          bin
                        />
                      ))}
                      {isActiveButton && (
                        <Button
                          stroke
                          label="Dodaj kolejną"
                          size="small"
                          onClick={() => {
                            arrayHelpers.push("");
                            setIsToLittle(false);
                          }}
                        />
                      )}
                    </div>
                  )}
                />
                <div className="modal-sub-heading">Etap</div>
                <Field
                  checked={values.isMeasurement}
                  secondary
                  label="Pomiar"
                  name="isMeasurement"
                  component={Switch}
                />
                <Field
                  checked={values.isInstallation}
                  secondary
                  label="Montaż"
                  name="isInstallation"
                  component={Switch}
                />
                <StaticAlert
                  show={isToLittle}
                  label="Musisz podać co najmniej dwie czynności."
                />
                <div className="modal-buttons mal__form-buttons">
                  <Button
                    stroke
                    onClick={() => {
                      onClose();
                      setIsToLittle(false);
                    }}
                    label="Anuluj"
                  />
                  <Button onClick={() => handleSubmit()} label="Potwierdź" />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ManageActivityList;
