import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig'; 
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums'; 
import { useNavigate } from 'react-router-dom';
import { ROUTE_ENUM } from "enums";

export const useLogout = (
  options?: UseMutationOptions<any, Error, void>
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient(); 

  return useMutation({
    ...options,
    mutationKey: ['logout'],
    mutationFn: async () => {
      return axiosInstance.put('/auth/logout')
        .then((res) => res.data);
    },
    onMutate: () => {
      // Toast({
      //   message: 'Wylogowywanie...',
      //   type: TOAST_TYPES_ENUM.LOADING,
      //   id: 'logout',
      // });
      if (options && options.onMutate) {
        return options.onMutate();
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Pomyślnie wylogowano.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'logout',
      });
      const recoveryAgain = sessionStorage.getItem("loginAdmin");
      if (recoveryAgain) {
        navigate(ROUTE_ENUM.LOGIN);
        sessionStorage.removeItem("loginAdmin");
        queryClient.clear();
      } else {
        navigate(ROUTE_ENUM.HOME);
        queryClient.clear();
      }
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      const recoveryAgain = sessionStorage.getItem("loginAdmin");
      if (recoveryAgain) {
        navigate(ROUTE_ENUM.LOGIN);
        sessionStorage.removeItem("loginAdmin");
        queryClient.clear();
      } else {
        navigate(ROUTE_ENUM.HOME);
        queryClient.clear();
      }
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

