import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

interface OrderType {
  title: string;
  start: string;
  end: string;
  id: number;
  date: string;
}

interface OrdersResponseType {
  orders: OrderType[];
}

export default function useGetOrdersByEmployee(employeeUuid: string | null, month: string | null) {
  return useQuery<OrdersResponseType, Error, OrderType[]>({
    queryKey: ["ordersByEmployee", employeeUuid, month],
    queryFn: async () => {
      const params = {
        employeeUuid,
        month,
      };
      return axiosInstance
        .get("/orders/date/employee", { params })
        .then((res) => res.data);
    },
    select: (data) => data.orders,
    retry: false,
    enabled: !!employeeUuid && month !== null,
  });
}
