/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button, FloatingMenu, Input } from "components";
import { Field, Formik } from "formik";
import {
  AddAssemblyMaterials,
  AssemblyMaterialsTable,
  HideAssemblyMaterials,
  EditAssemblyMaterials,
  HistoryAssemblyMaterial,
} from "features/assemblyMaterials";
import {
  AddSupplier,
  SupplierTable,
  EditSupplier,
  DeleteSupplier,
} from "features/supplier";
import { materialType } from "types/MaterialTypes";
import "./assembly-materials.scss";
import { useGetSuppliersLabel } from "hooks/supplier";
import { useSearchParams } from "react-router-dom";
import { SVG_TYPE } from "enums";

const SubMenuItems = [
  {
    label: "Aktywne",
    value: "active",
  },
  {
    label: "Ukryte",
    value: "hidden",
  },
  {
    label: "Dostawcy",
    value: "supplier",
  },
];

const AssemblyMaterials = () => {
  const { data: suppliersLabel, refetch } = useGetSuppliersLabel();
  const [searchParams, setSearchParams] = useSearchParams();
  const linkPage = searchParams.get("page");
  const searchWord = searchParams.get("search");
  const tab = searchParams.get("tab");
  const activeSubMenu =
    SubMenuItems.find((item) => item.value === tab) || SubMenuItems[0];
  const [modalManage, setModalManage] = useState({
    type: "",
    material: {},
  });
  const [activeFloatingMenuItem, setActiveFloatingMenuItem] =
    useState(activeSubMenu);
  const [page, setPage] = useState(Number(linkPage) || 1);
  const [search, setSearch] = useState<undefined | string>(
    searchWord || undefined
  );

  useEffect(() => {
    if (
      activeFloatingMenuItem.value === "hidden" ||
      activeFloatingMenuItem.value === "active"
    ) {
      refetch();
    }
  }, [activeFloatingMenuItem]);

  const isHiddenMaterial = activeFloatingMenuItem.value === "hidden";
  const isSupplier = activeFloatingMenuItem.value === "supplier";
  const isAddAssemblyMaterialsOpenModal =
    modalManage.type === "addAssemblyMaterials";
  const isEditAssemblyMaterialsOpenModal =
    modalManage.type === "editAssemblyMaterials";
  const isHideAssemblyMaterialsOpenModal =
    modalManage.type === "hideAssemblyMaterial";
  const isHistoryAssemblyMaterialsOpenModal =
    modalManage.type === "historyAssemblyMaterial";
  const isAddSupplierOpenModal = modalManage.type === "addSuppllier";
  const isEditSupplierOpenModal = modalManage.type === "editSupplier";
  const isDeleteSupplierOpenModal = modalManage.type === "deleteSupplier";
  const setAddAssemblyMaterials = () =>
    setModalManage({
      type: "addAssemblyMaterials",
      material: modalManage.material,
    });
  const setEditAssemblyMaterial = (material: materialType) =>
    setModalManage({
      type: "editAssemblyMaterials",
      material,
    });
  const setHideAssemblyMaterial = (material: materialType) =>
    setModalManage({
      type: "hideAssemblyMaterial",
      material,
    });
  const setHistoryAssemblyMaterial = (material: materialType) =>
    setModalManage({
      type: "historyAssemblyMaterial",
      material,
    });
  const clearModalManage = () => {
    setModalManage({ type: "", material: "" });
  };

  const setAddSupplierMaterials = () =>
    setModalManage({
      type: "addSuppllier",
      material: modalManage.material,
    });

  const setEditSupplier = (supplier: any) =>
    setModalManage({
      type: "editSupplier",
      material: supplier,
    });

  const setDeleteSupplier = (supplier: any) => {
    setModalManage({
      type: "deleteSupplier",
      material: supplier,
    });
  };

  const handleChangeTab = (item: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("tab", item.value);
    setPage(1);
  };

  const handleSearchMaterial = (search: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (search) {
      newSearchParams.set("search", search);
      setSearch(search);
    } else {
      newSearchParams.delete("search");
      setSearch("");
    }
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <HistoryAssemblyMaterial
        isOpen={isHistoryAssemblyMaterialsOpenModal}
        material={modalManage.material}
        onClose={() => clearModalManage()}
      />
      <AddAssemblyMaterials
        isOpen={isAddAssemblyMaterialsOpenModal}
        onClose={() => clearModalManage()}
        suppliersLabel={suppliersLabel}
      />
      <EditAssemblyMaterials
        suppliersLabel={suppliersLabel}
        material={modalManage.material}
        isOpen={isEditAssemblyMaterialsOpenModal}
        onClose={() => clearModalManage()}
      />
      <HideAssemblyMaterials
        page={page}
        setPage={setPage}
        isHiddenMaterial={isHiddenMaterial}
        material={modalManage.material}
        isOpen={isHideAssemblyMaterialsOpenModal}
        onClose={() => clearModalManage()}
      />
      <AddSupplier
        isOpen={isAddSupplierOpenModal}
        onClose={() => clearModalManage()}
      />
      <EditSupplier
        data={modalManage.material}
        isOpen={isEditSupplierOpenModal}
        onClose={() => clearModalManage()}
      />
      <DeleteSupplier
        material={modalManage.material}
        page={page}
        setPage={setPage}
        data={modalManage.material}
        isOpen={isDeleteSupplierOpenModal}
        onClose={() => clearModalManage()}
      />
      <div className="dashboard-tab-name-with-button dashboard-tab-name-with-button--only-mobile">
        <div className="dashboard-tab-name">Materiały montażowe</div>
        {!isHiddenMaterial && (
          <Button
            className="btn-plus"
            label="+"
            onClick={() =>
              isSupplier ? setAddSupplierMaterials() : setAddAssemblyMaterials()
            }
          />
        )}
      </div>
      <div
        className={`floating-menu-with-button floating-menu-with-search ${
          isSupplier ? "assembly-material__menu-supplier" : ""
        }`}
      >
        <FloatingMenu
          items={SubMenuItems}
          activeFloatingMenuItem={activeFloatingMenuItem}
          setActiveFloatingMenuItem={setActiveFloatingMenuItem}
          onClick={(item) => handleChangeTab(item)}
        />
        {!isHiddenMaterial ? (
          <div className={`right ${isSupplier ? "right--hidden" : ""}`}>
            {!isSupplier && (
              <Formik
                initialValues={{ search: searchWord }}
                enableReinitialize
                onSubmit={async (values) => null}
              >
                <Field
                  label="Szukaj materiału"
                  name="search"
                  maxLength={20}
                  component={Input}
                  icon={SVG_TYPE.SEARCH}
                  onChange={(e: any) => handleSearchMaterial(e.target.value)}
                />
              </Formik>
            )}
            <Button
              size="medium"
              onClick={() =>
                isSupplier
                  ? setAddSupplierMaterials()
                  : setAddAssemblyMaterials()
              }
              label="Dodaj +"
            />
          </div>
        ) : (
          <>
            <div className="right">
              <Formik
                initialValues={{ search: searchWord }}
                enableReinitialize
                onSubmit={async (values) => null}
              >
                <Field
                  label="Szukaj materiału"
                  name="search"
                  component={Input}
                  maxLength={20}
                  icon={SVG_TYPE.SEARCH}
                  onChange={(e: any) => handleSearchMaterial(e.target.value)}
                />
              </Formik>
            </div>
          </>
        )}
      </div>
      {isSupplier ? (
        <SupplierTable
          setPage={setPage}
          page={page}
          floatingMenuItem={activeFloatingMenuItem}
          deleteSupplier={setDeleteSupplier}
          editSupplier={setEditSupplier}
        />
      ) : (
        <AssemblyMaterialsTable
          search={search}
          setPage={setPage}
          page={page}
          suppliersLabel={suppliersLabel}
          isHiddenMaterial={isHiddenMaterial}
          floatingMenuItem={activeFloatingMenuItem}
          hideAssemblyMaterial={setHideAssemblyMaterial}
          editAssemblyMaterial={setEditAssemblyMaterial}
          historyAssemblyMaterial={setHistoryAssemblyMaterial}
        />
      )}
    </>
  );
};

export default AssemblyMaterials;
