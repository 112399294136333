import { Page, PageContent, UserAccessPanel } from "components";
import "./activate-link-expired.scss";

const ActivateLinkExpired = () => {
  return (
    <Page>
      <PageContent>
        <UserAccessPanel heading="Ten link wygasł">
          <div className="uap__description password-link-expired__description">
            Skontaktuj się z administratorem w celu uzyskania nowego linku.
          </div>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default ActivateLinkExpired;
