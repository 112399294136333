import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "components";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";

const useSuspendUser = (
options?: UseMutationOptions<any, Error, { uuid: string }>
) => {
  return useMutation({
    ...options,
    mutationKey: ["suspendUser"],
    mutationFn: async ({ uuid }: { uuid: string }) => {
      return axiosInstance
        .patch(`/users/suspend/${uuid}`)
        .then((res) => res.data);
    },
    onMutate: ({ uuid }) => {
      Toast({
        message: "Przetwarzanie żądania...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: `suspend-${uuid}`,
      });
      if (options && options.onMutate) {
        return options.onMutate({ uuid });
      }
    },
    onSuccess: (data, { uuid }, context) => {
      const message = data?.user.suspended
        ? "Użytkownik został zablokowany"
        : "Użytkownik został odblokowany";
      Toast({
        message,
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: `suspend-${uuid}`,
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, { uuid }, context);
      }
    },
    onError: (error, { uuid }, context) => {
      Toast({
        message: "Błąd podczas próby zmiany statusu użytkownika",
        type: TOAST_TYPES_ENUM.ERROR,
        id: `suspend-${uuid}`,
      });
      if (options && options.onError) {
        options.onError(error, { uuid }, context);
      }
    },
  });
};


export default useSuspendUser;