import { useState, useEffect } from "react";
import { PortalWithState } from "react-portal";
import "./adding-measurement.scss";
import {
  CloseMeasurement,
  AddingMeasurementFirstStep,
  WindowsInRowForms,
  WindowsReplacementForms,
  InteriorDoorForm,
  FloorForm,
  ExternalDoorInRowForm,
  GarageDoorForm,
} from "features/addingMeasurement";
import { useGetMeaasurementInfo } from "hooks/measurements";
import classNames from "classnames";
import { MEASUREMENTS_TYPE, SVG_TYPE } from "enums";
import { SVG } from "components";
import { useSearchParams } from "react-router-dom";

const AddingMeasurement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const measurementPage = searchParams.get("measurementPage");
  const measurementUuid = searchParams.get("measurementUuid");
  const [formType, setFormType] = useState("");
  const [step, setStep] = useState(
    measurementPage ? parseInt(measurementPage, 10) : 1
  );
  const isFirstStep = step === 1 || !measurementUuid;
  const { data: measurementInfo, refetch } =
    useGetMeaasurementInfo(measurementUuid);

  const amFormClass = classNames("am-form", {
    "am-form__first-step": isFirstStep,
    "am-form__another-step": !isFirstStep,
  });

  useEffect(() => {
    if (measurementInfo) {
      setFormType(measurementInfo.typeOfMeasurement);
    }
  }, [measurementInfo]);

  const handlePrevStep = () => {
    const prevStep = step - 1;
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("measurementPage", prevStep.toString());
    setSearchParams(newSearchParams, { replace: true });
    setStep(prevStep);
    refetch();
  };

  const handleShowForm = (type: string) => {
    switch (type) {
      case MEASUREMENTS_TYPE.WINDOWS_IN_ROW:
        return (
          <WindowsInRowForms
            measurementInfo={measurementInfo}
            step={step}
            setStep={setStep}
            prevStep={handlePrevStep}
          />
        );
      case MEASUREMENTS_TYPE.WINDOWS_REPLACEMENT:
        return (
          <WindowsReplacementForms
            measurementInfo={measurementInfo}
            step={step}
            setStep={setStep}
            prevStep={handlePrevStep}
          />
        );
      case MEASUREMENTS_TYPE.INTERIOR_DOORS:
        return (
          <InteriorDoorForm
            measurementInfo={measurementInfo}
            step={step}
            setStep={setStep}
            prevStep={handlePrevStep}
          />
        );
      case MEASUREMENTS_TYPE.FLOORS:
        return (
          <FloorForm
            measurementInfo={measurementInfo}
            step={step}
            setStep={setStep}
            prevStep={handlePrevStep}
          />
        );
      case MEASUREMENTS_TYPE.EXTERNAL_DOORS_IN_ROW:
        return (
          <ExternalDoorInRowForm
            measurementInfo={measurementInfo}
            step={step}
            setStep={setStep}
            prevStep={handlePrevStep}
          />
        );
      case MEASUREMENTS_TYPE.GARAGE_DOOR:
        return (
          <GarageDoorForm
            measurementInfo={measurementInfo}
            step={step}
            setStep={setStep}
            prevStep={handlePrevStep}
          />
        );
    }
  };

  return (
    <>
      <PortalWithState
        closeOnEsc={false}
        closeOnOutsideClick={false}
        defaultOpen={true}
      >
        {({ portal }) => (
          <>
            {portal(
              <div className="full-page am">
                <div className="am__content">
                  <CloseMeasurement />
                  <div className={amFormClass}>
                    <div className="am-steps">
                      {isFirstStep ? (
                        `Krok ${step}`
                      ) : (
                        <>
                          <div
                            className="am-steps__go-back"
                            onClick={() => handlePrevStep()}
                          >
                            <SVG type={SVG_TYPE.GO_BACK} />
                          </div>
                          Krok {step}/{measurementInfo?.pageCount}
                        </>
                      )}
                    </div>
                    {isFirstStep ? (
                      <AddingMeasurementFirstStep
                        setFormType={setFormType}
                        selectedOptions={measurementInfo?.typeOfMeasurement}
                        setStep={setStep}
                      />
                    ) : (
                      handleShowForm(formType)
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </PortalWithState>
    </>
  );
};

export default AddingMeasurement;
