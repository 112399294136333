import { Input, Select, Textarea } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
} from "features/addingMeasurement";

interface SwingGateFormType {
  touched: any;
  errors: any;
  validateField: any;
}

const SwingGateForm = ({
  touched,
  errors,
  validateField,
}: SwingGateFormType) => {
  return (
    <>
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips"
        touched={touched}
        name="roomName"
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="holeSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="holeSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          label="Sposób otwierania"
          name="open"
          type="number"
          options={[
            {
              value: "left",
              label: "Lewe skrzydło czynne",
            },
            {
              value: "right",
              label: "Prawe skrzydło czynne",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator single-section-element--strips"
      >
        <Field
          label="Podział bramy"
          name="open"
          type="number"
          options={[
            {
              value: "symetric",
              label: "Symetryczny",
            },
            {
              value: "nosymetric",
              label: "Niesymetryczny",
            },
          ]}
          component={Select}
          white
        />
        <Field
          errors={errors}
          maxLength={30}
          label="Skrzydło czynne"
          name={`color`}
          number
          component={Input}
        />
        <Field
          errors={errors}
          maxLength={30}
          label="Skrzydło bierne"
          number
          name={`color`}
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label={`Kolor`}
          name={`color`}
          optional
          component={Input}
        />
      </SingleSectionElement>
      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        name="comment"
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default SwingGateForm;
