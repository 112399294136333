import { USER_ROLE } from "enums";

export const SubMenuItems = (userRole: string | undefined) => {
  const isAdmin = userRole === USER_ROLE.admin;
  const isFitter = userRole === USER_ROLE.fitter;

  if (isAdmin) {
    return [
      {
        label: "Pomiary",
        value: "measurementsToBeReleased",
      },
      {
        label: "Montaże",
        value: "fixedMeasurements",
      },
      {
        label: "Lista czynności",
        value: "activityList",
      },
      {
        label: "Checklisty dla monterów",
        value: "checklistInstallation",
      },
    ];
  } else if (isFitter) {
    return [
      {
        label: "Moje montaże",
        value: "myinstallations",
      },
      {
        label: "Pozostałe montaże",
        value: "otherinstallation",
      },
    ];
  } else {
    return [
      {
        label: "Pomiar do ustalenia",
        value: "measurementsToBeReleased",
      },
      {
        label: "Ustalone pomiary",
        value: "fixedMeasurements",
      },
      {
        label: "Montaż do ustalenia",
        value: "installationToBeReleased",
      },
      {
        label: "Ustalone montaże",
        value: "fixedInstallation",
      },
    ];
  }
};
