import { ContextMenu, SVG } from "components";
import { SVG_TYPE } from "enums";

const items = (editChecklist: (checklist: any) => void) => {
  return (checklist: any) => {
    const actions = [
      {
        name: "Edytuj",
        action: () => editChecklist(checklist),
      },
    ];
    return actions;
  };
};

export const desktopColumns = (editChecklist: (checklist: any) => void) => [
  {
    dataIndex: "label",
    key: "label",
  },
  {
    dataIndex: "checkListExist",
    key: "checkListExist",
    width: 210,
    render: (item: any) => {
      const emptyList = !item;
      return (
        <>
          {emptyList && (
            <div className="checklist__empty-list">Brak punktów na liście</div>
          )}
        </>
      );
    },
  },
  {
    width: 40,
    render: (item: any) => (
      <ContextMenu data={item} items={items(editChecklist)(item)}>
        <SVG type={SVG_TYPE.ELLIPSIS} />
      </ContextMenu>
    ),
  },
];

export const mobileColumns = (editChecklist: (checklist: any) => void) => [
  {
    width: 2000,
    className: "checklist-table__label",
    render: (item: any) => {
      const emptyList = !item.checkListExist;
      return (
        <div className="checklist-table__mobile-row">
          {emptyList && <div className="checklist__empty-list" />}
          <div>{item.label}</div>
        </div>
      );
    },
  },
];
