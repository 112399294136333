import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { EmailType } from "types/AuthTypes";
import { Toast } from "components";
import { TOAST_TYPES_ENUM, ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";

const passwordRecovery = async (credentials: EmailType): Promise<any> => {
  const { data } = await axiosConfig.post(`/auth/recover`, credentials);
  return data;
};

export const usePasswordRecovery = (options?: UseMutationOptions<any, Error, EmailType>) => {
  const navigate = useNavigate();
  return useMutation<any, Error, EmailType, unknown>({
    mutationKey: ["reset-password"],
    mutationFn: passwordRecovery,
    onMutate: async (variables) => {
      Toast({
        message: "Przetwarzanie żądania resetu hasła...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'recovery-password',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Wysłaliśmy mail z resetem hasła, sprawdź pocztę",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'recovery-password',
      });
      const isRestartLink = window.location.href.includes(ROUTE_ENUM.SEND_LINK_RESTART_PASSWORD);
      if(!isRestartLink) {
        navigate(ROUTE_ENUM.SEND_LINK_RESTART_PASSWORD);
      }
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd podczas próby wysłania resetu hasła",
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'recovery-password',
      });
      options?.onError?.(error, variables, context);
    },
    ...options,
  });
};
