import { Button, Modal } from "components";
import { useSuspendUser } from "hooks/users";
// import { UserType } from "types/UsersTypes";

interface BlockUserType {
  user: any;
  isOpen: boolean;
  onClose: () => void;
}

const BlockUser = ({ isOpen, onClose, user }: BlockUserType) => {
  const { mutate: suspendUser } = useSuspendUser();
  const isUserBlocked = !user?.suspended;

  const handleSuspendUser = async (uuid: string) => {
    await suspendUser({ uuid });
    onClose();
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">
            {isUserBlocked
              ? "Blokowanie użytkownika"
              : "Odblokowanie użytkownika"}
          </div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz{" "}
            {isUserBlocked ? "zablokować" : "odblokować"} użytkownika
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button
              onClick={() => handleSuspendUser(user.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default BlockUser;
