import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface CreateActivityData {
  isMeasurement: boolean;
  isInstallation: boolean;
  text: string;
  numberOfActivities: 'one' | 'many';
}

const useCreateActivity = (
  options?: UseMutationOptions<any, Error, CreateActivityData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['createActivity'],
    mutationFn: async (data: CreateActivityData) => {
      return axiosInstance.post(`/activity`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Dodawanie czynności...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'create-activity',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Czynność została pomyślnie dodana.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'create-activity',
      });
      queryClient.invalidateQueries({ queryKey: ['userActivities'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas dodawania czynności.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'create-activity',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useCreateActivity;
