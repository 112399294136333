import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "components";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";

const useDeleteUser = (
  options?: UseMutationOptions<any, Error, { uuid: string }>
) => {
  return useMutation({
    ...options,
    mutationKey: ["deleteUser"],
    mutationFn: async ({ uuid }: { uuid: string }) => {
      return axiosInstance
        .delete(`/users/${uuid}`)
        .then((res) => res.data);
    },
    onMutate: ({ uuid }) => {
      Toast({
        message: "Przetwarzanie żądania usunięcia...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: `delete-${uuid}`,
      });
      if (options && options.onMutate) {
        return options.onMutate({ uuid });
      }
    },
    onSuccess: (data, { uuid }, context) => {
      const message = data?.status === 'success'
        ? "Twoje zmiany zostały pomyślnie zapisane"
        : "Nie udało się zapisać zmian. Spróbuj ponownie.";
      Toast({
        message,
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: `delete-${uuid}`,
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, { uuid }, context);
      }
    },
    onError: (error, { uuid }, context) => {
      Toast({
        message: "Błąd podczas próby usunięcia użytkownika",
        type: TOAST_TYPES_ENUM.ERROR,
        id: `delete-${uuid}`,
      });
      if (options && options.onError) {
        options.onError(error, { uuid }, context);
      }
    },
  });
};

export default useDeleteUser;
