import { useSearchParams } from "react-router-dom";
import {
  ExternalDoorInRowInstallationInformation,
  ExternalDoorInRowMeasurementValues,
  ExternalDoorInRowGeneralInformation,
} from "./components";

interface ExternalDoorInRowFormType {
  setStep: (page: number) => void;
  step: number;
  measurementInfo: any;
  prevStep: () => void;
}

const ExternalDoorInRowForm = ({
  step,
  setStep,
  measurementInfo,
  prevStep,
}: ExternalDoorInRowFormType) => {
  const [searchParams] = useSearchParams();
  const measurementUuid = searchParams.get("measurementUuid");
  const handleShowForm = (step: number, measurementUuid: string | null) => {
    switch (step) {
      case 2:
        return (
          <ExternalDoorInRowGeneralInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <ExternalDoorInRowInstallationInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <ExternalDoorInRowMeasurementValues
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      default:
        return <p>default</p>;
    }
  };

  return handleShowForm(step, measurementUuid);
};

export default ExternalDoorInRowForm;
