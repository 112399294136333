import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type EmployeeType = {
  label: string; 
  value: string; 
};

type EmployeesResponseType = EmployeeType[];

export default function useGetFitterAndMeasurer(onlyInstallation?: boolean) {
  return useQuery({
    queryKey: ["fitterAndMeasurer", { onlyInstallation }],
    queryFn: async () => {
      const params = onlyInstallation ? { onlyInstallation } : {};
      const response = await axiosInstance.get(`/users/employees`, { params });
      return response.data;
    },
    select: (data: { userLabel: EmployeesResponseType }) => data.userLabel,
    retry: false,
  });
}
