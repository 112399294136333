import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type InteriorDoorsInstallationInfoData = {
  measurementUuid: string;
  plannedAssemblyTime: string;
  numberOfAssemblyTeams: string;
  comments: string;
};

const usePutInteriorDoorsInstallationInfo = (
  options?: UseMutationOptions<any, Error, InteriorDoorsInstallationInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editInteriorDoorsInstallationInfo'],
    mutationFn: async (data) => {
      return axiosInstance.put(`/measurement/interior-doors/installation-info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych montażowych drzwi wewnętrznych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-interior-doors-installation-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane montażowe drzwi wewnętrznych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-interior-doors-installation-info',
      });
      queryClient.invalidateQueries({ queryKey: ['interiorDoorsInstallationInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych montażowych drzwi wewnętrznych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-interior-doors-installation-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutInteriorDoorsInstallationInfo;
