enum ROUTE_ENUM {
  HOME = '/',
  LOGIN_SMS_CODE = '/login-sms-code',
  LOGIN = '/login',
  SET_ADMIN_PASSWORD = '/set-password',
  FORGET_PASSWORD = '/forget-password',
  RESET_PASSWORD = '/reset/password',
  SEND_LINK_RESTART_PASSWORD = '/send-link-restart-password',
  PASSWORD_LINK_EXPIRED = '/password-link-expired',
  ACTIVATE_LINK_EXPIRED = '/activate-link-expired',
  PASSWORD_HAS_BEEN_CHANGED = '/password-has-been-changed',
  PASSWORD_HAS_BEEN_CREATED = '/password-has-been-created',
  CHECK_TOKEN_ACTIVATE = '/activate',
  
  // DASHBOARD
  DASHBOARD = '/dashboard',
  DASHBOARD_STATISTICS = '/dashboard/statistics',
  USERS = '/dashboard/users',
  EMPLOYEE_RATES = '/dashboard/employee-rates',
  ASSEMBLY_MATERIAL = '/dashboard/assembly-materials',
  ORDERS_LIST = '/dashboard/orders-list',
  CALENDAR = '/dashboard/calendar',
  SCHEDULE_MANAGER_CALENDAR = '/dashboard/calendar-schedule-manager',
  ARRANGING_MEASUREMENT = '/dashboard/arranging-measurement',
  ACTIVE_ORDER = '/dashboard/active-order',
  ADDING_MEASUREMENT = '/dashboard/adding-measurement',
}

export default ROUTE_ENUM;