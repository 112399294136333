import { ReactNode } from "react";
import classNames from "classnames";
import "./page-content.scss";

interface PageContentProps {
  children: ReactNode;
  className?: string;
}

const PageContent = ({ children, className }: PageContentProps) => {
  const classes = classNames("page-content", className);

  return <div className={classes}>{children}</div>;
};

export default PageContent;
