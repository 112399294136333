import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { InstallationType } from "types/MeasurementTypes";


export default function useGetInstallationTypes(types: any) {
  return useQuery({
    queryKey: ["installationTypes", types],
    queryFn: async () => {
      return axiosInstance
        .get(`/types-of-installation?types=${types}`)
        .then((res) => res.data);
    },
    select: (data: {typeOfInstallationLabel: InstallationType[]}) => data.typeOfInstallationLabel,
    retry: false,
    enabled: !!types,
  });
}