/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import "./mounting-accessories.scss";
import { useGetMountingAccessories } from "hooks/measurements";
import { MountingAccessoriesType } from "types/MeasurementTypes";
import { CheckboxPart } from "components";

interface MountingAccessoriesTypes {
  field: any;
  errors?: any;
  form: any;
  touched?: any;
  isWindowReplacement: boolean;
  type?: string;
}

const MountingAccessories = ({
  errors,
  form,
  field,
  type,
}: MountingAccessoriesTypes) => {
  const { data: mountingAccessories } = useGetMountingAccessories(type);
  const [typeOfMountingAccessoriesUuids, setMountingAccessoriesUuids] =
    useState<any>(form.values[field.name] || []);

  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray = typeOfMountingAccessoriesUuids.find(
      (item: any) => item === uuid
    );
    if (!isUuidInArray) {
      const values = [...typeOfMountingAccessoriesUuids, uuid];
      setMountingAccessoriesUuids(values);
      form.setFieldValue(field.name, values);
    } else {
      const values = typeOfMountingAccessoriesUuids.filter(
        (item: any) => item !== uuid
      );
      setMountingAccessoriesUuids(values);
      form.setFieldValue(field.name, values);
    }
  };

  useEffect(() => {
    setMountingAccessoriesUuids(form.values.installationElementsUuids);
  }, [form.values.installationElementsUuids]);

  return (
    <div className="mounting-accessories">
      <div className="am-section-heading">
        Dodatkowe elementy potrzebne do montażu
      </div>
      <div className="am-section-checkbox-list">
        {mountingAccessories &&
          mountingAccessories.length > 0 &&
          mountingAccessories.map((item: MountingAccessoriesType) => {
            return (
              <CheckboxPart
                checked={typeOfMountingAccessoriesUuids.find(
                  (uuid: string) => uuid === item.value
                )}
                key={item.value}
                label={item.label}
                uuid={item.value}
                onChange={(uuid) => handleClickCheckbox(uuid)}
              />
            );
          })}
      </div>
      <div className="am-separator" />
    </div>
  );
};

export default MountingAccessories;
