import { useState } from "react";
import {
  TypeOfInstalation,
  SpoutLevel,
  RoomList,
  KitchenWindowConnectedCountertop,
  BlindsAndRollerBlinds,
} from "features/addingMeasurement";
import { VALIDATION_MESSAGES } from "enums";
import { Button } from "components";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { usePutGenerallInfoMeasurement } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";
import { ScrollToError } from "utils";
import ExtraWork from "../ExtraWork";

interface WindowsInRowFormGeneralInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const WindowsInRowFormGeneralInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: WindowsInRowFormGeneralInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const initialMeasurementClientInfo = measurementInfo?.measurementClientInfo;

  const typeOfInstallationComments =
    initialMeasurementClientInfo?.typeOfInstallationUuids?.find(
      (item: any) => item.comment
    )?.comment;

  const typeOfBlindInstallationComments =
    initialMeasurementClientInfo?.typeOfBlindInstallation?.find(
      (item: any) => item.comment
    )?.comment;

  const initialValues = {
    typeOfBlindInstallationComments: typeOfBlindInstallationComments || "",
    measurementUuid: measurementUuid,
    typeOfInstallationUuids:
      initialMeasurementClientInfo?.typeOfInstallationUuids || [],
    typeOfInstallationComments: typeOfInstallationComments || "",
    typeOfBlindInstallationUuids:
      initialMeasurementClientInfo?.typeOfBlindInstallationUuids || [],
    spoutLevel: initialMeasurementClientInfo?.spoutLevel || "",
    typeOfStars: initialMeasurementClientInfo?.typeOfStars || "",
    starsMeasurement: initialMeasurementClientInfo?.starsMeasurement || "",
    roomListUuids: initialMeasurementClientInfo?.roomListUuids || [],
    kitchenWindowConnectedToTheCountertop:
      initialMeasurementClientInfo?.kitchenWindowConnectedToTheCountertop ||
      false,
    kitchenWindowConnectedToTheCountertopMeasurement:
      initialMeasurementClientInfo?.kitchenWindowConnectedToTheCountertopMeasurement ||
      "",
    doesTheClientPlanBlindsShutters:
      initialMeasurementClientInfo?.doesTheClientPlanBlindsShutters || false,
    preparationOfOpeningsForTripleLayerInstallation:
      initialMeasurementClientInfo?.preparationOfOpeningsForTripleLayerInstallation ||
      "nobody",
    changeOfOpeningDimensions:
      initialMeasurementClientInfo?.changeOfOpeningDimensions || "nobody",
    constructionSiteCleanupInvestor:
      initialMeasurementClientInfo?.constructionSiteCleanupInvestor || false,
  };

  const { mutate: editGeneralInfo } = usePutGenerallInfoMeasurement({
    onSuccess: () => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementPage", "3");
      setSearchParams(newSearchParams, { replace: true });
      setStep(step + 1);
    },
    onError: () => {
      console.error("Błąd podczas aktualizacji danych klienta.");
    },
  });

  const roomSchema = Yup.object().shape({
    uuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    measurement: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const validationSchema = Yup.object().shape({
    kitchenWindowConnectedToTheCountertop: Yup.boolean()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "kitchenWindow-connected-test",
        VALIDATION_MESSAGES.REQUIRED,
        function (value) {
          const measurement =
            this.parent.kitchenWindowConnectedToTheCountertopMeasurement;
          if (value === true && !measurement) {
            return false;
          }
          return true;
        }
      ),
    kitchenWindowConnectedToTheCountertopMeasurement: Yup.string(),
    roomListUuids: Yup.array()
      .of(roomSchema)
      .min(1, "")
      .required(VALIDATION_MESSAGES.REQUIRED),
    spoutLevel: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test({
        name: "spoutLevel-conditions",
        exclusive: true,
        message: VALIDATION_MESSAGES.REQUIRED,
        test: function (value) {
          if (value === "measuredFromStairLevel") {
            return !!this.parent.typeOfStars && !!this.parent.starsMeasurement;
          }
          return true;
        },
      }),
    typeOfInstallationUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value))
        )
      )
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "has-at-least-one-loose-string",
        VALIDATION_MESSAGES.REQUIRED,
        (array) => array.some((item) => typeof item === "string")
      ),
    doesTheClientPlanBlindsShutters: Yup.boolean().required(
      VALIDATION_MESSAGES.REQUIRED
    ),

    typeOfBlindInstallationUuids: Yup.array()
      .of(
        Yup.mixed().test(
          "is-string-or-object-blinds",
          VALIDATION_MESSAGES.REQUIRED,
          (value) =>
            typeof value === "string" ||
            (typeof value === "object" &&
              value !== null &&
              !Array.isArray(value))
        )
      )
      .test(
        "conditional-required",
        VALIDATION_MESSAGES.REQUIRED,
        function (value) {
          const { doesTheClientPlanBlindsShutters } = this.parent;
          if (doesTheClientPlanBlindsShutters) {
            return (
              Array.isArray(value) &&
              value.some((item) => typeof item === "string")
            );
          }
          return true;
        }
      ),
  });

  const handleOnSubmit = (values: any) => {
    delete values.typeOfInstallationComments;
    if (values.spoutLevel !== "measuredFromStairLevel") {
      delete values.typeOfStars;
      delete values.starsMeasurement;
    }
    Object.keys(values).forEach((key) => {
      if (key.startsWith("drop")) {
        delete values[key];
      }
    });
    if (values.kitchenWindowConnectedToTheCountertop === false) {
      delete values.kitchenWindowConnectedToTheCountertopMeasurement;
    }
    if (
      values.doesTheClientPlanBlindsShutters === false &&
      values.typeOfBlindInstallationUuids
    ) {
      delete values.typeOfBlindInstallationUuids;
    }

    editGeneralInfo({
      ...values,
    });
  };

  return (
    <div>
      <div className="am-heading">Informacje ogólne</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                values={values}
                errors={errors}
                label="Nazwa materiału"
                name="typeOfInstallationUuids"
                component={TypeOfInstalation}
                type={measurementInfo?.typeOfMeasurement}
              />
              <Field errors={errors} name="spoutLevel" component={SpoutLevel} />
              <Field
                errors={errors}
                values={values}
                label="Nazwa materiału"
                name="roomListUuids"
                component={RoomList}
              />
              <Field
                isChecked={values.kitchenWindowConnectedToTheCountertop}
                errors={errors}
                name="kitchenWindowConnectedToTheCountertop"
                component={KitchenWindowConnectedCountertop}
              />
              <Field
                values={values}
                isChecked={values.doesTheClientPlanBlindsShutters}
                errors={errors}
                name="doesTheClientPlanBlindsShutters"
                component={BlindsAndRollerBlinds}
              />
              <Field
                isChecked={values.doesTheClientPlanBlindsShutters}
                values={values}
                component={ExtraWork}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button type="submit" label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default WindowsInRowFormGeneralInformation;
