import { Button, Modal } from "components";
import { useDeleteMeasurement } from "hooks/measurements";
import { useSearchParams } from "react-router-dom";

interface DeleteMeasurementType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteMeasurement = ({
  isOpen,
  onClose,
  data,
}: DeleteMeasurementType) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const { mutate } = useDeleteMeasurement(orderUuid, {
    onSuccess: () => {
      onClose();
    },
  });
  const handleDelete = (uuid: string) => {
    mutate({ measurementUuid: uuid });
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">Usuwanie pomiaru</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć pomiar
            <span>{data.label}</span>?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={() => handleDelete(data.uuid)} label="Potwierdź" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteMeasurement;
