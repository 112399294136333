import { queryClient } from "App";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { LoginTypes } from 'types/AuthTypes';
import { Toast } from "components";
import { TOAST_TYPES_ENUM } from 'enums'


export const useLogin = (
  options?: UseMutationOptions<any, Error, LoginTypes>
) => {
  return useMutation({
    ...options,
    mutationKey: ["login"],
    mutationFn: async (credentials: LoginTypes) => {
      return axiosInstance
        .post(`/auth/login`, credentials)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: "Logowanie...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "login",
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Zalogowano pomyślnie",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "login",
      });
      queryClient.prefetchQuery({ queryKey: ["me"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Błąd logowania",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "login",
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
