/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useGetAssemblyMaterials } from "hooks/assembly-materials";
import "./assembly-materials-table.scss";
import Table from "rc-table";
import { isMobile } from "utils";
import { Pagination, SkeletonRow } from "components";
import { mobileColumns, desktopColumns } from "./utils";
import { materialType } from "types/MaterialTypes";
import { useSearchParams } from "react-router-dom";

interface AssemblyMaterialsTableType {
  floatingMenuItem: { value: string; label: string };
  hideAssemblyMaterial: (material: materialType) => void;
  editAssemblyMaterial: (material: materialType) => void;
  historyAssemblyMaterial: (material: materialType) => void;
  isHiddenMaterial: boolean;
  page: number;
  setPage: (page: number) => void;
  suppliersLabel: { label: string; value: string }[] | undefined;
  search: string | undefined;
}

const AssemblyMaterialsTable = ({
  floatingMenuItem,
  hideAssemblyMaterial,
  editAssemblyMaterial,
  historyAssemblyMaterial,
  isHiddenMaterial,
  suppliersLabel,
  page,
  setPage,
  search,
}: AssemblyMaterialsTableType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const supplierInLink = searchParams.get("supplier");
  const pageInLink = searchParams.get("page");
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const [activeSupplier, setActiveSupplier] = useState({
    label: "",
    value: "",
  });

  const { data, refetch, isLoading } = useGetAssemblyMaterials(
    floatingMenuItem.value,
    page,
    activeSupplier.value || supplierInLink || "",
    search
  );

  useEffect(() => {
    if (activeSupplier.value || search !== undefined) {
      refetch();
    }
  }, [activeSupplier.value, search]);

  const handleExpand = (expanded: any, record: any) => {
    if (isMobile()) {
      const keys = expanded ? [record.id] : [];
      setExpandedRowKeys(keys);
    }
  };

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (activeSupplier.value) {
      newSearchParams.set("supplier", activeSupplier.value);
    }
    newSearchParams.set("tab", floatingMenuItem.value);
    newSearchParams.set("page", pageInLink || "1");
    setSearchParams(newSearchParams, { replace: true });
  }, [floatingMenuItem.value, activeSupplier]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("tab", floatingMenuItem.value);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams, { replace: true });
  }, [page]);

  const isLastElement = data?.data.length === 1;

  return (
    <div className="users-table">
      <Table
        rowKey="id"
        className="user-table"
        data={data?.data}
        columns={
          isMobile()
            ? mobileColumns()
            : desktopColumns(
                data?.meta?.currentPage || 0,
                data?.meta?.perPage || 0,
                editAssemblyMaterial,
                hideAssemblyMaterial,
                historyAssemblyMaterial,
                isHiddenMaterial,
                suppliersLabel,
                setActiveSupplier,
                refetch,
                isLastElement
              )
        }
        expandable={{
          showExpandColumn: false,
          expandRowByClick: true,
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender: (record) => (
            <div className="user-table-row__expand">
              <div className="user-table-row__expand-top"></div>
              <div className="user-table-row__expand-bottom">
                <div
                  className="user-table-row__expand-item"
                  onClick={() => editAssemblyMaterial(record)}
                >
                  Edytuj
                </div>
                <div
                  className="user-table-row__expand-item"
                  onClick={() => historyAssemblyMaterial(record)}
                >
                  Historia cen
                </div>
                <div
                  className="user-table-row__expand-item"
                  onClick={() => hideAssemblyMaterial(record)}
                >
                  {isHiddenMaterial ? "Przywróć" : "Ukryj"}
                </div>
              </div>
            </div>
          ),
        }}
      />

      {data && data.data.length === 0 && (
        <div className="users-table__no-data">
          <div className="users-table__no-data--paragraph">
            {isHiddenMaterial
              ? "Nie ma jeszcze ukrytych materiałów."
              : "Nie dodano jeszcze żadnych materiałów."}
          </div>
          <div className="users-table__no-data--paragraph">
            {isHiddenMaterial
              ? "Przejdź do zakładki aktywne i ukryj materiał."
              : "Kliknij w przycisk “Dodaj” i wprowadź pierwszy materiał montażowy."}
          </div>
        </div>
      )}
      {isLoading && <SkeletonRow count={10} height={58} />}
      {data?.meta && data?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={data?.meta?.lastPage}
          currentPage={data?.meta?.currentPage || page}
          onPageChange={(e) => setPage(e)}
        />
      )}
    </div>
  );
};

export default AssemblyMaterialsTable;
