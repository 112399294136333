import { StaticAlert } from "components";
import "./single-section-element.scss";

interface SingleSectionElementType {
  errors: any;
  children: any;
  touched: any;
  name: string;
  className?: string;
}

const SingleSectionElement = ({
  errors,
  children,
  touched,
  name,
  className = "",
}: SingleSectionElementType) => {
  return (
    <div className={`single-section-element ${className}`}>
      {children}
      {errors && touched && (
        <>
          <StaticAlert
            size="small"
            show={errors[name] && errors[name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}

      <div
        className={`am-separator ${
          errors[name] ? "" : "am-separator--no-margin-top "
        }`}
      />
    </div>
  );
};

export default SingleSectionElement;
