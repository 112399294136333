import { UserStatus, UserBadge, ContextMenu, SVG } from "components";
import { SVG_TYPE } from "enums";
import { UserType } from "types/UsersTypes";

const items = (
  isAdminTab: boolean,
  blockUser: (user: UserType) => void,
  deleteUser: (user: UserType) => void,
  editUser: (user: UserType) => void,
  resendActivateMail: (email: string) => void
) => {
  return (user: UserType) => {
    const actions = [
      {
        name: "Edytuj",
        action: () => editUser(user),
      },
    ];
    if (isAdminTab) {
      if (user.status === "inactive") {
        actions.push({
          name: "Wyślij link aktywacyjny",
          action: () => resendActivateMail(user.email),
        });
      }
    }
    if (user.status !== "inactive") {
      actions.push({
        name: user.status === "suspended" ? "Odblokuj" : "Zablokuj",
        action: () => blockUser(user),
      });
    }
    actions.push({
      name: "Usuń",
      action: () => deleteUser(user),
    });
    return actions;
  };
};

export const mobileColumns = (isAdminTab: boolean) =>
  isAdminTab
    ? [
        {
          dataIndex: "status",
          key: "status",
          width: 10,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          width: "calc(50% - 10px)",
          render: (values: any) => (
            <div className="users-table-mobile__name">
              {`${values.firstName} ${values.lastName}`}
            </div>
          ),
        },
        {
          dataIndex: "email",
          key: "email",
          width: "calc(50% - 10px)",
          render: (email: string) => (
            <div className="users-table-mobile__email">{email}</div>
          ),
        },
      ]
    : [
        {
          width: 10,
          render: (value: any) => (
            <div
              className="users-table__color"
              style={{ backgroundColor: value.userColor }}
            />
          ),
        },
        {
          width: "calc(50% - 50px)",
          render: (values: any) => `${values.firstName} ${values.lastName}`,
        },
        {
          width: "calc(50% - 50px)",
          render: (value: UserType) =>
            `${value?.phonePrefix ? value?.phonePrefix : ""} ${
              value?.phoneNumber ? value?.phoneNumber : ""
            }`,
        },
        {
          dataIndex: "role",
          key: "role",
          width: 40,
          render: (value: any) => <UserBadge role={value} />,
        },
      ];

export const desktopColumns = (
  isAdminTab: boolean,
  currentPage: number,
  perPage: number,
  blockUser: (user: UserType) => void,
  deleteUser: (user: UserType) => void,
  editUser: (user: UserType) => void,
  resendActivateMail: (email: string) => void
) =>
  isAdminTab
    ? [
        {
          title: "Lp.",
          key: "index",
          width: 68,
          render: (value: any, record: UserType, index: number) =>
            (currentPage - 1) * perPage + index + 1,
        },
        {
          title: "Imię",
          dataIndex: "firstName",
          key: "firstName",
          width: 300,
        },
        {
          title: "Nazwisko",
          dataIndex: "lastName",
          key: "lastName",
          width: 300,
        },
        {
          title: "Adres email",
          dataIndex: "email",
          key: "email",
          width: 350,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 201,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          title: "Akcje",
          width: 88,
          render: (item: UserType) => (
            <ContextMenu
              data={item}
              items={items(
                isAdminTab,
                blockUser,
                deleteUser,
                editUser,
                resendActivateMail
              )(item)}
            >
              <SVG type={SVG_TYPE.ELLIPSIS} />
            </ContextMenu>
          ),
        },
      ]
    : [
        {
          title: "Lp.",
          key: "index",
          width: 68,
          render: (value: any, record: UserType, index: number) =>
            (currentPage - 1) * perPage + index + 1,
        },
        {
          title: "Imię",
          // dataIndex: "firstName",
          // key: "firstName",
          width: 220,
          render: (values: any) => (
            <div>
              <div
                className="users-table__color"
                style={{ backgroundColor: values.userColor }}
              />
              {`${values.firstName}`}
            </div>
          ),
        },
        {
          title: "Nazwisko",
          dataIndex: "lastName",
          key: "lastName",
          width: 220,
        },
        {
          title: "Telefon",
          width: 196,
          render: (value: UserType) =>
            `${value?.phonePrefix ? value?.phonePrefix : ""} ${
              value?.phoneNumber ? value?.phoneNumber : ""
            }`,
        },
        {
          title: "Stanowisko",
          dataIndex: "role",
          key: "role",
          width: 228,
          render: (value: any) => <UserBadge role={value} />,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 201,
          render: (value: string) => <UserStatus status={value} />,
        },
        {
          title: "Akcje",
          width: 88,
          render: (item: UserType) => (
            <ContextMenu
              data={item}
              items={items(
                isAdminTab,
                blockUser,
                deleteUser,
                editUser,
                resendActivateMail
              )(item)}
            >
              <SVG type={SVG_TYPE.ELLIPSIS} />
            </ContextMenu>
          ),
        },
      ];
