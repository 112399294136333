/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Switch, Textarea } from "components";
import { SingleSectionElement, Drawing } from "features/addingMeasurement";
import { ScrollToError } from "utils";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import { DoorSills, FloorArea } from "features/addingMeasurement/Form";
import { usePutFloorInfo } from "hooks/measurements";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

interface FloorMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const FloorMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: FloorMeasurementValuesType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");

  const initialValues = {
    measurementUuid: measurementUuid,
    directionOfLayingThePanels: "",
    conditionOfTheSpout: "",
    conditionOfTheSpoutWork: "investor",
    conditionOfTheSpoutComments: "",
    floorHeating: false,
    connectingPanelsToAnotherFloor: false,
    differentFloorLevel: "",
    wallStrips: "",
    typeOfStrips: "",
    stripColor: "",
    roomName: "",
    file: [],
    activities: [],
    floorArea: [],
    comments: "",
  };

  const [isValidated, setIsValidated] = useState(false);

  const validationSchema = Yup.object().shape({
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const { mutate: editMeasurementInfo } = usePutFloorInfo({
    onSuccess: () => {
      navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}`);
    },
  });

  const handleOnSubmit = async (values: any) => {
    editMeasurementInfo(values);
  };

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <SingleSectionElement
                className="padding-bottom-separator"
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                label="Rysunek podziału okien"
                name="drawing"
                component={Drawing}
              />
              <SingleSectionElement
                touched={touched}
                name="directionOfLayingThePanels"
                errors={errors}
              >
                <Field
                  label="Kierunek układania paneli"
                  name="directionOfLayingThePanels"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="conditionOfTheSpout"
                errors={errors}
              >
                <Field
                  first="W normie"
                  second="Do poprawy"
                  stringMode
                  label="Stan wylewki"
                  name="conditionOfTheSpout"
                  valueFirst="normal"
                  valueSecond="toImprove"
                  component={Switch}
                />
                {values.conditionOfTheSpout === "toImprove" && (
                  <>
                    <Field
                      checked={values.conditionOfTheSpoutWork === "investor"}
                      label={`Kto ma przygotować otwór?`}
                      name="conditionOfTheSpoutWork"
                      first="Inwestor"
                      second="MartDom"
                      stringMode
                      valueFirst="investor"
                      valueSecond="martdom"
                      component={Switch}
                    />
                    <Field
                      className="am-margin-top"
                      errors={errors}
                      label="Dodatkowe uwagi"
                      name="conditionOfTheSpoutComments"
                      component={Textarea}
                      maxLength={250}
                    />
                  </>
                )}
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="floorHeating"
                errors={errors}
              >
                <Field
                  label="Ogrzewanie podłogowe"
                  name="floorHeating"
                  component={Switch}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="connectingPanelsToAnotherFloor"
                errors={errors}
              >
                <Field
                  label="Łączenie paneli z inną podłogą"
                  name="connectingPanelsToAnotherFloor"
                  component={Switch}
                />
                {values?.connectingPanelsToAnotherFloor && (
                  <>
                    <Field
                      label="Poziom innej podłogi"
                      rightPlaceholder="mm"
                      type="number"
                      maxLength={4}
                      name="differentFloorLevel"
                      component={Input}
                    />
                  </>
                )}
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator"
                touched={touched}
                name="wallStrips"
                errors={errors}
              >
                <Field
                  label="Listwy przyścienne"
                  rightPlaceholder="m"
                  type="number"
                  maxLength={4}
                  name="wallStrips"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator"
                touched={touched}
                name="typeOfStrips"
                errors={errors}
              >
                <Field
                  label="Rodzaj listew"
                  name="typeOfStrips"
                  component={Input}
                  maxLength="30"
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator"
                touched={touched}
                name="stripColor"
                errors={errors}
              >
                <Field
                  optional
                  label="Kolor listwy"
                  name="stripColor"
                  component={Input}
                  maxLength="30"
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="padding-top-separator single-section-element--strips"
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <div className="am-section-heading">
                  Ilość wykończeń (listwa PCV) <span>(opcjonalnie)</span>
                </div>
                <Field
                  optional
                  label="Narożnik wewnętrzny"
                  name="internalCorner"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                />
                <Field
                  optional
                  label="Narożnik zewnętrzny"
                  name="externalCorner"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                />
                <Field
                  optional
                  label="Łącznik"
                  name="connector"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                />
                <Field
                  optional
                  label="Zakończenie prawe"
                  name="rightEnding"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                />
                <Field
                  optional
                  label="Zakończenie lewe"
                  name="leftEnding"
                  component={Input}
                  maxLength={3}
                  rightPlaceholder="szt."
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                component={DoorSills}
              />
              <Field
                errors={errors}
                validateField={validateField}
                component={FloorArea}
              />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button onClick={() => handleSubmit()} label="Zakończ pomiar" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FloorMeasurementValues;
