import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type ExternalDoorsUpdateData = {
  measurementUuid: string;
  roomName: string;
  durability: 'left' | 'right';
  openingDirection: 'inward' | 'outward';
  holeSizeWidth: string;
  holeSizeHeight: string;
  heightFromZeroLevel: string;
  doorWidth: string;
  doorHeight: string;
  doorSideLighting: string;
  doorTopLighting: string;
  doorBottomLighting: string;
  isTheOpeningCorrect: 'true' | 'false';
  whatShouldBeTheCorrectWidth?: string;
  whatShouldBeTheCorrectHeight?: string;
  whoIsToPrepareTheOpening?: 'investor' | 'martdom';
  replacementDoor?: boolean;
  manufacturer?: string;
  model?: string;
  color?: string;
  typeOfDoorFrame?: string;
  doorHandle?: string;
  doorKnocker?: string;
  peephole?: boolean;
  glassPanels?: string;
  powerSupply?: string;
  dripEdge?: boolean;
  doorAbbrevation?: string;
  contactSensors?: boolean;
  comments?: string;
};

const usePutExternalDoorsInfo = (
  options?: UseMutationOptions<any, Error, ExternalDoorsUpdateData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editExternalDoorsClientInfo'],
    mutationFn: async (data: ExternalDoorsUpdateData) => {
      return axiosInstance.put(`/measurement/external-doors-in-raw-condition/info`, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych drzwi zewnętrznych...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-external-doors-client-info',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane drzwi zewnętrznych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-external-doors-client-info',
      });
      queryClient.invalidateQueries({ queryKey: ['externalDoorsClientInfo'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych drzwi zewnętrznych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-external-doors-client-info',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutExternalDoorsInfo;
