
export enum USER_ROLE {
  scheduleManager = 'scheduleManager',
  measurer = 'measurer',
  fitter = 'fitter',
  admin = 'admin',
  advisor = 'advisor',
}

export const roleInfo = {
  [USER_ROLE.scheduleManager]: { jobTitle: 'Grafikowiec', color:'#9DD6FF' , role: USER_ROLE.scheduleManager },
  [USER_ROLE.measurer]: { jobTitle: 'Pomiarowiec', color: '#38D58B', role: USER_ROLE.measurer  },
  [USER_ROLE.fitter]: { jobTitle: 'Monter', color: '#FFBDCC', role: USER_ROLE.fitter },
  [USER_ROLE.admin]: { jobTitle: 'Admin', color: '#FFBDCC', role: USER_ROLE.fitter },
  [USER_ROLE.advisor]: { jobTitle: 'Doradca', color: '#FFE350', role: USER_ROLE.fitter },
};

