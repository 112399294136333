import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import {addEmployeeType} from 'types/UsersTypes'

const useAddEmployee = (
  options?: UseMutationOptions<any, Error, addEmployeeType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['addEmployee'],
    mutationFn: async ({
      phoneNumber,
      firstName,
      lastName,
      role,
      phonePrefixUuid,
      locationUuid,
      rate,
      userColor,
    }) => {
      return axiosInstance.post('/users/', {
        phoneNumber,
        firstName,
        lastName,
        role,
        phonePrefixUuid,
        locationUuid,
        rate,
        userColor,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'add-employee',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Pracownik został dodany pomyślnie',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'add-employee',

      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas dodawania pracownika',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'add-employee',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddEmployee;
