/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import "./blinds-and-roller-blinds.scss";
import { Switch, StaticAlert, Textarea, CheckboxPart } from "components";
import { Field } from "formik";
import { useGetBlindsInstallationTypes } from "hooks/measurements";
import { BlindsInstallationType } from "types/MeasurementTypes";

interface BlindsAndRollerBlindsType {
  field: any;
  errors?: any;
  form: any;
  isChecked: boolean;
  values?: any;
}

const BlindsAndRollerBlinds = ({
  field,
  errors,
  form,
  isChecked,
  values,
}: BlindsAndRollerBlindsType) => {
  const { data: blindsInstallationTypes } = useGetBlindsInstallationTypes();
  const [typeOfBlindsUuids, setTypeOfBlindsUuids] = useState<any>([]);

  useEffect(() => {
    if (
      typeOfBlindsUuids.length === 0 ||
      typeOfBlindsUuids !== form.values.typeOfBlindInstallationUuids
    ) {
      setTypeOfBlindsUuids(form.values.typeOfBlindInstallationUuids || []);
    }
  }, [form.values.typeOfBlindInstallationUuids, blindsInstallationTypes]);

  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray = typeOfBlindsUuids.find((item: any) => item === uuid);
    if (!isUuidInArray) {
      const values = [...typeOfBlindsUuids, uuid];
      setTypeOfBlindsUuids(values);
      form.setFieldValue("typeOfBlindInstallationUuids", values);
    } else {
      const values = typeOfBlindsUuids.filter((item: any) => item !== uuid);
      setTypeOfBlindsUuids(values);
      form.setFieldValue("typeOfBlindInstallationUuids", values);
    }
  };

  const handleWriteComment = (e: any) => {
    console.log("tutajs");
    const commentItem =
      blindsInstallationTypes &&
      blindsInstallationTypes.find((item: any) => item.type === "comments");
    const isCommentInArray = typeOfBlindsUuids.find(
      (item: any) => item.comment
    );
    if (commentItem && !isCommentInArray) {
      const values = [
        ...typeOfBlindsUuids,
        { comment: e, uuid: commentItem?.value },
      ];
      setTypeOfBlindsUuids(values);
      form.setFieldValue("typeOfBlindInstallationUuids", values);
    } else if (commentItem && isCommentInArray) {
      const newTypeOfBlindsUuids = typeOfBlindsUuids.filter(
        (item: any) => item.uuid !== commentItem?.value
      );
      const values = [
        ...newTypeOfBlindsUuids,
        { comment: e, uuid: commentItem?.value },
      ];
      setTypeOfBlindsUuids(values);
      form.setFieldValue("typeOfBlindInstallationUuids", values);
    }
  };

  return (
    <div className="kwcc barb">
      <div className="kwcc__switch">
        <label className="kwcc__label">Żaluzje / Rolety</label>
        <Switch checked={isChecked} field={field} form={form} />
      </div>
      {isChecked && (
        <>
          <div className="am-section-sub-heading">Rodzaj montażu</div>
          <div className="am-section-checkbox-list">
            {blindsInstallationTypes &&
              blindsInstallationTypes.length > 0 &&
              blindsInstallationTypes.map((item: BlindsInstallationType) => {
                if (item.type !== "comments") {
                  return (
                    <CheckboxPart
                      checked={values?.typeOfBlindInstallationUuids?.find(
                        (uuid: string) => uuid === item.value
                      )}
                      key={item.value}
                      label={item.label}
                      uuid={item.value}
                      onChange={(uuid) => handleClickCheckbox(uuid)}
                    />
                  );
                }
                return false;
              })}
          </div>
          {errors && form.touched && (
            <>
              <StaticAlert
                className="am-alert"
                size="small"
                show={
                  errors["typeOfBlindInstallationUuids"] &&
                  errors["typeOfBlindInstallationUuids"]
                }
                label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
              />
            </>
          )}
          <Field
            label="Dodatkowe uwagi"
            name="typeOfBlindInstallationComments"
            component={Textarea}
            onChange={(e: any) => handleWriteComment(e)}
            maxLength={250}
          />
        </>
      )}
      <div
        className={`am-separator ${
          isChecked ? "am-separator--active" : "am-separator--disabled"
        }`}
      />
    </div>
  );
};

export default BlindsAndRollerBlinds;
