import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type RoomType = {
  type: string;    
  label: string;   
  value: string;   
  uuid?: string;
};

export default function useGetRooms(options = {}) {
  return useQuery({
    queryKey: ["rooms"],
    queryFn: async () => {
      return axiosInstance
        .get("/rooms")
        .then((res) => res.data);
    },
    select: (data: {roomsLabel: RoomType[]}) => data.roomsLabel,
    retry: false,
    ...options,
  });
}