/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Modal, InstallationsForm } from "components";
import { isMobile } from "utils";
import { useSearchParams } from "react-router-dom";
import { useGetOrderDetails } from "hooks/orders";
import "./arranging-assembly-panel.scss";
import moment from "moment";

interface ArrangingAssemblyPanelType {
  date: string | undefined;
  setIsModalOpen: (isOpen: boolean) => void;
  calendarEvents: any;
  setDate: any;
  user: { label: string; value: string };
  isModalOpen: boolean;
}

const ArrangingAssemblyPanel = ({
  date,
  calendarEvents,
  setDate,
  user,
  isModalOpen,
  setIsModalOpen,
}: ArrangingAssemblyPanelType) => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");
  const isEdit = searchParams.get("edit") === "true";
  const { data: orderDetails, isLoading } = useGetOrderDetails(uuid, true);
  const today = moment().format("YYYY-MM-DD");
  useEffect(() => {
    setDate(today);
  }, []);

  return (
    <div className="amp">
      {!isLoading && (
        <Modal
          className={`amp__modal ${isMobile() ? "" : "modal--aside"}`}
          clean
          side={isMobile() ? "center" : "right"}
          isOpened={isModalOpen}
          onClose={() => null}
        >
          <>
            <div className="modal-heading">
              {isEdit ? "Edycja " : "Umawianie "}montażu
            </div>
            {!isMobile() ? (
              <>
                <div className="amp__name">{orderDetails?.name}</div>
                <div className="modal__separator" />
              </>
            ) : (
              <>
                <div className="modal__information-header">Pracownik</div>
                <div className="modal__information-descript">{user.label}</div>
              </>
            )}
            <div className="modal__information-header">Adres pomiaru</div>
            <div className="modal__information-descript">
              {orderDetails?.address}
            </div>

            {orderDetails?.measurementAllInstallation.length > 0 && (
              <>
                <div className="modal__information-header">
                  Montaż do ustalenia
                </div>
                <InstallationsForm
                  isEdit={isEdit}
                  orderDetails={orderDetails}
                />
              </>
            )}
          </>
        </Modal>
      )}
    </div>
  );
};

export default ArrangingAssemblyPanel;
