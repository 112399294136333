import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig"; 
import { SetPasswordType } from "types/AuthTypes";
import { useNavigate } from "react-router";
import { ROUTE_ENUM } from "enums";
import { Toast } from "components"; 
import { TOAST_TYPES_ENUM } from 'enums'; 

const updateUser = async (
  userDetails: SetPasswordType
): Promise<any> => {
  const { data } = await axiosConfig.put(`auth/activate`, userDetails);
  return data;
};

export const useSetPassword = (
  options?: UseMutationOptions<any, Error, SetPasswordType>
) => {
  const navigate = useNavigate();
  return useMutation<any, Error, SetPasswordType, unknown>({
    mutationKey: ["set-password"],
    mutationFn: updateUser,
    onMutate: async (variables) => {
      Toast({
        message: "Trwa ustawienie hasła...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "set-password"
      });
      return options?.onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Hasło zostało ustawione.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "set-password"
      });
      navigate(ROUTE_ENUM.LOGIN);
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Nie udało się ustawić hasła.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "set-password",
      });
      options?.onError?.(error, variables, context);
    },
    ...options,
  });
};
