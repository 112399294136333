import { UserBadge } from "components";
import "./user-name-and-badge.scss";

const UserNameAndBadge = ({ user, className = "" }: any) => {
  return (
    <div className={`${className} user-name-and-badge__user`}>
      <div className="user-name-and-badge__user-item">{`${user.firstName} ${user.lastName}`}</div>
      <UserBadge role={user.role} />
    </div>
  );
};

export default UserNameAndBadge;
