import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { UpdateActivityType } from 'types/ActivityTypes';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetUserActivities() {
  return useQuery({
    queryKey: ["userActivities"],
    queryFn: async () => {
      await delay(1000); 
      return axiosInstance
        .get(`/activity`)
        .then((res) => res.data);
    },
    select: (data: { activities: UpdateActivityType[] }) => data?.activities, 
    retry: false,
    staleTime: 0, 
  });
}
