import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type UpdateActivityTimeData = {
  timeManagementUuid?: string;
  dateStart?: string;
  dateEnd?: string;
  comment?: string;
  countedTime?: string;
  firstDate?: string;
};

const useUpdateActivityTime = (
  options?: UseMutationOptions<any, Error, UpdateActivityTimeData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['updateActivityTime'],
    mutationFn: async (data: UpdateActivityTimeData) => {
      return axiosInstance.put(`/activity/time/update`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: `Aktualizowanie ${variables.comment ? 'komentarza' : 'czasu'}  aktywności...`,
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'update-activity-time',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: `${variables.comment ? 'Komentarz' : 'Czas'} aktywności został zaktualizowany pomyślnie.`,
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'update-activity-time',
      });
      queryClient.invalidateQueries({ queryKey: ['timeManagement'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: `Błąd podczas aktualizowania ${variables.comment ? 'komentarza' : 'czasu'} aktywności.`,
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'update-activity-time',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateActivityTime;
