import { useState } from "react";
import { Select, Button } from "components";
import { Field, Formik } from "formik";
import { SingleSectionElement } from "features/addingMeasurement";
import {
  SwingGateForm,
  TiltingGateForm,
  SectionalGateForm,
  RollerGateForm,
} from "features/addingMeasurement";
interface GarageDoorMeasurementValuesType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const GarageDoorMeasurementValues = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: GarageDoorMeasurementValuesType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [typeOfGate, setTypeOfGate] = useState("");

  const handleOnSubmit = (values: any) => {
    console.log(values);
  };

  enum GARAGE_DOOR_TYPE {
    SWING_GATE = "swingGate",
    TILTING_GATE = "tiltingGate",
    SECTIONAL_GATE = "sectionalGate",
    ROLLER_GATE = "rollerGate",
  }

  const typeOfGarageDoor = [
    {
      value: GARAGE_DOOR_TYPE.SWING_GATE,
      label: "Brama rozwierna",
    },
    {
      value: GARAGE_DOOR_TYPE.TILTING_GATE,
      label: "Brama uchylna",
    },
    {
      value: GARAGE_DOOR_TYPE.SECTIONAL_GATE,
      label: "Brama segmentowa",
    },
    {
      value: GARAGE_DOOR_TYPE.ROLLER_GATE,
      label: "Brama roletowa",
    },
  ];

  const handleSetTypeOfGate = (e: { value: string; label: string }) => {
    setTypeOfGate(e.value);
  };

  const handleShowForm = (
    type: string,
    touched: any,
    errors: any,
    validateField: any
  ) => {
    switch (type) {
      case GARAGE_DOOR_TYPE.SWING_GATE:
        return (
          <SwingGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
          />
        );
      case GARAGE_DOOR_TYPE.TILTING_GATE:
        return (
          <TiltingGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
          />
        );
      case GARAGE_DOOR_TYPE.SECTIONAL_GATE:
        return (
          <SectionalGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
          />
        );
      case GARAGE_DOOR_TYPE.ROLLER_GATE:
        return (
          <RollerGateForm
            validateField={validateField}
            touched={touched}
            errors={errors}
          />
        );
      default: {
        return <div>zobacz sam jak gumisie skacz</div>;
      }
    }
  };

  return (
    <>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          typeOfGate: "",
        }}
        // validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          return (
            <form className={`amfs__form form `} onSubmit={handleSubmit}>
              <SingleSectionElement
                touched={touched}
                name="spoutLevel"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Rodzaj bramy"
                  name="typeOfGate"
                  type="number"
                  options={typeOfGarageDoor}
                  component={Select}
                  onChange={(e: any) => handleSetTypeOfGate(e)}
                  white
                />
              </SingleSectionElement>
              {typeOfGate && (
                <>
                  {handleShowForm(typeOfGate, touched, errors, validateField)}
                  <div className="am__buttons am__buttons--last-step am__buttons--margin-top">
                    <Button
                      stroke
                      onClick={() => prevStep()}
                      label="Poprzedni krok"
                    />
                    <Button
                      stroke
                      onClick={() => {
                        handleSubmit();
                      }}
                      label="Zakończ i dodaj kolejny tego typu"
                    />
                    <Button
                      onClick={() => handleSubmit()}
                      label="Zakończ pomiar"
                    />
                  </div>
                </>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default GarageDoorMeasurementValues;
