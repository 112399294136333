import { ReactNode, useEffect, useState } from "react";
import "./phone-with-prefix.scss";

interface PhoneWithPrefixProps {
  children: ReactNode;
}

const PhoneWithPrefix = ({ children }: PhoneWithPrefixProps) => {
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    const inputErrorElement = document.querySelector(
      ".phone-with-prefix .input__error"
    );
    if (inputErrorElement) {
      const handleTextChange = () => {
        setErrorText(inputErrorElement.textContent || "");
      };
      inputErrorElement.addEventListener(
        "DOMSubtreeModified",
        handleTextChange
      );
      return () => {
        inputErrorElement.removeEventListener(
          "DOMSubtreeModified",
          handleTextChange
        );
      };
    }
  }, []);

  return (
    <div
      className={`phone-with-prefix ${
        errorText ? "phone-with-prefix--error" : ""
      }`}
    >
      <div className="phone-with-prefix__content">
        <div className="phone-with-prefix__separator" />
        {children}
      </div>
    </div>
  );
};

export default PhoneWithPrefix;
