import { Button, Modal } from "components";
import { useToggleAssemblyMaterialVisibility } from "hooks/assembly-materials";

interface HideAssemblyMaterialsType {
  isOpen: boolean;
  isHiddenMaterial: boolean;
  material: any;
  onClose: () => void;
  setPage: (page: number) => void;
  page: number;
}

const HideAssemblyMaterials = ({
  material = { name: "", cost: "" },
  isOpen,
  onClose,
  isHiddenMaterial,
  setPage,
  page,
}: HideAssemblyMaterialsType) => {
  const { mutate: toggleVisibility } = useToggleAssemblyMaterialVisibility();

  const handleSend = async () => {
    await toggleVisibility({ uuid: material.uuid });
    if (material.isLastElement) {
      setPage(page - 1);
    }
    onClose();
  };

  return (
    <>
      <Modal isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">
            {isHiddenMaterial ? "Przywracanie" : "Ukrywanie"} materiału
          </div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz{" "}
            {isHiddenMaterial ? "przywrócić" : "ukryć"} materiał
            <span>{material.name}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={() => handleSend()} label="Potwierdź" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default HideAssemblyMaterials;
