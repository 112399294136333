import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from 'axiosConfig';
import { TokenTypes } from 'types/AuthTypes';

const checkToken = async (token: TokenTypes): Promise<any> => {
  const {data} = await axiosConfig.get(
    `/auth/valid/token?token=${token.token}`
  );
  return data;
};

export const useCheckToken = (
  options?: UseMutationOptions<any, Error, TokenTypes, any>
) => {
  return useMutation({
    mutationKey: ["check-token"],
    mutationFn: async (token: TokenTypes) => {
      return checkToken(token);
    },
    onSuccess: (data, variables, context) => {
      if(options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      if(options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options
  });
};
