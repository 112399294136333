/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect } from "react";
import { isMobile } from "utils";
import Table from "rc-table";
import { Pagination, SkeletonRow } from "components";
import { desktopColumns, mobileColumns } from "./utils";
import "./orders-table.scss";
import "../../users/UsersTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetOrders } from "hooks/orders";
import { OrderStatus } from "types/OrderTypes";

interface OrderPreviewType {
  setOrderPreview: (order: any) => void;
  activeFloatingMenuItem?: { label: string; value: string };
  isMeasurer: boolean;
  page: number;
  setPage: (page: number) => void;
}

const OrdersTable = ({
  setOrderPreview,
  activeFloatingMenuItem,
  isMeasurer,
  page,
  setPage,
}: OrderPreviewType) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const uuidFromUrl = searchParams.get("orderUuid");
  const tabFromUrl = searchParams.get("tab");
  const [activeRowId, setActiveRowId] = useState(0);

  const { data: orders, isLoading } = useGetOrders(
    (activeFloatingMenuItem?.value ??
      "measurementsToBeReleased") as OrderStatus,
    page,
    {
      enabled: true,
    }
  );

  useEffect(() => {
    if (uuidFromUrl && orders?.data) {
      const record = orders.data.find((r) => r.uuid === uuidFromUrl);
      if (record) {
        setTimeout(() => {
          setActiveRowId(record.id);
          setOrderPreview(record);
        }, 200);
      }
    }
  }, [orders]);

  const handleClickRow = (record: any) => {
    setActiveRowId(record.id);
    setOrderPreview(record);
    activeFloatingMenuItem?.value
      ? setSearchParams({
          page: page.toString(),
          orderUuid: record.uuid,
          tab: activeFloatingMenuItem.value,
        })
      : setSearchParams({ page: page.toString(), orderUuid: record.uuid });
  };

  return (
    <div className="users-table">
      {orders && orders?.data?.length > 0 && (
        <Table
          rowKey="uuid"
          className="user-table order-table"
          data={orders?.data}
          columns={
            isMobile()
              ? mobileColumns(
                  navigate,
                  setSearchParams,
                  page,
                  isMeasurer,
                  activeFloatingMenuItem
                )
              : desktopColumns(
                  navigate,
                  setSearchParams,
                  page,
                  isMeasurer,
                  activeFloatingMenuItem
                )
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleClickRow(record);
              },
              className: activeRowId === record.id ? "active-row" : "",
            };
          }}
        />
      )}
      {isLoading && <SkeletonRow count={10} height={58} />}
      {orders?.meta && orders?.meta?.lastPage > 1 && (
        <Pagination
          totalPages={orders?.meta?.lastPage}
          currentPage={page}
          onPageChange={(e) => {
            setPage(e);
            tabFromUrl
              ? setSearchParams({ page: e.toString(), tab: tabFromUrl })
              : setSearchParams({ page: e.toString() });
          }}
        />
      )}
    </div>
  );
};

export default OrdersTable;
