import "./kitchen-window-connected-countertop.scss";
import { Switch, StaticAlert, Input } from "components";
import { Field } from "formik";

interface KitchenWindowConnectedCountertopType {
  field: any;
  errors?: any;
  form: any;
  isChecked: boolean;
}

const KitchenWindowConnectedCountertop = ({
  field,
  errors,
  form,
  isChecked,
}: KitchenWindowConnectedCountertopType) => {
  return (
    <div className="kwcc">
      <div className="kwcc__switch">
        <label className="kwcc__label">Okno kuchenne łączone z blatem</label>
        <Switch checked={isChecked} field={field} form={form} />
      </div>
      {isChecked && (
        <div className="kwcc__input">
          <Field
            label="Ustalona wysokość blatu"
            rightPlaceholder="mm"
            type="number"
            name="kitchenWindowConnectedToTheCountertopMeasurement"
            component={Input}
          />
        </div>
      )}
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={errors[field.name] && errors[field.name]}
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <div
        className={`am-separator ${
          isChecked ? "am-separator--active" : "am-separator--disabled"
        }`}
      />
    </div>
  );
};

export default KitchenWindowConnectedCountertop;
