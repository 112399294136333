import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosConfig from "axiosConfig";
import { ResetPasswordType } from "types/AuthTypes";
import { ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";
import { Toast } from "components"; 
import { TOAST_TYPES_ENUM } from 'enums'; 

const resetPassword = async (
  credentials: ResetPasswordType
): Promise<any> => {
  const { data } = await axiosConfig.put(`/auth/recover`, credentials);
  return data;
};

export const useResetPassword = (
  options?: UseMutationOptions<any, Error, ResetPasswordType>
) => {
  const navigate = useNavigate();
  return useMutation<any, Error, ResetPasswordType, unknown>({
    mutationKey: ["reset-password-create"],
    mutationFn: resetPassword,
    ...options,

    onMutate: async (variables) => {
      Toast({
        message: "Trwa zmiana hasła...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: "reset-password"
      });
      return options?.onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: "Hasło zostało zmienione.",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: "reset-password"
      });
      navigate(ROUTE_ENUM.PASSWORD_HAS_BEEN_CHANGED);
      return options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      Toast({
        message: "Nie udało się zmienić hasła.",
        type: TOAST_TYPES_ENUM.ERROR,
        id: "reset-password",
      });
      return options?.onError?.( error, variables, context);
    },
  });
};
