import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type FloorsInstallationInfoData = {
  measurementUuid: string;
  screedHumidity: string;
  airHumidity: string;
  plannedAssemblyTime: string;
  numberOfAssemblyTeams: string;
  comments?: string;
};

const usePutFloorsInstallationInfo = (
  options?: UseMutationOptions<any, Error, FloorsInstallationInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editFloorsInstallationInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/floors/installation-info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych instalacji podłogi...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-floors-installation-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane instalacji podłogi zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-floors-installation-info',
      });
      queryClient.invalidateQueries({ queryKey: ['floorsInstallationInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych instalacji podłogi.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-floors-installation-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutFloorsInstallationInfo;
