import { Page, PageContent, UserAccessPanel, Button } from "components";
import { ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";
import "./password-link-expired.scss";

const PasswordLinkExpired = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    sessionStorage.setItem("recoveryAgain", JSON.stringify(true));
    navigate(ROUTE_ENUM.FORGET_PASSWORD);
  };

  return (
    <Page>
      <PageContent>
        <UserAccessPanel heading="Ten link wygasł">
          <div className="uap__description password-link-expired__description">
            W celu zresetowania hasła, skorzystaj z formularza.
          </div>
          <div className="uap__button">
            <Button
              label="Formularz przypomnienia hasła"
              onClick={() => handleNavigate()}
            />
          </div>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default PasswordLinkExpired;
