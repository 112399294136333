import { Input, StaticAlert, Select, Textarea } from "components";
import { Field } from "formik";

import "./addition-bottom-window.scss";

interface AdditionBottomWindowType {
  field: any;
  errors?: any;
  form: any;
  validateField: any;
  label: string;
}

const AdditionBottomWindow = ({
  errors,
  form,
  field,
  label,
  validateField,
}: AdditionBottomWindowType) => {
  const windowBottomOptions = [
    {
      value: "clinarite",
      label: "Klinaryt",
    },
    {
      value: "systemicExpansion",
      label: "Poszerzenie systemowe",
    },
    {
      value: "warmWindowSill",
      label: "Ciepły parapet",
    },
    {
      value: "undersillStrip",
      label: "Listwa podparapetowa",
    },
    {
      value: "other",
      label: "Inne",
    },
  ];
  return (
    <div className="addition-bottom-window">
      <div className="am-section-heading">{label}</div>
      <Field
        label="Dodatek dół okna"
        placeholder="Wybierz"
        name="windowBottomAddition"
        component={Select}
        options={windowBottomOptions}
        optional
        white
      />
      {form.values.windowBottomAddition === "other" && (
        <Field name="windowBottomAdditionComment" component={Textarea} />
      )}
      <Field
        label="Wymiar"
        name="windowBottomAdditionSize"
        type="number"
        rightPlaceholder="mm"
        onChange={() => validateField("holeSizeHeight")}
        component={Input}
      />
      {errors && form.touched && (
        <>
          <StaticAlert
            className="am-alert"
            size="small"
            show={
              (errors["windowBottomAddition"] &&
                errors["windowBottomAddition"]) ||
              (errors["windowBottomAdditionSize"] &&
                errors["windowBottomAdditionSize"])
            }
            label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
          />
        </>
      )}
      <div className="am-separator" />
    </div>
  );
};

export default AdditionBottomWindow;
