import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type TypeOfInstallation = {
  uuid: string;
  comment?: string;
};

type ExternalDoorsClientInfoData = {
  measurementUuid: string;
  typeOfInstallationUuids: TypeOfInstallation[];
  spoutLevel: 'fromTheProject' | 'specifiedByInvestor' | 'specifiedByContractor' | 'measuredFromStairLevel';
  typeOfStars?: 'concrete' | 'selfSupporting';
  starsMeasurement?: string; 
  preparationOfOpeningsForTripleLayerInstallation?: boolean | null;
  changeOfOpeningDimensions?: boolean | null;
  constructionSiteCleanupInvestor?: boolean;
  comments?: string;
};

const usePutExternalDoorsClientInfo = (
  options?: UseMutationOptions<any, Error, ExternalDoorsClientInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editExternalDoorsClientInfo'],
    mutationFn: async (data: ExternalDoorsClientInfoData) => {
      return axiosInstance.put(`/measurement/external-doors-in-raw-condition/client-info`, data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych drzwi zewnętrznych w stanie surowym...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-external-doors-client-info',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane drzwi zewnętrznych zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-external-doors-client-info',
      });
      queryClient.invalidateQueries({ queryKey: ['externalDoorsClientInfo'] });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych drzwi zewnętrznych.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-external-doors-client-info',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutExternalDoorsClientInfo;
