import { useState, useRef, useEffect } from "react";
import { ChromePicker } from "react-color";

import "./color-input.scss";

interface ColorInputProps {
  label?: string;
  type: string;
  icon?: string;
  inputOnFocus?: any;

  // formik;
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (data: string) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  onFocus: any;
  onBlur?: any;
}

const ColorInput = ({ label, errors = {}, form, field }: ColorInputProps) => {
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
  const [color, setColor] = useState(field.value);
  const colorInputRightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        colorInputRightRef.current &&
        event.target instanceof Node &&
        !colorInputRightRef.current.contains(event.target)
      ) {
        setIsOpenColorPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeColor = (hex: string) => {
    setColor(hex);
    form.setFieldValue(field.name, hex);
  };

  return (
    <div className="color-input__wrapper">
      {label && <label className="input__label">{label}</label>}
      <div className="color-input">
        <div
          className={`color-input__button ${
            isOpenColorPicker ? "color-input__button--disabled" : ""
          }`}
          onClick={() => setIsOpenColorPicker(!isOpenColorPicker)}
        >
          Wybierz kolor
        </div>
        <div className="color-input__separator" />
        <div className="color-input__right" ref={colorInputRightRef}>
          {isOpenColorPicker && (
            <ChromePicker
              color={color}
              onChange={(color) => handleChangeColor(color.hex)}
            />
          )}
          <div
            className="color-input__color"
            style={{ backgroundColor: color }}
            onClick={() => setIsOpenColorPicker(!isOpenColorPicker)}
          />
        </div>
      </div>
      {errors && form.touched && (
        <div className="input__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
};

export default ColorInput;
