/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Calendar, SVG } from "components";
import {
  ArrangingMeasurementPanel,
  UserSelectForm,
  ArrangingAssemblyPanel,
} from "features/calendar";
import moment from "moment";
import "./arranging-measurement.scss";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { ROUTE_ENUM, SVG_TYPE } from "enums";
import {
  useGetOrdersByEmployee,
  useGetOrderDetails,
  useGetEmployeeSchedule,
} from "hooks/orders";
import { useQueryClient } from "@tanstack/react-query";
import { isMobile, convertToEvents } from "utils";
import { checkAvailableHours } from "./utils";

const ArrangingMeasurement = () => {
  const [searchParams] = useSearchParams();
  const today = moment().format("YYYY-MM-DD");
  const initialModalOpen = isMobile() ? false : true;
  const [date, setDate] = useState<string | undefined>(today);
  const [calendarEvents, setCalendarEvents] = useState<any>();
  const [calendarEventsWithoutEditing, setCalendarEventsWithoutEditing] =
    useState();
  const [month, setMonth] = useState(moment(date).format("M"));
  const [isModalOpen, setIsModalOpen] = useState(initialModalOpen);
  const [usersTable, setUsersTable] = useState<any>(["all"]);
  const [user, setUser] = useState<{ label: string; value: string }>({
    label: "",
    value: "",
  });
  const queryClient = useQueryClient();
  const uuid = searchParams.get("uuid");
  const tab = searchParams.get("tab");
  const isEdit = tab === "fixedMeasurements";
  const isInstallationToBeReleased = tab === "installationToBeReleased";
  const { data: orderDetails } = useGetOrderDetails(uuid, true);
  const navigate = useNavigate();
  const { data } = useGetOrdersByEmployee(user.value, month);
  const { data: employeesAppointment } = useGetEmployeeSchedule(
    usersTable,
    month,
    "fitter"
  );

  useEffect(() => {
    if (orderDetails && orderDetails.date && !isInstallationToBeReleased) {
      setDate(
        `${moment(orderDetails.date, "DD/MM/YYYY").format("YYYY-MM-DD")} ${
          orderDetails.start
        }-${orderDetails.end}`
      );
    }
  }, [orderDetails]);

  useEffect(() => {
    if (!uuid) {
      navigate(ROUTE_ENUM.ORDERS_LIST);
    }
  }, [uuid, navigate]);

  useEffect(() => {
    if (isInstallationToBeReleased) {
      setCalendarEvents(
        convertToEvents(employeesAppointment ? employeesAppointment : [])
      );
    } else if (data) {
      if (isEdit && orderDetails) {
        const findEvent = data.find((item) => item.id === orderDetails.id);
        const addEventColor = { ...findEvent, userColor: "#FFC17A" };
        const removeEvent = data.filter((item) => item.id !== orderDetails.id);
        setCalendarEventsWithoutEditing(convertToEvents(removeEvent));

        if (findEvent && findEvent.date === moment(date).format("DD/MM/YYYY")) {
          const dateSplit = date?.split(" ");
          if (dateSplit) {
            const timeParts = dateSplit[1].split("-");
            const [startTime, endTime] = timeParts;
            const events = [
              ...removeEvent,
              {
                ...addEventColor,
                start: startTime,
                end: endTime,
                date: moment(dateSplit[0]).format("DD/MM/YYYY"),
              },
            ];
            setCalendarEvents(convertToEvents(events));
          }
        } else {
          const dateSplit = date?.split(" ");

          if (dateSplit) {
            const timeParts = dateSplit[1].split("-");
            const [startTime, endTime] = timeParts;
            const findEventsThisSameDay = removeEvent.filter(
              (item) => item.date === moment(dateSplit[0]).format("DD/MM/YYYY")
            );

            if (findEventsThisSameDay.length > 0) {
              const available = checkAvailableHours(findEventsThisSameDay, {
                addEventColor,
                date: moment(dateSplit[0]).format("DD/MM/YYYY"),
                start: startTime,
                end: endTime,
              });
              if (available !== null) {
                const { start, end } = available;
                const events = [
                  ...removeEvent,
                  {
                    ...addEventColor,
                    title: orderDetails.name,
                    date: moment(dateSplit[0]).format("DD/MM/YYYY"),
                    start,
                    end,
                  },
                ];
                setDate(
                  `${moment(dateSplit[0]).format("YYYY-MM-DD")} ${start}-${end}`
                );
                setCalendarEvents(convertToEvents(events));
              }
            } else {
              const events = [
                ...removeEvent,
                {
                  ...addEventColor,
                  title: orderDetails.name,
                  date: moment(dateSplit[0]).format("DD/MM/YYYY"),
                  start: startTime,
                  end: endTime,
                },
              ];
              setCalendarEvents(convertToEvents(events));
            }
          }
        }
      } else {
        setCalendarEvents(convertToEvents(data));
      }
    }
  }, [data, date, employeesAppointment, usersTable]);

  useEffect(() => {
    const dateSplit = date?.split(" ");
    const newMonth =
      (dateSplit && moment(dateSplit[0]).format("M")) ||
      moment(orderDetails?.date, "DD/MM/YYYY").format("M");

    if (newMonth !== month) {
      setMonth(newMonth);
      queryClient.invalidateQueries({
        queryKey: ["ordersByEmployee", user.value, newMonth],
      });
    }
  }, [date, month, user, queryClient]);

  return (
    <div
      className={`arranging-measurement ${
        isInstallationToBeReleased ? "arranging-assembly" : ""
      }`}
    >
      {isMobile() && (
        <>
          <div className="arranging-measurement__top">
            <div
              className="arranging-measurement__top-left"
              onClick={() => navigate(-1)}
            >
              <SVG type={SVG_TYPE.ARROW_BACK} />
            </div>
            <div className="arranging-measurement__top-right">
              <div className="bold">
                {isEdit ? "Edycja" : "Umawianie"}{" "}
                {isInstallationToBeReleased ? "montażu" : "pomiaru"}
              </div>
              <div className="blue">{orderDetails?.name}</div>
            </div>
          </div>
        </>
      )}
      <div className="calendar__user-select-form">
        <UserSelectForm
          setUsersTable={setUsersTable}
          usersTable={usersTable}
          setUser={setUser}
          orderDetails={orderDetails}
        />
      </div>
      {today && calendarEvents && (
        <Calendar
          user={user}
          date={date}
          events={calendarEvents}
          setDate={setDate}
          arrangingMeasurement={setIsModalOpen}
          assembly={isInstallationToBeReleased}
        />
      )}
      {isInstallationToBeReleased ? (
        <ArrangingAssemblyPanel
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          user={user}
          setDate={setDate}
          calendarEvents={calendarEvents}
          date={date}
        />
      ) : (
        <ArrangingMeasurementPanel
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          user={user}
          setDate={setDate}
          calendarEvents={
            calendarEventsWithoutEditing
              ? calendarEventsWithoutEditing
              : calendarEvents
          }
          date={date}
        />
      )}
    </div>
  );
};

export default ArrangingMeasurement;
