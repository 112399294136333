import MEASUREMENTS_TYPE  from "enums/measurementsTypes";

 const MEASUREMENTS_TYPE_TRANSLATIONS: { [key in MEASUREMENTS_TYPE]: string } = {
  [MEASUREMENTS_TYPE.WINDOWS_IN_ROW]: "Okna stan surowy",
  [MEASUREMENTS_TYPE.WINDOWS_REPLACEMENT]: "Okna wymiana",
  [MEASUREMENTS_TYPE.EXTERNAL_DOORS_IN_ROW]: "Drzwi zewnętrzne stan surowy",
  [MEASUREMENTS_TYPE.EXTERNAL_DOORS_REPLACEMENT]: "Drzwi zewnętrzne wymiana",
  [MEASUREMENTS_TYPE.GARAGE_DOOR]: "Brama garażowa",
  [MEASUREMENTS_TYPE.ROLLER_BLINDS_AND_BLINDS]: "Rolety i żaluzje",
  [MEASUREMENTS_TYPE.WINDOW_SILLS]: "Parapety",
  [MEASUREMENTS_TYPE.INTERIOR_DOORS]: "Drzwi wewnętrzne",
  [MEASUREMENTS_TYPE.FLOORS]: "Dodłogi",
  [MEASUREMENTS_TYPE.SKIRTING_BOARDS]: "listwy",
};


export default MEASUREMENTS_TYPE_TRANSLATIONS;