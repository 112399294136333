import { useState } from "react";
import { Calendar as CalendarComponent } from "react-big-calendar";
import { CalendarEventModal } from "components/Calendars";
import { useCalendarState, View } from "../utils";
import { isMobile } from "utils";
import { USER_ROLE } from "enums";
import moment from "moment";
import "./mobile-calendar-sheet.scss";
import classNames from "classnames";

interface MobileCalendarSheetType {
  date?: string;
  events?: any;
  setDate?: (date: string) => void;
  main: boolean;
  calendarView: View;
  arrangingMeasurement?: (isOpen: boolean) => void;
  user: any;
}

const MobileCalendarSheet = ({
  date = moment().format("YYYY-MM-DD"),
  events,
  setDate,
  arrangingMeasurement,
  main = false,
  calendarView,
  user,
}: MobileCalendarSheetType) => {
  const [eventOpened, setEventOpen] = useState({ isOpened: false, data: {} });
  const { minTime, maxTime, formats, localizer, DayColumnWrapper } =
    useCalendarState(date, setDate, main, arrangingMeasurement);
  const getFormattedDate = (dateString: string) => {
    const dateObject = moment(dateString, "YYYY-MM-DD").locale("pl");
    return dateObject.format("D MMMM, dddd");
  };

  const calendarClass = classNames("calendar-mobile", {
    "calendar-mobile--day": calendarView === "day",
    "calendar-mobile--week": calendarView === "week",
    "calendar-mobile--month": calendarView === "month",
  });

  const CustomToolbar = (toolbar: any) => {
    return (
      <>
        {date && calendarView === "month" && (
          <div className="calendar-mobile__active-day">
            {getFormattedDate(date)}
          </div>
        )}
      </>
    );
  };

  const eventStyleGetter = (event: any) => {
    const eventColor = event.original.userColor;
    const userRoleColor =
      user?.role === USER_ROLE.measurer
        ? "rgb(165, 236, 203)"
        : USER_ROLE.fitter
        ? "rgb(255, 204, 204)"
        : null;
    return {
      className: "calendar-sheet-event--measurer calendar-event--measurer",
      style: {
        backgroundColor: eventColor || userRoleColor,
      },
    };
  };

  const handleClickEvent = (event: any) => {
    setEventOpen({ isOpened: true, data: event });
  };

  return (
    <>
      {isMobile() && (
        <>
          <CalendarEventModal
            isOpen={eventOpened.isOpened}
            data={eventOpened.data}
            onClose={() => setEventOpen({ isOpened: false, data: {} })}
          />
          <CalendarComponent
            className={calendarClass}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            view={calendarView === "week" ? "week" : "day"}
            min={minTime}
            date={moment(date, "YYYY-MM-DD").toDate()}
            step={60}
            timeslots={1}
            max={maxTime}
            formats={formats}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleClickEvent}
            components={{
              toolbar: CustomToolbar,
              dayColumnWrapper: DayColumnWrapper,
            }}
          />
        </>
      )}
    </>
  );
};

export default MobileCalendarSheet;
