import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig"; 

export default function useGetAssemblyMaterialHistory(uuid: string,isOpen: boolean) {
  return useQuery({
    queryKey: ["assemblyMaterialsHistory", uuid],
    queryFn: async () => {
      return axiosInstance
        .get(`/assembly-materials/history/${uuid}`)
        .then((res) => res.data);
    },
    select: (data: { assemblyMaterial: any}) => data?.assemblyMaterial, 
    retry: false,
    enabled: !!uuid && isOpen,
  });
}

