import "./floating-menu.scss";

interface FloatingMenuType {
  setActiveFloatingMenuItem: (data: { label: string; value: string }) => void;
  activeFloatingMenuItem: { label: string; value: string };
  items: any;
  onClick?: (data: any) => void;
}
const FloatingMenu = ({
  setActiveFloatingMenuItem,
  activeFloatingMenuItem,
  items,
  onClick,
}: FloatingMenuType) => {
  const handleItemClick = async (item: any) => {
    await setActiveFloatingMenuItem(item);
    onClick && onClick(item);
  };

  return (
    <div className="floating-menu">
      {items.map((item: { label: string; value: string }, index: number) => {
        const activeElement = activeFloatingMenuItem?.value === item.value;
        return (
          <div
            key={index}
            className={`floating-menu-item ${
              activeElement ? "floating-menu-item--active" : ""
            }`}
            onClick={() => handleItemClick(item)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default FloatingMenu;
