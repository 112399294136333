import { useState } from "react";
import "./scheduler-calendar.scss";
import { Calendar, Select, MultipleSelect } from "components";
import moment from "moment";
import { Field, Formik } from "formik";
import { USER_ROLE } from "enums";
import { useGetFitterAndMeasurer } from "hooks/users";
import { useGetEmployeeSchedule } from "hooks/orders";
import { convertToEvents } from "utils";

const SchedulerCalendar = () => {
  const today = moment().format("YYYY-MM-DD");
  const [usersTable, setUsersTable] = useState<any>(["all"]);
  const [userRole, setUserRole] = useState("");
  const [date, setDate] = useState(today);
  const month = parseInt(date.split("-")[1]) || 5;

  const { data: employee } = useGetFitterAndMeasurer(
    userRole === USER_ROLE.fitter ? true : false
  );
  const { data } = useGetEmployeeSchedule(usersTable, month, userRole);

  const usersSelect = employee && [
    { label: "Wszyscy pracownicy", value: "all" },
    ...employee,
  ];

  const roleSelect = [
    {
      label: "Wszystkie zlecenia",
      value: "all",
    },
    {
      label: "Pomiary",
      value: USER_ROLE.measurer,
    },
    {
      label: "Montaże",
      value: USER_ROLE.fitter,
    },
  ];

  const handleChangeRole = (
    e: { label: string; value: string },
    setFieldValue: any
  ) => {
    setUsersTable(["all"]);
    setFieldValue("userUuid", "all");
    setUserRole(e.value === "all" ? "" : e.value);
  };
  const handleChangeUser = (e: Array<{ label: string; value: string }>) => {
    const usersTable = e.map((user: any) => user.value);
    setUsersTable(usersTable);
  };

  return (
    <div className="scheduler-calendar">
      {usersSelect && employee && (
        <Formik
          initialValues={{
            role: userRole ? userRole : roleSelect[0].value,
            userUuid: usersSelect ? usersSelect[0].value : "",
          }}
          enableReinitialize
          onSubmit={(values) => console.log(values)}
        >
          {({ handleSubmit, errors, setFieldValue }) => (
            <form
              className="scheduler-calendar__selects"
              onSubmit={handleSubmit}
            >
              <Field
                errors={errors}
                label="Zlecenia"
                name="role"
                component={Select}
                options={roleSelect}
                onChange={(e: any) => handleChangeRole(e, setFieldValue)}
                size="medium"
                disableMobileKeyboard
                checker
                white
              />
              <Field
                errors={errors}
                label="Pracownicy"
                name="userUuid"
                component={MultipleSelect}
                onChange={(e: any) => handleChangeUser(e)}
                options={usersSelect}
                size="medium"
                employee
                selectAll
                multiple
                white
              />
            </form>
          )}
        </Formik>
      )}
      <Calendar
        events={data && convertToEvents(data)}
        date={date}
        setDate={setDate}
        main
        size="100%"
      />
    </div>
  );
};

export default SchedulerCalendar;
