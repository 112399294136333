export const base64ToFile = (base64: string, filename: string, mimeType: string) => {
  let byteString;
  if (base64.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(base64.split(",")[1]);
  else byteString = unescape(base64.split(",")[1]);

  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ab], filename, { type: mimeType || mimeString });
};