import "./checklist-preview.scss";
import { Modal, SVG, Button } from "components";
import { SVG_TYPE } from "enums";
import { useGetChecklist } from "hooks/checklist";

interface ChecklistPreviewType {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  isMeasurer: boolean;
  page: number;
  setEditChecklist: () => void;
}

const OrderPreview = ({
  isOpen,
  onClose,
  data,
  isMeasurer,
  page,
  setEditChecklist,
}: ChecklistPreviewType) => {
  const { data: checklist } = useGetChecklist(data.value, isOpen);

  return (
    <>
      <Modal
        side="right"
        className="order-preview checklist-preview"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <>
            <div className="modal-heading__with-close">
              <div className="modal-heading">Podgląd checklisty</div>
              <div
                className="modal-heading__close order-preview__heading"
                onClick={() => onClose()}
              >
                <span>Zamknij</span>
                <SVG type={SVG_TYPE.CLOSE} />
              </div>
            </div>
            <div className="order-preview__label">{data.label}</div>
            <div className="modal__separator" />
            {checklist && checklist.length > 0 ? (
              checklist.map((item: any, index: number) => {
                const itemIndex = index + 1;
                return (
                  <div className="checklist-preview__item">
                    <div className="checklist-preview__number">
                      {itemIndex}.
                    </div>
                    <div className="checklist-preview__label">{item.text}</div>
                    {item.required ? (
                      <div className="checklist-preview__required">
                        Wymagane
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            ) : (
              <div className="checklist-preview__empty">
                Nie dodano jeszcze żadnych punktów do tej listy.
              </div>
            )}

            <Button
              className="order-preview__measurer-button checklist-preview__btn"
              label="Edytuj"
              onClick={() => setEditChecklist()}
            />
          </>
        </>
      </Modal>
    </>
  );
};

export default OrderPreview;
