import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type SupplierLabel = {
  label: string,
  value: string,
}

export default function useGetSuppliersLabel(options = {}) {
  return useQuery({
    queryKey: ["suppliersLabel"],
    queryFn: async () => {
      return axiosInstance
        .get("/suppliers/label")
        .then((res) => res.data);
    },
    select: (data: { suppliersLabel: SupplierLabel[] }) => data.suppliersLabel,
    retry: false,
    ...options,
  });
}
