/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { FloatingMenu } from "components";
import { EmployeeRatesTable } from "features/employeeRates";
import { USER_ROLE } from "enums";
import { RateInfoType } from "types/EmployeeRatesTypes";
import { EditEmployeeRate, UserHistoryRate } from "features/employeeRates";
import { useSearchParams } from "react-router-dom";
import { useGetEmployeeRateHistory } from "hooks/employee-rates";

const SubMenuItems = [
  {
    label: "Wszyscy pracownicy",
    value: "",
  },
  {
    label: "Grafikowiec",
    value: USER_ROLE.scheduleManager,
  },
  {
    label: "Monter",
    value: USER_ROLE.fitter,
  },
  {
    label: "Pomiarowiec",
    value: USER_ROLE.measurer,
  },
  {
    label: "Doradca",
    value: USER_ROLE.advisor,
  },
];

const EmployeeRates = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const linkPage = searchParams.get("page");
  const rolePage = searchParams.get("tab");
  const [page, setPage] = useState(Number(linkPage) || 1);
  const activeSubMenu =
    SubMenuItems.find((item) => item.value === rolePage) || SubMenuItems[0];
  const [modalManage, setModalManage] = useState<any>({
    type: "",
    userRateInfo: {},
  });
  const { data: userRates, refetch } = useGetEmployeeRateHistory(
    modalManage.userRateInfo.uuid
  );

  const [activeFloatingMenuItem, setActiveFloatingMenuItem] =
    useState(activeSubMenu);

  const isEditUserRate = modalManage.type === "editUserRate";
  const isShowRateHistory = modalManage.type === "rateHistory";
  const setEditUserRate = (userRateInfo: RateInfoType) =>
    setModalManage({
      type: "editUserRate",
      userRateInfo,
    });

  useEffect(() => {
    if (isShowRateHistory || isEditUserRate) {
      refetch();
    }
  }, [isShowRateHistory, isEditUserRate]);

  const setShowRateHistory = (userRateInfo: RateInfoType) =>
    setModalManage({
      type: "rateHistory",
      userRateInfo,
    });

  const clearModalManage = () => {
    setModalManage({ type: "", userRateInfo: modalManage.userRateInfo });
  };

  const handleChangeTab = (item: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", "1");
    newSearchParams.set("tab", item.value);
    setPage(1);
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <UserHistoryRate
        userRates={userRates}
        user={modalManage.userRateInfo}
        isOpen={isShowRateHistory}
        onClose={() => clearModalManage()}
      />
      <EditEmployeeRate
        userRates={userRates}
        user={modalManage.userRateInfo}
        isOpen={isEditUserRate}
        onClose={() => clearModalManage()}
      />
      <div className="dashboard-tab-name-with-button dashboard-tab-name-with-button--only-mobile">
        <div className="dashboard-tab-name">Stawki pracownicze</div>
      </div>
      <div>
        <div className="floating-menu-with-button">
          <FloatingMenu
            items={SubMenuItems}
            activeFloatingMenuItem={activeFloatingMenuItem}
            setActiveFloatingMenuItem={setActiveFloatingMenuItem}
            onClick={(item) => handleChangeTab(item)}
          />
        </div>
      </div>
      <EmployeeRatesTable
        setPage={setPage}
        page={page}
        userRole={activeFloatingMenuItem.value}
        setShowRateHistory={setShowRateHistory}
        setEditUserRate={setEditUserRate}
      />
    </>
  );
};

export default EmployeeRates;
