import "./order-preview.scss";
import { Modal, OrderStatus, SVG, FileItem, Button } from "components";
import { ROUTE_ENUM, SVG_TYPE } from "enums";
import { useNavigate } from "react-router";
import { useGetOrderDetails } from "hooks/orders";
import {
  OrderListType,
  measurementAllInstallationType,
  MEASUREMENTS_TYPE,
} from "types/OrderTypes";

interface OrderPreviewType {
  isOpen: boolean;
  onClose: () => void;
  order: OrderListType;
  isMeasurer: boolean;
  page: number;
  activeFloatingMenuItem: any;
}

const OrderPreview = ({
  isOpen,
  onClose,
  order,
  isMeasurer,
  page,
  activeFloatingMenuItem,
}: OrderPreviewType) => {
  const navigate = useNavigate();

  const { data: orderDetails, isLoading } = useGetOrderDetails(
    order.uuid,
    isOpen
  );

  const handleClickAppointment = (edit = "") => {
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${order.uuid}&tab=${
        activeFloatingMenuItem.value
      }${edit ? `&edit=true` : ""}`
    );
  };

  const handleClickMeasurement = () => {
    navigate(
      `${ROUTE_ENUM.ACTIVE_ORDER}?page=${page}&orderUuid=${order.uuid}&tab=${activeFloatingMenuItem.value}`
    );
  };
  const isInstallationToBeReleased =
    orderDetails?.status === "installationToBeReleased";
  const isMeasurementDate = orderDetails?.status !== "measurementsToBeReleased";
  const isStatusMeasurementToBeReleased =
    orderDetails?.status === "measurementsToBeReleased";
  const isStatusFixedMeasurement = orderDetails?.status === "fixedMeasurements";
  const measurementsAllInstallation = orderDetails?.measurementAllInstallation;
  const measurementArrangedInstallation =
    orderDetails?.measurementArrangedInstallation;

  return (
    <>
      {!isLoading && (
        <Modal
          side="right"
          className="order-preview"
          isOpened={isOpen}
          onClose={() => onClose()}
        >
          <>
            {orderDetails && (
              <>
                <div className="modal-heading__with-close">
                  <div className="modal-heading">Podgląd zlecenia</div>
                  <OrderStatus
                    className="order-preview__status-desktop"
                    status={orderDetails.status}
                  />
                  <div
                    className="modal-heading__close order-preview__heading"
                    onClick={() => onClose()}
                  >
                    <span>Zamknij</span>
                    <SVG type={SVG_TYPE.CLOSE} />
                  </div>
                </div>
                <div className="order-preview__status-mobile">
                  <OrderStatus status={orderDetails.status} />
                </div>
                <div className="order-preview__label">{orderDetails.name}</div>
                <div className="modal__separator" />
                <div className="modal__informations">
                  {isStatusFixedMeasurement && (
                    <Button
                      className="order-preview__edit-button"
                      size="small"
                      label="Edycja"
                      onClick={() => handleClickAppointment()}
                    />
                  )}
                  <div className="modal__information-header">
                    Imię i nazwisko klienta
                  </div>
                  <div className="modal__information-descript">
                    {orderDetails.clientName}
                  </div>
                  <div className="modal__information-header">Telefon</div>
                  <div className="modal__information-descript">
                    {orderDetails.phoneNumber}
                  </div>
                  <div className="modal__information-header">Adres Email</div>
                  <div className="modal__information-descript">
                    {orderDetails.email}
                  </div>
                  <div className="modal__information-header">Adres pomiaru</div>
                  <div className="modal__information-descript">
                    {orderDetails.address}
                  </div>
                  {isMeasurementDate && (
                    <>
                      <div className="modal__information-header">
                        Termin pomiaru
                      </div>
                      <div className="modal__information-descript">
                        {orderDetails.date}, godz. {orderDetails.start}-
                        {orderDetails.end}
                      </div>
                    </>
                  )}
                  {!isMeasurer && (
                    <>
                      <div className="modal__information-header">
                        Przypisany{" "}
                        {isInstallationToBeReleased ? "monter" : "pomiarowiec"}
                        {isStatusMeasurementToBeReleased && (
                          <Button
                            size="small"
                            label="Umów"
                            onClick={() => handleClickAppointment()}
                          />
                        )}
                      </div>
                      <div className="modal__information-descript">
                        {orderDetails.employee && isStatusFixedMeasurement ? (
                          <>
                            {orderDetails.employee?.firstName}{" "}
                            {orderDetails.employee?.lastName}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </>
                  )}
                  <div className="modal__information-header">
                    {isInstallationToBeReleased
                      ? "Uwagi dla montera"
                      : "Uwagi dla pomiarowca"}
                  </div>

                  <div className="modal__information-descript">
                    {orderDetails?.comments || "-"}
                  </div>

                  {isInstallationToBeReleased &&
                    measurementsAllInstallation &&
                    measurementsAllInstallation.length > 0 && (
                      <>
                        <div className="am-separator" />
                        <div className="modal__information-header">
                          Montaże do ustalenia
                          <Button
                            size="small"
                            label="Umów"
                            onClick={() => handleClickAppointment()}
                          />
                        </div>
                        {measurementsAllInstallation?.length > 0 &&
                          measurementsAllInstallation.map(
                            (measurement: measurementAllInstallationType) => (
                              <div className="order-preview__installations">
                                <FileItem
                                  name={measurement.typeOfMeasurement}
                                />
                                <div className="order-preview__installation">
                                  <div className="order-preview__installation-label">
                                    Planowany czas montażu:
                                  </div>
                                  <div className="order-preview__installation-value">
                                    {measurement.plannedAssemblyTime}h
                                  </div>
                                </div>
                                <div className="order-preview__installation">
                                  <div className="order-preview__installation-label">
                                    Potrzebna ilość pracowników:
                                  </div>
                                  <div className="order-preview__installation-value">
                                    {measurement.numberOfAssemblyTeams}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </>
                    )}

                  {!isMeasurer && (
                    <>
                      {isInstallationToBeReleased && (
                        <>
                          {measurementArrangedInstallation &&
                            measurementArrangedInstallation.length > 0 && (
                              <>
                                <div className="am-separator" />
                                <div className="modal__information-header">
                                  Montaże ustalone
                                  <Button
                                    size="small"
                                    label="Edytuj"
                                    onClick={() =>
                                      handleClickAppointment("edit")
                                    }
                                  />
                                </div>
                              </>
                            )}
                          {measurementArrangedInstallation &&
                            measurementArrangedInstallation.length > 0 &&
                            measurementArrangedInstallation.map(
                              (measurement: any) => {
                                return (
                                  <div className="order-preview__installations">
                                    <FileItem
                                      name={measurement.typeOfMeasurement}
                                    />
                                    <div className="order-preview__installation order-preview__installation-arranged">
                                      {measurement.dates.map(
                                        (arranged: any) => {
                                          return (
                                            <>
                                              {arranged.team.map(
                                                (team: any, index: number) => {
                                                  return (
                                                    <>
                                                      <div className="order-preview__installation">
                                                        <div className="order-preview__installation-label">
                                                          Termin:
                                                        </div>
                                                        <div className="order-preview__installation-value">
                                                          {arranged.date},{" "}
                                                          {team.start} -{" "}
                                                          {team.end}
                                                        </div>
                                                      </div>
                                                      <div className="order-preview__installation">
                                                        <div className="order-preview__installation-label">
                                                          Przypisani pracownicy
                                                        </div>
                                                        <div className="order-preview__installation-value">
                                                          {team.employeeUuids
                                                            .length > 0 &&
                                                            team.employeeUuids.map(
                                                              (
                                                                employee: any,
                                                                index: number
                                                              ) => {
                                                                const lastElement =
                                                                  team
                                                                    .employeeUuids
                                                                    .length ===
                                                                  index + 1;

                                                                return (
                                                                  <>
                                                                    {`${
                                                                      employee.firstName
                                                                    } ${
                                                                      employee.lastName
                                                                    }${
                                                                      lastElement
                                                                        ? ""
                                                                        : ","
                                                                    } `}
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                        </div>
                                                      </div>
                                                      <div className="am-separator" />
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </>
                      )}
                      <div className="am-separator" />
                      <div className="modal__information-header">Oferta</div>
                      <div className="order-preview__information-file">
                        {orderDetails?.files.length > 0 &&
                          orderDetails?.files.map((item) => {
                            return (
                              <>
                                <FileItem
                                  name={item.name as MEASUREMENTS_TYPE}
                                />
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                  {isMeasurer && (
                    <Button
                      className="order-preview__measurer-button"
                      label="Wykonaj"
                      onClick={() => handleClickMeasurement()}
                    />
                  )}
                </div>
              </>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default OrderPreview;
