import { useSearchParams } from "react-router-dom";
import {
  FloorGeneralInformation,
  FloorMeasurementValues,
  FloorInstallationInformation,
} from "./components";

interface FloorFormType {
  setStep: (page: number) => void;
  step: number;
  measurementInfo: any;
  prevStep: () => void;
}

const FloorForm = ({
  step,
  setStep,
  measurementInfo,
  prevStep,
}: FloorFormType) => {
  const [searchParams] = useSearchParams();
  const measurementUuid = searchParams.get("measurementUuid");

  const handleShowForm = (step: number, measurementUuid: string | null) => {
    switch (step) {
      case 2:
        return (
          <FloorGeneralInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <FloorInstallationInformation
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <FloorMeasurementValues
            setStep={setStep}
            step={step}
            measurementInfo={measurementInfo}
            measurementUuid={measurementUuid}
            prevStep={prevStep}
          />
        );
      default:
        return <p>default</p>;
    }
  };

  return handleShowForm(step, measurementUuid);
};

export default FloorForm;
