import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type FloorsClientInfoData = {
  measurementUuid: string;
  flooLayingPattern: 'onThree' | 'mixed' | 'symmetrical' | 'herringbone';
  finishingWorks: 'completed' | 'inProgress';
  comments?: string;
};

const usePutFloorClientInfo = (
  options?: UseMutationOptions<any, Error, FloorsClientInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editFloorClientInfo'],
    mutationFn: async (data) => {
      return axiosInstance
        .put('/measurement/floors/client-info', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie danych podłogi...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-floors-client-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dane podłogi zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-floors-client-info',
      });
      queryClient.invalidateQueries({ queryKey: ['floorsClientInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji danych podłogi.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-floors-client-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutFloorClientInfo;
