import {
  LoginByTel,
  LoginSmsCode,
  SetAdminPassword,
  ResetPassword,
  Login,
  ForgetPassword,
  SendRestartPassword,
  PasswordLinkExpired,
  ActivateLinkExpired,
  PasswordHasBeenChanged,
  Users,
  EmployeeRates,
  PasswordHasBeenCreated,
  AssemblyMaterials,
  Orders,
  CalendarPage,
  ArrangingMeasurement,
  ActiveOrder,
  AddingMeasurement,
  SchedulerCalendar,
  Statistics,
} from "pages";
import { ROUTE_ENUM } from "enums";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "utils";

const {
  HOME,
  LOGIN_SMS_CODE,
  LOGIN,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  SEND_LINK_RESTART_PASSWORD,
  PASSWORD_LINK_EXPIRED,
  ACTIVATE_LINK_EXPIRED,
  PASSWORD_HAS_BEEN_CHANGED,
  PASSWORD_HAS_BEEN_CREATED,
  SET_ADMIN_PASSWORD,
  DASHBOARD,
  CHECK_TOKEN_ACTIVATE,
  ACTIVE_ORDER,
  ORDERS_LIST,
  CALENDAR,
  ARRANGING_MEASUREMENT,
  USERS,
  EMPLOYEE_RATES,
  ASSEMBLY_MATERIAL,
  ADDING_MEASUREMENT,
  SCHEDULE_MANAGER_CALENDAR,
  DASHBOARD_STATISTICS,
} = ROUTE_ENUM;

const RouterComponent = () => {
  return (
    <Routes>
      {/* USER LOGIN */}
      <Route path={HOME} element={<LoginByTel />} />
      <Route path={LOGIN_SMS_CODE} element={<LoginSmsCode />} />
      {/* ADMIN LOGIN */}
      <Route path={LOGIN} element={<Login />} />
      <Route path={FORGET_PASSWORD} element={<ForgetPassword />} />
      <Route path={RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={CHECK_TOKEN_ACTIVATE} element={<SetAdminPassword />} />
      <Route
        path={SEND_LINK_RESTART_PASSWORD}
        element={<SendRestartPassword />}
      />
      <Route path={PASSWORD_LINK_EXPIRED} element={<PasswordLinkExpired />} />
      <Route path={ACTIVATE_LINK_EXPIRED} element={<ActivateLinkExpired />} />
      <Route
        path={PASSWORD_HAS_BEEN_CHANGED}
        element={<PasswordHasBeenChanged />}
      />{" "}
      <Route
        path={PASSWORD_HAS_BEEN_CREATED}
        element={<PasswordHasBeenCreated />}
      />
      <Route path={SET_ADMIN_PASSWORD} element={<SetAdminPassword />} />
      {/* DASHBOARD */}
      <Route path={`${DASHBOARD}/*`} element={<ProtectedRoute />}>
        <Route
          path={ORDERS_LIST.replace(`${DASHBOARD}/`, "")}
          element={<Orders />}
        />
        <Route
          path={CALENDAR.replace(`${DASHBOARD}/`, "")}
          element={<CalendarPage />}
        />
        <Route
          path={SCHEDULE_MANAGER_CALENDAR.replace(`${DASHBOARD}/`, "")}
          element={<SchedulerCalendar />}
        />
        <Route
          path={ARRANGING_MEASUREMENT.replace(`${DASHBOARD}/`, "")}
          element={<ArrangingMeasurement />}
        />
        <Route
          path={ACTIVE_ORDER.replace(`${DASHBOARD}/`, "")}
          element={<ActiveOrder />}
        />
        <Route
          path={ADDING_MEASUREMENT.replace(`${DASHBOARD}/`, "")}
          element={<AddingMeasurement />}
        />
      </Route>
      <Route path={`${DASHBOARD}/*`} element={<ProtectedRoute isAdminOnly />}>
        <Route
          path={DASHBOARD_STATISTICS.replace(`${DASHBOARD}/`, "")}
          element={<Statistics />}
        />
        <Route path={USERS.replace(`${DASHBOARD}/`, "")} element={<Users />} />
        <Route
          path={EMPLOYEE_RATES.replace(`${DASHBOARD}/`, "")}
          element={<EmployeeRates />}
        />
        <Route
          path={ASSEMBLY_MATERIAL.replace(`${DASHBOARD}/`, "")}
          element={<AssemblyMaterials />}
        />
      </Route>
    </Routes>
  );
};

export default RouterComponent;
