import { SVG } from "components";
import "./static-alert.scss";
import classNames from "classnames";
import { SVG_TYPE } from "enums";

interface StaticAlertProps {
  label: string;
  type?: string;
  className?: string;
  show?: any;
  size?: string;
}

const StaticAlert = ({
  show,
  label,
  type,
  className,
  size = "normal",
}: StaticAlertProps) => {
  const staticAlertClass = classNames("static-alert", className, {
    "static-alert--warning": !type,
    "static-alert--success": type === "success",
    "static-alert--show": show,
    "static-alert--small": show && size === "small",
  });

  return (
    <div className={staticAlertClass}>
      <div className="static-alert__content">{label}</div>
      <div className="static-alert__icon">
        <SVG type={SVG_TYPE.ALERT} />
      </div>
    </div>
  );
};

export default StaticAlert;
