import { SVG_TYPE, USER_ROLE } from "enums";
import "./dashboard-top-bar.scss";
import { useLogout } from "hooks/auth/";
import { ContextMenu, SVG } from "components";
import { useState } from "react";
import { ChangePassword } from "features/users";

interface DashboardTopBarType {
  user: any;
}

const DashboardTopBar = ({ user }: DashboardTopBarType) => {
  const { mutate: logout } = useLogout({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin = user?.role === USER_ROLE.admin;

  const adminSubmenuItems = () => {
    return [
      {
        name: "Zmień hasło",
        action: () => {
          setIsModalOpen(true);
        },
      },
      {
        name: "Wyloguj się",
        action: () => {
          logout();
        },
      },
    ];
  };

  return (
    <>
      <ChangePassword
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div className="dtb__wrapper">
        <div className="dtb">
          <SVG className="dtb__logo" type={SVG_TYPE.LOGO} />
          {isAdmin ? (
            <div className="dtb__user">
              <div className="dtb__user-label">
                <ContextMenu
                  className="dtb__user-submenu"
                  items={adminSubmenuItems()}
                >
                  {user.firstName} {user.lastName}{" "}
                  <SVG type={SVG_TYPE.CHEVRON_DOWN} />
                </ContextMenu>
              </div>
            </div>
          ) : (
            <div onClick={() => logout()} className="dtb__logout-wrapper">
              <SVG type={SVG_TYPE.LOGOUT} />
              <span>Wyloguj się</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardTopBar;
