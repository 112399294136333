import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { Toast } from "components";
import { TOAST_TYPES_ENUM } from "enums";
import { queryClient } from "App";

const useResendActivationLink = (
  options?: UseMutationOptions<any, Error, { email: any }>
) => {
  return useMutation({
    ...options,
    mutationKey: ["resendActivationLink"],
    mutationFn: async ({ email }: { email: string }) => {
      return axiosInstance
        .post("/users/activate", { email })
        .then((res) => res.data);
    },
    onMutate: ({ email }) => {
      Toast({
        message: "Przetwarzanie żądania...",
        type: TOAST_TYPES_ENUM.LOADING,
        id: `resend-activation-${email}`,
      });
      if (options && options.onMutate) {
        return options.onMutate({ email });
      }
    },
    onSuccess: (data, { email }, context) => {
      Toast({
        message: "Link aktywacyjny został wysłany",
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: `resend-activation-${email}`,
      });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      if (options && options.onSuccess) {
        options.onSuccess(data, { email }, context);
      }
    },
    onError: (error, { email }, context) => {
      Toast({
        message: "Błąd podczas wysyłania linku aktywacyjnego",
        type: TOAST_TYPES_ENUM.ERROR,
        id: `resend-activation-${email}`,
      });
      if (options && options.onError) {
        options.onError(error, { email }, context);
      }
    },
  });
};

export default useResendActivationLink;
