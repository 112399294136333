import { ContextMenu, SVG } from "components";
import { SVG_TYPE } from "enums";
import { OrderStatus, UserBadge } from "components";

const items = (
  deleteActivity: (activity: any) => void,
  editActivity: (activity: any) => void
) => {
  return (activity: any) => {
    const actions = [
      {
        name: "Edytuj",
        action: () => editActivity(activity),
      },
      {
        name: "Usuń",
        action: () => deleteActivity(activity),
      },
    ];
    return actions;
  };
};

export const desktopColumns = (
  deleteActivity: (activity: any) => void,
  editActivity: (activity: any) => void
) => [
  {
    title: "Nazwa Czynności",
    dataIndex: "activities",
    key: "activities",
    width: 663,
    render: (activities: any) => activities.join(", "),
  },
  {
    title: "Etap",
    width: 480,
    render: (original: any) => {
      const isMeasurement = original.isMeasurement && "Pomiar";
      const isInstallation = original.isInstallation && "Montaż";
      return (
        <div className="order-status__orders">
          {isMeasurement ? <OrderStatus status={isMeasurement} /> : ""}
          {isInstallation ? <OrderStatus status={isInstallation} /> : ""}
        </div>
      );
    },
  },
  {
    title: "Akcje",
    width: 75,
    render: (item: any) => (
      <ContextMenu
        data={item}
        items={items(deleteActivity, editActivity)(item)}
      >
        <SVG type={SVG_TYPE.ELLIPSIS} />
      </ContextMenu>
    ),
  },
];

export const mobileColumns = (
  deleteActivity: (data: any) => void,
  editActivity: (activity: any) => void
) => [
  {
    dataIndex: "activities",
    key: "activities",
    className: "left-aligned-cell",
    width: 500,
    render: (activities: any) => activities.join(", "),
  },
  {
    width: 100,
    render: (original: any) => {
      const isMeasurement = original.isMeasurement && "Pomiar";
      const isInstallation = original.isInstallation && "Montaż";
      return (
        <div className="order-status__orders">
          {isMeasurement ? <UserBadge status={isMeasurement} /> : ""}
          {isInstallation ? <UserBadge status={isInstallation} /> : ""}
        </div>
      );
    },
  },
];
