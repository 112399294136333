import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { MetaType } from "types/UtilsTypes";

type SupplierType = {
  uuid: string;
  name: string;
};

export default function useGetSuppliers({ page }: any): UseQueryResult<{ data: SupplierType[], meta: MetaType }> {
  return useQuery({
    queryKey: ["suppliers", page],
    queryFn: async () => {
      const response = await axiosInstance.get("/suppliers", {
        params: {
          page,
        },
      });
      return response.data;
    },
    select: (data: { suppliers: { data: SupplierType[], meta: MetaType } }) => data.suppliers,
    retry: false,
  });
}
