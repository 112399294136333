import { useState, useEffect } from "react";
import {
  Page,
  PageContent,
  UserAccessPanel,
  Button,
  PasswordStrengthChecker,
  PasswordValidation,
  Input,
  StaticAlert,
} from "components";
import { Formik, Field } from "formik";
import { useCheckToken, useResetPassword } from "hooks/auth";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import "./reset-password.scss";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED),
    confirm_password: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED)
      .oneOf([Yup.ref("newPassword"), ""], VALIDATION_MESSAGES.PASSWORD_MATCH),
  });

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";

  const { mutate: mutateCheckToken } = useCheckToken({
    onSuccess: () => {},
    onError: (error: Error) => {
      navigate(ROUTE_ENUM.PASSWORD_LINK_EXPIRED);
    },
  });

  useEffect(() => {
    if (token) {
      mutateCheckToken({ token });
    }
  }, [mutateCheckToken, token]);

  const { mutate: mutateResetPassword } = useResetPassword({
    onError: (error: any) => {
      setTimeout(() => {
        if (error.data.messsages === `You can't set an old password`) {
          setShowError(true);
        }
      }, 500);
    },
  });

  const initialValues = { newPassword: "", token, confirm_password: "" };

  const handleSubmitForm = async (values: typeof initialValues) => {
    !showValidation &&
      mutateResetPassword({
        resetToken: token,
        newPassword: values.newPassword,
      });
  };

  return (
    <>
      <Page>
        <PageContent>
          <UserAccessPanel heading="Utwórz nowe hasło">
            <div className="uap__description set-admin-password__description"></div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              isInitialValid={false}
              validateOnBlur={false}
              enableReinitialize
              onSubmit={async (values) => handleSubmitForm(values)}
            >
              {({ handleSubmit, errors, isSubmitting, values }) => (
                <form
                  className="set-admin-password__form"
                  onSubmit={handleSubmit}
                >
                  <StaticAlert
                    show={showError}
                    label="Hasło nie może być takie samo jak obecne."
                  />
                  <Field
                    type="password"
                    label="Hasło"
                    name="newPassword"
                    component={Input}
                    wrapperClassName="white form__field--gray"
                    errors={errors}
                    errorBelow
                    inputOnFocus={() => setShowValidation(true)}
                    onBlur={() => isPasswordValid && setShowValidation(false)}
                  />
                  <PasswordStrengthChecker
                    isHidden={!showValidation}
                    password={values.newPassword}
                  />
                  <PasswordValidation
                    className={` ${
                      showValidation ? "validation-item__wrapper--show" : ""
                    }`}
                    password={values.newPassword}
                    setIsValid={(value: boolean) => setIsPasswordValid(value)}
                  />
                  <Field
                    type="password"
                    label="Powtórz hasło"
                    name="confirm_password"
                    component={Input}
                    errorBelow
                    errors={errors}
                    wrapperClassName="white form__field--gray"
                  />
                  <div className="uap__button">
                    <Button type="submit" label="Potwierdź" />
                  </div>
                </form>
              )}
            </Formik>
          </UserAccessPanel>
        </PageContent>
      </Page>
    </>
  );
};

export default ResetPassword;
