import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { UserType } from "types/UsersTypes";
import { MetaType } from "types/UtilsTypes";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetUsers(page: number, location: string) {
  return useQuery({
    queryKey: ["users", page, location], 
    queryFn: async () => {
      await delay(1000); 
      return axiosInstance
        .get(`/users?page=${page}&location=${location}`)
        .then((res) => res.data);
    },
    select: (data: { users: { data: UserType[]; meta: MetaType } }) =>
      data?.users,
    retry: false,
    staleTime: 0, 
  });
}
