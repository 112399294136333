import React from "react";
import animationDataBlue from "assets/files/loader-animation.json";
import animationDataWhite from "assets/files/loader-animation-white.json";
import Lottie from "react-lottie";

export default function Loader({ width, height, isWhite = false }: any) {
  const animationData = isWhite ? animationDataWhite : animationDataBlue;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const style = {
    width: width || "24px",
    height: height || "24px",
  };
  return (
    <div className="loader m-auto">
      <Lottie style={style} options={defaultOptions} />
    </div>
  );
}
