// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "assets/styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RouterComponent from "routes";
import "react-toastify/dist/ReactToastify.css";
import { PortalWithState } from "react-portal";

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RouterComponent />
      </BrowserRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <PortalWithState
        closeOnEsc={true}
        closeOnOutsideClick={false}
        defaultOpen={true}
      >
        {({ portal }) => (
          <div className="toast__wrapper">
            {portal(
              <ToastContainer
                stacked
                position="bottom-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            )}
          </div>
        )}
      </PortalWithState>
    </QueryClientProvider>
  );
}

export default App;
