import { Outlet } from "react-router-dom";
import "./dashboard-router.scss";
import { DesktopMenu, DashboardTopBar, MobileMenu } from "components";
import { useGetMe } from "hooks/auth";

const DashboardRouter = () => {
  const { data: authorizedUser } = useGetMe();

  return (
    <div className="dashboard">
      <DashboardTopBar user={authorizedUser} />
      <DesktopMenu user={authorizedUser} />
      <div className="dashboard-content__wrapper">
        <div className="dashboard-content">
          <Outlet />
        </div>
      </div>
      <MobileMenu user={authorizedUser} />
    </div>
  );
};

export default DashboardRouter;
