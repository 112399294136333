import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type DeleteActivityTimeData = {
  timeManagementUuid: string;
};

const useDeleteActivityTime = (
  options?: UseMutationOptions<any, Error, DeleteActivityTimeData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['deleteActivityTime'],
    mutationFn: async (data: DeleteActivityTimeData) => {
      return axiosInstance.delete(`/activity/time/delete`, { data }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Usuwanie czasu aktywności...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'delete-activity-time',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Czas aktywności został usunięty pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'delete-activity-time',
      });
      queryClient.invalidateQueries({ queryKey: ['timeManagement'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas usuwania czasu aktywności.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'delete-activity-time',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useDeleteActivityTime;
