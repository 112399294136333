/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Page,
  PageContent,
  UserAccessPanel,
  Button,
  Input,
  StaticAlert,
} from "components";
import { useLogin } from "hooks/auth";
import { useNavigate } from "react-router";
import { Field, Formik } from "formik";
import { ROUTE_ENUM, VALIDATION_MESSAGES, USER_ROLE } from "enums";
import { LoginTypes } from "types/AuthTypes";
import * as Yup from "yup";
import "./login.scss";
import { useGetMe } from "hooks/auth";

const Login = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const { data: authorizedUser, isLoading } = useGetMe();

  useEffect(() => {
    if (authorizedUser) {
      if (authorizedUser.role === USER_ROLE.admin) {
        navigate(ROUTE_ENUM.DASHBOARD_STATISTICS);
      } else {
        navigate(ROUTE_ENUM.ORDERS_LIST);
      }
    }
  }, [authorizedUser]);

  const { mutate } = useLogin({
    onSuccess: () => {
      sessionStorage.setItem("loginAdmin", JSON.stringify(true));
      navigate(ROUTE_ENUM.DASHBOARD_STATISTICS);
    },
    onError: (error: Error) => {
      setShowError(true);
    },
  });

  const handleOnSubmit = (values: LoginTypes) => {
    mutate(values);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .email(VALIDATION_MESSAGES.EMAIL),
    password: Yup.string()
      .min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH)
      .required(VALIDATION_MESSAGES.REQUIRED),
  });

  return (
    <>
      {!isLoading && !authorizedUser && (
        <Page>
          <PageContent>
            <UserAccessPanel heading="Zaloguj się">
              <div className="uap__description login__description">
                Logowanie do panelu administracyjnego
              </div>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={async (values) => handleOnSubmit(values)}
              >
                {({ handleSubmit, errors, isSubmitting }) => (
                  <form className="login__form" onSubmit={handleSubmit}>
                    <StaticAlert
                      show={showError}
                      label="Nieprawidłowe dane logowania"
                    />
                    <Field
                      errors={errors}
                      label="Adres email"
                      name="email"
                      component={Input}
                      onChange={() => setShowError(false)}
                    />
                    <div className="login__input-wrapper">
                      <div
                        className="login__paragraph-link"
                        onClick={() => navigate(ROUTE_ENUM.FORGET_PASSWORD)}
                      >
                        Nie pamiętasz hasła?
                      </div>
                      <Field
                        errors={errors}
                        label="Hasło"
                        name="password"
                        type="password"
                        onChange={() => setShowError(false)}
                        component={Input}
                      />
                    </div>
                    <div className="uap__button">
                      <Button type="submit" label="Zaloguj się" />
                    </div>
                  </form>
                )}
              </Formik>
            </UserAccessPanel>
          </PageContent>
        </Page>
      )}
    </>
  );
};

export default Login;
