import { useState, useEffect } from "react";
import { CheckboxPart, StaticAlert, Input } from "components";
import { useGetRooms } from "hooks/measurements";
import { Field } from "formik";
import "./room-list.scss";

interface RoomListType {
  field: any;
  errors?: any;
  form: any;
  values: any;
}

const RoomList = ({ errors, form, field, values }: RoomListType) => {
  const { data: rooms } = useGetRooms();
  const [roomsTable, setRoomsTable] = useState<any>(
    values?.roomListUuids || []
  );

  useEffect(() => {
    setRoomsTable(values?.roomListUuids);
  }, [values?.roomListUuids]);

  const handleClickCheckbox = (uuid: string) => {
    const isUuidInArray = roomsTable.find((item: any) => item.uuid === uuid);
    if (!isUuidInArray) {
      const values = [...roomsTable, { uuid: uuid, measurement: "" }];
      setRoomsTable(values);
      form.setFieldValue(field.name, values);
    } else {
      const values = roomsTable.filter((item: any) => item.uuid !== uuid);
      setRoomsTable(values);
      form.setFieldValue(field.name, values);
    }
  };

  const isUuidSelected = (uuid: string) => {
    return !!roomsTable.find((item: any) => item.uuid === uuid);
  };

  const handleMeasurementChange = (uuid: string, measurementValue: string) => {
    if (!/^\d*$/.test(measurementValue)) {
      return;
    }
    if (
      (measurementValue.length > 0 &&
        measurementValue.length > 1 &&
        measurementValue.startsWith("0")) ||
      measurementValue === "0"
    ) {
      return;
    }
    const updatedRooms = roomsTable.map((item: any) =>
      item.uuid === uuid ? { ...item, measurement: measurementValue } : item
    );
    setRoomsTable(updatedRooms);
    form.setFieldValue(field.name, updatedRooms);
  };

  return (
    <>
      <div>
        <div className="am-section-heading room-list__heading">
          Lista pomieszczeń
        </div>
        <div className="room-list">
          {rooms &&
            rooms.length > 0 &&
            rooms.map((room, index) => {
              const initialSelectedItem = values?.roomListUuids.find(
                (item: { uuid: string }) => item.uuid === room.value
              );
              return (
                <div key={room.value} className="room-list__item-element">
                  <div key={room.value} className="room-list__item">
                    <CheckboxPart
                      checked={values?.roomListUuids.find(
                        (item: { uuid: string }) => item.uuid === room.value
                      )}
                      key={room.value}
                      label={room.label}
                      uuid={room.value}
                      onChange={(uuid) => handleClickCheckbox(uuid)}
                    />
                  </div>
                  {isUuidSelected(room.value) && (
                    <Field
                      initialValue={initialSelectedItem?.measurement || ""}
                      errors={errors}
                      label="Wartość kondygnacji"
                      rightPlaceholder="mm"
                      type="number"
                      name={`drop_${index}`}
                      component={Input}
                      onChange={(e: any) =>
                        handleMeasurementChange(room.value, e.target.value)
                      }
                    />
                  )}
                </div>
              );
            })}
        </div>
        {errors && form.touched && (
          <>
            <StaticAlert
              className="am-alert"
              size="small"
              show={errors[field.name] && errors[field.name]}
              label="Te dane są obowiązkowe. Uzupełnij je, aby móc przejść dalej."
            />
          </>
        )}
      </div>
      <div className="am-separator" />
    </>
  );
};

export default RoomList;
