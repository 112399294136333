import { SVG_TYPE } from "enums";

interface SVGProps {
  type: string;
  className?: string;
  variant?: string;
}

const SVG = ({ type, className = "", variant }: SVGProps) => {
  const selectedVariant = variant ? variant : type;
  switch (selectedVariant) {
    case SVG_TYPE.USERS: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.CLOSE: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1654 6.33334L5.83203 14.6667M5.83203 6.33334L14.1654 14.6667"
            stroke="#6D7678"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.USER: {
      return (
        <svg
          className={`svg ${className}`}
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.49991 9.375C5.51861 9.375 3.75666 10.3316 2.6349 11.8162C2.39347 12.1358 2.27276 12.2955 2.27671 12.5114C2.27976 12.6782 2.38451 12.8887 2.51576 12.9917C2.68565 13.125 2.92107 13.125 3.39193 13.125H11.6079C12.0787 13.125 12.3142 13.125 12.4841 12.9917C12.6153 12.8887 12.7201 12.6782 12.7231 12.5114C12.7271 12.2955 12.6063 12.1358 12.3649 11.8162C11.2432 10.3316 9.4812 9.375 7.49991 9.375Z"
            stroke="#9BA7AA"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.49991 7.5C9.05321 7.5 10.3124 6.2408 10.3124 4.6875C10.3124 3.1342 9.05321 1.875 7.49991 1.875C5.94661 1.875 4.68741 3.1342 4.68741 4.6875C4.68741 6.2408 5.94661 7.5 7.49991 7.5Z"
            stroke="#9BA7AA"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.EXCLAMATION: {
      return (
        <svg
          className={`svg ${className}`}
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1813_2799)">
            <rect width="28" height="28" rx="14" fill="#F29225" />
            <path
              d="M15.6211 16.9609H12.3984L11.9004 7.72266H16.1191L15.6211 16.9609ZM11.9395 20.3789C11.9395 19.7865 12.1152 19.3275 12.4668 19.002C12.8184 18.6699 13.3294 18.5039 14 18.5039C14.6641 18.5039 15.1654 18.6699 15.5039 19.002C15.849 19.3275 16.0215 19.7865 16.0215 20.3789C16.0215 20.9648 15.8424 21.4238 15.4844 21.7559C15.1328 22.0814 14.638 22.2441 14 22.2441C13.3555 22.2441 12.8509 22.0814 12.4863 21.7559C12.1217 21.4238 11.9395 20.9648 11.9395 20.3789Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1813_2799">
              <rect width="28" height="28" rx="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case SVG_TYPE.EDIT_PENCIL: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="edit-02">
            <path
              id="Icon"
              d="M14.9999 8.33338L11.6666 5.00005M2.08325 17.9167L4.90356 17.6033C5.24813 17.5651 5.42042 17.5459 5.58146 17.4938C5.72433 17.4475 5.86029 17.3822 5.98566 17.2995C6.12696 17.2063 6.24954 17.0838 6.49469 16.8386L17.4999 5.83338C18.4204 4.91291 18.4204 3.42052 17.4999 2.50005C16.5795 1.57957 15.0871 1.57957 14.1666 2.50005L3.16136 13.5053C2.91621 13.7504 2.79363 13.873 2.70045 14.0143C2.61778 14.1397 2.55243 14.2756 2.50618 14.4185C2.45405 14.5795 2.43491 14.7518 2.39662 15.0964L2.08325 17.9167Z"
              stroke="#6D7678"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.CLOSE_CIRCLE: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="x">
            <path
              id="Icon"
              d="M14.1666 5.83337L5.83325 14.1667M5.83325 5.83337L14.1666 14.1667"
              stroke="#6D7678"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.BIN: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5"
            stroke="#6D7678"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.SEARCH: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
            stroke="#9BA7AA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.EDIT: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.87604 18.1157C2.92198 17.7022 2.94496 17.4955 3.00751 17.3022C3.06301 17.1308 3.14143 16.9676 3.24064 16.8172C3.35246 16.6476 3.49955 16.5005 3.79373 16.2063L17 3.00006C18.1046 1.89549 19.8955 1.89549 21 3.00006C22.1046 4.10463 22.1046 5.89549 21 7.00006L7.79373 20.2063C7.49955 20.5005 7.35245 20.6476 7.18289 20.7594C7.03245 20.8586 6.86929 20.937 6.69785 20.9925C6.5046 21.0551 6.29786 21.0781 5.88437 21.124L2.5 21.5001L2.87604 18.1157Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.ERASER: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9995 13L10.9995 6.00004M20.9995 21H7.99955M10.9368 20.0628L19.6054 11.3941C20.7935 10.2061 21.3875 9.61207 21.6101 8.92709C21.8058 8.32456 21.8058 7.67551 21.6101 7.07298C21.3875 6.388 20.7935 5.79397 19.6054 4.60592L19.3937 4.39415C18.2056 3.2061 17.6116 2.61207 16.9266 2.38951C16.3241 2.19373 15.675 2.19373 15.0725 2.38951C14.3875 2.61207 13.7935 3.2061 12.6054 4.39415L4.39366 12.6059C3.20561 13.794 2.61158 14.388 2.38902 15.073C2.19324 15.6755 2.19324 16.3246 2.38902 16.9271C2.61158 17.6121 3.20561 18.2061 4.39366 19.3941L5.06229 20.0628C5.40819 20.4087 5.58114 20.5816 5.78298 20.7053C5.96192 20.815 6.15701 20.8958 6.36108 20.9448C6.59126 21 6.83585 21 7.32503 21H8.67406C9.16324 21 9.40784 21 9.63801 20.9448C9.84208 20.8958 10.0372 20.815 10.2161 20.7053C10.418 20.5816 10.5909 20.4087 10.9368 20.0628Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.TRASH: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.TEXT: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 7C4 6.06812 4 5.60218 4.15224 5.23463C4.35523 4.74458 4.74458 4.35523 5.23463 4.15224C5.60218 4 6.06812 4 7 4H17C17.9319 4 18.3978 4 18.7654 4.15224C19.2554 4.35523 19.6448 4.74458 19.8478 5.23463C20 5.60218 20 6.06812 20 7M9 20H15M12 4V20"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.GO_BACK: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="12" fill="white" />
          <path
            d="M18.6663 12H5.33301M5.33301 12L10.333 17M5.33301 12L10.333 7"
            stroke="#096EB5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.PLAY: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.16699 4.1576C4.16699 3.3483 4.16699 2.94365 4.33573 2.72059C4.48274 2.52626 4.70743 2.40601 4.95066 2.39149C5.22986 2.37482 5.56655 2.59928 6.23993 3.0482L15.0029 8.89018C15.5593 9.26111 15.8375 9.44658 15.9344 9.68035C16.0192 9.88473 16.0192 10.1144 15.9344 10.3188C15.8375 10.5526 15.5593 10.738 15.0029 11.109L6.23992 16.951C5.56655 17.3999 5.22986 17.6243 4.95066 17.6077C4.70743 17.5931 4.48274 17.4729 4.33573 17.2786C4.16699 17.0555 4.16699 16.6509 4.16699 15.8416V4.1576Z"
            fill="#333738"
            stroke="#333738"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.PLUS: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 5V19M5 12H19"
            stroke="#333738"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.EQUAL: {
      return (
        <svg
          className={`svg ${className}`}
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.66602 9H19.666M5.66602 15H19.666"
            stroke="#6D7678"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.STOP: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="stop">
            <path
              id="Icon"
              d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
              fill="#096EB5"
              stroke="#096EB5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.CLOCK: {
      return (
        <svg
          className={`svg ${className}`}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99967 6.33398V9.00065L9.66634 10.0007M7.99967 3.33398C4.87006 3.33398 2.33301 5.87104 2.33301 9.00065C2.33301 12.1303 4.87006 14.6673 7.99967 14.6673C11.1293 14.6673 13.6663 12.1303 13.6663 9.00065C13.6663 5.87104 11.1293 3.33398 7.99967 3.33398ZM7.99967 3.33398V1.33398M6.66634 1.33398H9.33301M13.5523 3.72868L12.5523 2.72868L13.0523 3.22868M2.44702 3.72868L3.44702 2.72868L2.94702 3.22868"
            stroke="#9BA7AA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.CHEVRON_RIGHT: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 5L12.5 10L7.5 15"
            stroke="#4A5051"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.DOWNLOAD_FILE: {
      return (
        <svg
          className={`svg ${className}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66663 10.0008L9.99996 13.3342M9.99996 13.3342L13.3333 10.0008M9.99996 13.3342V5.66749C9.99996 4.50857 9.99996 3.92911 9.54119 3.28032C9.23638 2.84926 8.35878 2.31722 7.83561 2.24634C7.0482 2.13966 6.74918 2.29564 6.15115 2.60761C3.48607 3.99785 1.66663 6.78686 1.66663 10.0008C1.66663 14.6032 5.39759 18.3342 9.99996 18.3342C14.6023 18.3342 18.3333 14.6032 18.3333 10.0008C18.3333 6.91631 16.6575 4.22322 14.1666 2.78234"
            stroke="#6D7678"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.CALENDAR: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10H3M16 2V6M8 2V6M10.5 14L12 13V18M10.75 18H13.25M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.EMPLOYEE_RATES: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5295 8.35186C12.9571 8.75995 12.2566 9 11.5 9C9.567 9 8 7.433 8 5.5C8 3.567 9.567 2 11.5 2C12.753 2 13.8522 2.65842 14.4705 3.64814M6 20.0872H8.61029C8.95063 20.0872 9.28888 20.1277 9.61881 20.2086L12.3769 20.8789C12.9753 21.0247 13.5988 21.0388 14.2035 20.9214L17.253 20.3281C18.0585 20.1712 18.7996 19.7854 19.3803 19.2205L21.5379 17.1217C22.154 16.5234 22.154 15.5524 21.5379 14.9531C20.9832 14.4134 20.1047 14.3527 19.4771 14.8103L16.9626 16.6449C16.6025 16.9081 16.1643 17.0498 15.7137 17.0498H13.2855L14.8311 17.0498C15.7022 17.0498 16.4079 16.3633 16.4079 15.5159V15.2091C16.4079 14.5055 15.9156 13.892 15.2141 13.7219L12.8286 13.1417C12.4404 13.0476 12.0428 13 11.6431 13C10.6783 13 8.93189 13.7988 8.93189 13.7988L6 15.0249M20 6.5C20 8.433 18.433 10 16.5 10C14.567 10 13 8.433 13 6.5C13 4.567 14.567 3 16.5 3C18.433 3 20 4.567 20 6.5ZM2 14.6L2 20.4C2 20.9601 2 21.2401 2.10899 21.454C2.20487 21.6422 2.35785 21.7951 2.54601 21.891C2.75992 22 3.03995 22 3.6 22H4.4C4.96005 22 5.24008 22 5.45399 21.891C5.64215 21.7951 5.79513 21.6422 5.89101 21.454C6 21.2401 6 20.9601 6 20.4V14.6C6 14.0399 6 13.7599 5.89101 13.546C5.79513 13.3578 5.64215 13.2049 5.45399 13.109C5.24008 13 4.96005 13 4.4 13L3.6 13C3.03995 13 2.75992 13 2.54601 13.109C2.35785 13.2049 2.20487 13.3578 2.10899 13.546C2 13.7599 2 14.0399 2 14.6Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.ASSEMBLY_MATERIALS: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.DASHBOARD: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.4 3H4.6C4.03995 3 3.75992 3 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3 3.75992 3 4.03995 3 4.6V8.4C3 8.96005 3 9.24008 3.10899 9.45399C3.20487 9.64215 3.35785 9.79513 3.54601 9.89101C3.75992 10 4.03995 10 4.6 10H8.4C8.96005 10 9.24008 10 9.45399 9.89101C9.64215 9.79513 9.79513 9.64215 9.89101 9.45399C10 9.24008 10 8.96005 10 8.4V4.6C10 4.03995 10 3.75992 9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.4 3H15.6C15.0399 3 14.7599 3 14.546 3.10899C14.3578 3.20487 14.2049 3.35785 14.109 3.54601C14 3.75992 14 4.03995 14 4.6V8.4C14 8.96005 14 9.24008 14.109 9.45399C14.2049 9.64215 14.3578 9.79513 14.546 9.89101C14.7599 10 15.0399 10 15.6 10H19.4C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V4.6C21 4.03995 21 3.75992 20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.4 14H15.6C15.0399 14 14.7599 14 14.546 14.109C14.3578 14.2049 14.2049 14.3578 14.109 14.546C14 14.7599 14 15.0399 14 15.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V15.6C21 15.0399 21 14.7599 20.891 14.546C20.7951 14.3578 20.6422 14.2049 20.454 14.109C20.2401 14 19.9601 14 19.4 14Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.4 14H4.6C4.03995 14 3.75992 14 3.54601 14.109C3.35785 14.2049 3.20487 14.3578 3.10899 14.546C3 14.7599 3 15.0399 3 15.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V15.6C10 15.0399 10 14.7599 9.89101 14.546C9.79513 14.3578 9.64215 14.2049 9.45399 14.109C9.24008 14 8.96005 14 8.4 14Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.CHEVRON_LEFT: {
      return (
        <svg
          className={`svg ${className}`}
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.75 12.75L7.25 8.5L11.75 4.25"
            stroke="#333738"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.ARROW_BACK: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12H4M4 12L10 18M4 12L10 6"
            stroke="#096EB5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.ORDERS_LIST: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 12L9 12M21 6L9 6M21 18L9 18M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12ZM5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6ZM5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.LOGOUT: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 17L21 12M21 12L16 7M21 12H9M9 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9"
            stroke="#FAFAFA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.LOGO: {
      return (
        <svg
          className={`svg ${className}`}
          width="102"
          height="23"
          viewBox="0 0 102 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.4789 5.65869L0 11.1176V22.2856H5.48172V13.3838L11.1835 19.0662L15.0585 15.2015L5.4789 5.65869Z"
            fill="#0069B4"
          />
          <path
            d="M22.3566 11.1244L19.3082 8.08921V4.72222H15.9297L11.1942 0L7.31641 3.86325L16.8706 13.3878V22.2854H22.358L22.3566 11.1244Z"
            fill="#E73458"
          />
          <path
            d="M99.4375 14.428H99.3697L96.1352 18.3475L92.9119 14.428H92.8456L92.8385 22.3078H90.2754V14.1989V11.0906H93.486L96.1394 14.3802L98.797 11.0906H102.001V14.3704V22.3078H99.4346L99.4375 14.428Z"
            fill="#E73458"
          />
          <path
            d="M83.4576 10.887C86.5836 10.887 89.1213 13.4147 89.1213 16.6917C89.1213 19.9701 86.6922 22.5147 83.4576 22.5147C80.1285 22.5147 77.7812 19.9715 77.7812 16.6917C77.7812 13.4147 80.3599 10.887 83.4576 10.887ZM83.4576 20.039C85.5045 20.039 86.3523 18.3646 86.3523 16.6917C86.3523 15.0201 85.4509 13.3458 83.4576 13.3458C81.4799 13.3458 80.5517 15.0201 80.5517 16.6917C80.5517 18.3646 81.3558 20.039 83.4576 20.039Z"
            fill="#E73458"
          />
          <path
            d="M50.4198 22.308H47.662L46.8298 20.0656H42.5457L41.7134 22.308H38.957L43.5021 11.0894H45.8762L50.4198 22.308ZM44.7025 14.5562H44.6743L43.4189 17.8233H45.958L44.7025 14.5562Z"
            fill="#0069B4"
          />
          <path
            d="M54.8781 11.0894C56.9658 11.0894 58.5499 12.2196 58.5499 14.4212C58.5499 16.104 57.6217 17.2343 56.2435 17.6715L60.1186 22.308H56.8572L53.4195 17.8753V22.308H50.8535V11.0894H54.8781ZM53.7199 16.0674C54.6876 16.0674 55.8486 15.9985 55.8486 14.5983C55.8486 13.1981 54.689 13.1292 53.7199 13.1292H53.4195V16.0674H53.7199Z"
            fill="#0069B4"
          />
          <path
            d="M59.3711 11.0894H67.2848V13.3317H64.6102V22.308H62.0457V13.3317H59.3711V11.0894Z"
            fill="#0069B4"
          />
          <path
            d="M29.0465 11.0894H25.8359V14.1991V22.308H28.4005L28.4061 14.4282H28.4752L32.2078 18.3477L34.1093 16.357L29.0465 11.0894Z"
            fill="#0069B4"
          />
          <path
            d="M35.9369 11.0894L34.4473 12.8087L36.0088 14.5871L36.0173 22.308H38.5818V14.372V11.0894H35.9369Z"
            fill="#0069B4"
          />
          <path
            d="M71.4748 11.0894H68.2148V13.3752H70.7794H71.1758C73.0858 13.3752 74.3835 14.8289 74.3835 16.7043C74.3694 18.5797 73.0731 20.0235 71.1758 20.0235H70.7794V16.6973H68.2148V22.3066H71.4762C74.5726 22.3066 77.154 19.9686 77.154 16.6902C77.1526 13.4301 74.5599 11.0894 71.4748 11.0894Z"
            fill="#E73458"
          />
        </svg>
      );
    }
    case SVG_TYPE.LOGO_WHITE: {
      return (
        <svg
          className={`svg ${className}`}
          width="102"
          height="23"
          viewBox="0 0 102 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.4789 5.65869L0 11.1176V22.2856H5.48172V13.3838L11.1835 19.0662L15.0585 15.2015L5.4789 5.65869Z"
            fill="#FAFAFA"
          />
          <path
            d="M22.3566 11.1244L19.3082 8.08921V4.72222H15.9297L11.1942 0L7.31641 3.86325L16.8706 13.3878V22.2854H22.358L22.3566 11.1244Z"
            fill="#FAFAFA"
          />
          <path
            d="M99.4375 14.428H99.3697L96.1352 18.3475L92.9119 14.428H92.8456L92.8385 22.3078H90.2754V14.1989V11.0906H93.486L96.1394 14.3802L98.797 11.0906H102.001V14.3704V22.3078H99.4346L99.4375 14.428Z"
            fill="#FAFAFA"
          />
          <path
            d="M83.4576 10.887C86.5836 10.887 89.1213 13.4147 89.1213 16.6917C89.1213 19.9701 86.6922 22.5147 83.4576 22.5147C80.1285 22.5147 77.7812 19.9715 77.7812 16.6917C77.7812 13.4147 80.3599 10.887 83.4576 10.887ZM83.4576 20.039C85.5045 20.039 86.3523 18.3646 86.3523 16.6917C86.3523 15.0201 85.4509 13.3458 83.4576 13.3458C81.4799 13.3458 80.5517 15.0201 80.5517 16.6917C80.5517 18.3646 81.3558 20.039 83.4576 20.039Z"
            fill="#FAFAFA"
          />
          <path
            d="M50.4198 22.308H47.662L46.8298 20.0656H42.5457L41.7134 22.308H38.957L43.5021 11.0894H45.8762L50.4198 22.308ZM44.7025 14.5562H44.6743L43.4189 17.8233H45.958L44.7025 14.5562Z"
            fill="#FAFAFA"
          />
          <path
            d="M54.8781 11.0894C56.9658 11.0894 58.5499 12.2196 58.5499 14.4212C58.5499 16.104 57.6217 17.2343 56.2435 17.6715L60.1186 22.308H56.8572L53.4195 17.8753V22.308H50.8535V11.0894H54.8781ZM53.7199 16.0674C54.6876 16.0674 55.8486 15.9985 55.8486 14.5983C55.8486 13.1981 54.689 13.1292 53.7199 13.1292H53.4195V16.0674H53.7199Z"
            fill="#FAFAFA"
          />
          <path
            d="M59.3711 11.0894H67.2848V13.3317H64.6102V22.308H62.0457V13.3317H59.3711V11.0894Z"
            fill="#FAFAFA"
          />
          <path
            d="M29.0465 11.0894H25.8359V14.1991V22.308H28.4005L28.4061 14.4282H28.4752L32.2078 18.3477L34.1093 16.357L29.0465 11.0894Z"
            fill="#FAFAFA"
          />
          <path
            d="M35.9369 11.0894L34.4473 12.8087L36.0088 14.5871L36.0173 22.308H38.5818V14.372V11.0894H35.9369Z"
            fill="#FAFAFA"
          />
          <path
            d="M71.4748 11.0894H68.2148V13.3752H70.7794H71.1758C73.0858 13.3752 74.3835 14.8289 74.3835 16.7043C74.3694 18.5797 73.0731 20.0235 71.1758 20.0235H70.7794V16.6973H68.2148V22.3066H71.4762C74.5726 22.3066 77.154 19.9686 77.154 16.6902C77.1526 13.4301 74.5599 11.0894 71.4748 11.0894Z"
            fill="#FAFAFA"
          />
        </svg>
      );
    }
    case SVG_TYPE.ELLIPSIS: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="6"
          viewBox="0 0 24 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3" cy="3" r="3" fill="#D9D9D9" />
          <circle cx="12" cy="3" r="3" fill="#D9D9D9" />
          <circle cx="21" cy="3" r="3" fill="#D9D9D9" />
        </svg>
      );
    }

    case SVG_TYPE.ACCEPT_GREEN: {
      return (
        <svg
          className={`svg  ${className}`}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#38D58B" />
          <path
            d="M5 7.99976L7.12132 10.1211L11.3637 5.87842"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.ALERT: {
      return (
        <svg
          className={`svg ${className}`}
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_29_129)">
            <rect width="28" height="28" rx="14" fill="#F53B64" />
            <path
              d="M15.6211 16.9609H12.3984L11.9004 7.72266H16.1191L15.6211 16.9609ZM11.9395 20.3789C11.9395 19.7865 12.1152 19.3275 12.4668 19.002C12.8184 18.6699 13.3294 18.5039 14 18.5039C14.6641 18.5039 15.1654 18.6699 15.5039 19.002C15.849 19.3275 16.0215 19.7865 16.0215 20.3789C16.0215 20.9648 15.8424 21.4238 15.4844 21.7559C15.1328 22.0814 14.638 22.2441 14 22.2441C13.3555 22.2441 12.8509 22.0814 12.4863 21.7559C12.1217 21.4238 11.9395 20.9648 11.9395 20.3789Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_29_129">
              <rect width="28" height="28" rx="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }

    case SVG_TYPE.EYE: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
            stroke="#979797"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
            stroke="#979797"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.CHEVRON_DOWN: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9L12 16L5 9"
            stroke="#949598"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.CHEVRON_TOP: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 15L12 8L19 15"
            stroke="#949598"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.EYE_OFF: {
      return (
        <svg
          className={`svg ${className}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
            stroke="#979797"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    default: {
      return null;
    }
  }
};

export default SVG;
