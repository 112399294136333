import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type WindowsReplacementInfoData = {
  measurementUuid: string;
  roomName: string;
  holeSizeWidth: string;
  holeSizeHeight: string;
  joinerySizeWidth: string;
  joinerySizeHeight: string;
  windowComment?: string;
  windowBottomAddition: 'clinarite' | 'systemicExpansion' | 'warmWindowSill' | 'undersillStrip' | 'other';
  windowBottomAdditionSize: string;
  windowSideAddition: 'widening' | 'miniConnector' | '90connector' | 'pipeConnector' | '45connector' | 'other';
  windowSideAdditionSize: string;
  typeOfSlidingWindow: 'hst' | 'smartSlide' | 'psk';
  comment?: string;
  windowAdditionUuids: Array<{ uuid: string; comment?: string }>;
};

const usePutWindowsReplacementMeasurementValues = (
  options?: UseMutationOptions<any, Error, WindowsReplacementInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editWindowsReplacementInfo'],
    mutationFn: async (data: WindowsReplacementInfoData) => {
      return axiosInstance.put(`/measurement/windows-replacement/info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie informacji o wymianie okien...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-windows-replacement-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Informacje o wymianie okien zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-windows-replacement-info',
      });
      queryClient.invalidateQueries({ queryKey: ['windowsReplacementInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji informacji o wymianie okien.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-windows-replacement-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutWindowsReplacementMeasurementValues;
