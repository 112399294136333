import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig'; 
import { Toast } from 'components'; 
import { TOAST_TYPES_ENUM } from 'enums'; 
import { queryClient } from 'App'; 

type DeleteSupplierType = {
  uuid: string;
};

const useDeleteSupplier = (
  options?: UseMutationOptions<any, Error, DeleteSupplierType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['deleteSupplier'],
    mutationFn: async ({ uuid }) => {
      const url = `/suppliers/${encodeURIComponent(uuid)}`;
      return axiosInstance.delete(url)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie żądania usunięcia dostawcy...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'delete-supplier',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Dostawca został pomyślnie usunięty.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'delete-supplier',
      });
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas usuwania dostawcy. Może posiadać przypisany materiał montażowy.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'delete-supplier',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useDeleteSupplier;
