import axios, { AxiosResponse, AxiosError } from "axios";

interface CustomError {
  message: string;
  data?: any;
  status?: number;
  headers?: any;
  request?: string;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError): Promise<any> => {
    let customError: CustomError = {
      message: error.message,
    };

    if (error.response) {
      customError = {
        ...customError,
        data: error.response.data,
        status: error.response.status,
        headers: error.response.headers,
      };

    } else if (error.request) {
      customError = {
        ...customError,
        request: "Request was made but no response was received",
      };
    }

    return Promise.reject(customError);
  }
);

export default axiosInstance;
