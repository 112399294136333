import React, { ReactNode } from "react";
import classNames from "classnames";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import "./user-access-panel.scss";
import { useNavigate } from "react-router";

interface UserAccessPanelProps {
  children: ReactNode;
  className?: string;
  heading: string;
  goBack?: any;
}

const UserAccessPanel = ({
  children,
  className,
  heading,
  goBack,
}: UserAccessPanelProps) => {
  const navigate = useNavigate();
  const componentClass = classNames("uap", className);

  const previewSite = () => {
    const goBackFunc = () => {
      navigate(goBack);
    };
    return (
      <>
        {goBack && (
          <div className="uap__go-back" onClick={() => goBackFunc()}>
            <SVG type={SVG_TYPE.ARROW_BACK} />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={componentClass}>
      <div className="uap__logo">
        <SVG type={SVG_TYPE.LOGO} />
      </div>
      <div className="uap__box">
        <div className="uap__heading">
          {previewSite()}
          {heading}
        </div>
        <div className="uap__content">{children}</div>
      </div>
    </div>
  );
};

export default UserAccessPanel;
