import { Input, Select, Textarea } from "components";
import { Field } from "formik";
import {
  SingleSectionElement,
  HoleDimension,
  TypeOfRadiobox,
} from "features/addingMeasurement";

interface RollerGateFormType {
  touched: any;
  errors: any;
  validateField: any;
}

const RollerGateForm = ({
  touched,
  errors,
  validateField,
}: RollerGateFormType) => {
  return (
    <>
      <Field
        errors={errors}
        name="theBuildingIsMadeOf"
        label="Typ montażu"
        options={[
          {
            label: "We wnęce",
            value: "ceramicBlock",
            uuid: "ceramicBlock",
          },
          {
            label: "Na ścianie na zewnątrz",
            value: "woodenStructure",
            uuid: "woodenStructure",
          },
          {
            label: "Na ścianie wewnątrz",
            value: "yTong",
            uuid: "yTong",
          },
        ]}
        component={TypeOfRadiobox}
      />
      <Field
        errors={errors}
        validateField={validateField}
        label="Wymiar otworu"
        component={HoleDimension}
      />
      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator"
      >
        <Field
          errors={errors}
          maxLength={30}
          label="Wysokość nadproża"
          rightPlaceholder="mm"
          number
          name={`color`}
          component={Input}
        />
      </SingleSectionElement>
      <SingleSectionElement
        className="padding-top-separator single-section-element--strips"
        touched={touched}
        name="roomName"
        errors={errors}
      >
        <div className="am-section-heading">Wymiar bramy</div>
        <Field
          label="Szerokość"
          name="holeSizeWidth"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
        <Field
          label="Wysokość"
          name="holeSizeHeight"
          type="number"
          rightPlaceholder="mm"
          component={Input}
        />
      </SingleSectionElement>

      <Field
        errors={errors}
        name="theBuildingIsMadeOf"
        label="Strona wyjścia kabla"
        options={[
          {
            label: "Bok",
            value: "ceramicBlock",
            uuid: "ceramicBlock",
          },
          {
            label: "Tył",
            value: "woodenStructure",
            uuid: "woodenStructure",
          },
          {
            label: "Przód",
            value: "yTong",
            uuid: "yTong",
          },
        ]}
        component={TypeOfRadiobox}
      />

      <Field
        errors={errors}
        half
        name="theBuildingIsMadeOf"
        label="Rodzaj napędu"
        options={[
          {
            label: "Radiowy plus klawisz",
            value: "ceramicBlock",
            uuid: "ceramicBlock",
          },
          {
            label: "Klawisz",
            value: "woodenStructure",
            uuid: "woodenStructure",
          },
          {
            label: "Ręczny",
            value: "yTong",
            uuid: "yTong",
          },
          {
            label: "Wifi",
            value: "yTong",
            uuid: "yTong",
          },
        ]}
        component={TypeOfRadiobox}
      />

      <SingleSectionElement
        touched={touched}
        name="spoutLevel"
        errors={errors}
        className="padding-top-separator padding-bottom-separator"
      >
        <Field
          label="Strona kabla zasilającego/linki (widok od wewnątrz)"
          name="open"
          type="number"
          options={[
            {
              value: "Prawa",
              label: "Prawa",
            },
            {
              value: "Lewa",
              label: "Lewa",
            },
          ]}
          component={Select}
          white
        />
      </SingleSectionElement>

      <Field
        errors={errors}
        maxLength={30}
        label="Kolor"
        optional
        name={`color`}
        number
        component={Input}
      />

      <div className="am-separator" />

      <Field
        className="am-margin-top"
        errors={errors}
        label="Dodatkowe uwagi"
        name="comment"
        optional
        component={Textarea}
        maxLength={250}
      />
    </>
  );
};

export default RollerGateForm;
