import { Page, PageContent, UserAccessPanel, Button } from "components";
import "./password-has-been-changed.scss";
import { ROUTE_ENUM } from "enums";
import { useNavigate } from "react-router";

const PasswordHasBeenChanged = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <PageContent>
        <UserAccessPanel heading="Hasło zostało zmienione">
          <div className="uap__description password-has-been-changed__description">
            Możesz teraz zalogować się za pomocą nowego hasła.
          </div>

          <div className="uap__button">
            <Button
              onClick={() => navigate(ROUTE_ENUM.LOGIN)}
              label="Zaloguj się"
            />
          </div>
        </UserAccessPanel>
      </PageContent>
    </Page>
  );
};

export default PasswordHasBeenChanged;
