import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

type MeasurementInfoData = {
  measurementUuid: string;
  roomName: string;
  holeSizeWidth: string;
  holeSizeHeight: string;
  joinerySizeWidth: string;
  joinerySizeHeight: string;
  windowComment?: string;
  windowBottomAddition: 'clinarite' | 'systemicExpansion' | 'warmWindowSill' | 'undersillStrip' | 'other';
  windowBottomAdditionSize: string;
  windowSideAddition: 'widening' | 'miniConnector' | '90connector' | 'pipeConnector' | '45connector' | 'other';
  windowSideAdditionSize: string;
  typeOfSlidingWindow: 'hst' | 'smartSlide' | 'psk';
  comment?: string;
  windowAdditionUuids: Array<{ uuid: string; comment?: string }>;
};

const usePutMeasurementInfo = (
  options?: UseMutationOptions<any, Error, MeasurementInfoData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editMeasurementInfo'],
    mutationFn: async (data: MeasurementInfoData) => {
      return axiosInstance.put(`/measurement/info`, data).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie informacji o pomiarze...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-measurement-info',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Informacje o pomiarze zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-measurement-info',
      });
      queryClient.invalidateQueries({ queryKey: ['measurementInfo'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji informacji o pomiarze.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-measurement-info',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutMeasurementInfo;
