/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { Select, MultipleSelect } from "components";
import { useGetFitterAndMeasurer } from "hooks/users";
import { useSearchParams } from "react-router-dom";
import "./user-select-form.scss";

interface UserSelectFormType {
  setUser: (uuid: { label: string; value: string }) => void;
  setUsersTable: (uuid: { label: string; value: string }) => void;
  orderDetails?: any;
  usersTable?: any;
}

const UserSelectForm = ({
  setUser,
  orderDetails,
  setUsersTable,
  usersTable,
}: UserSelectFormType) => {
  const [activeUser, setActiveUser] = useState<any>();
  const { data: fitterAndMeasurer } = useGetFitterAndMeasurer();
  const { data: fitter } = useGetFitterAndMeasurer(true);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const isEdit = tab === "fixedMeasurements";
  const isInstallationToBeReleased = tab === "installationToBeReleased";

  useEffect(() => {
    if (fitterAndMeasurer && !isEdit) {
      setUser(fitterAndMeasurer[0]);
    } else if (
      fitterAndMeasurer &&
      isEdit &&
      orderDetails &&
      orderDetails.employee.uuid
    ) {
      const user = fitterAndMeasurer.find(
        (item: any) => item.value === orderDetails.employee.uuid
      );
      setActiveUser(user);
      user && setUser(user);
    }
  }, [fitterAndMeasurer, orderDetails]);

  const usersSelect = fitter && [
    { label: "Wszyscy pracownicy", value: "all" },
    ...fitter,
  ];

  const initialValues = isInstallationToBeReleased
    ? { employee: usersSelect ? usersSelect[0].value : "" }
    : {
        employee: fitterAndMeasurer
          ? isEdit && activeUser
            ? activeUser.value
            : fitterAndMeasurer[0].value
          : "",
      };

  const handleChangeUser = (e: any) => {
    const usersTable = e.map((user: any) => user.value);
    setUsersTable(usersTable);
  };
  return (
    <div className="user-select-form">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => console.log(null)}
      >
        {isInstallationToBeReleased ? (
          <Field
            label="Pracownik"
            name="employee"
            onChange={(employe: any) => handleChangeUser(employe)}
            component={MultipleSelect}
            options={usersSelect}
            size="medium"
            employee
            selectAll
            required
            white
            multiple
          />
        ) : (
          <Field
            label="Pracownik"
            name="employee"
            required
            white
            onChange={(employe: any) => setUser(employe)}
            component={Select}
            options={fitterAndMeasurer}
          />
        )}
      </Formik>
    </div>
  );
};

export default UserSelectForm;
