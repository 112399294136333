import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
import "./password-strength-checker.scss";

type P = {
  password: string;
  className?: string;
  isHidden?: boolean;
};

const PasswordStrengthChecker = ({ password, className = "", isHidden }: P) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState("weak");

  useEffect(() => {
    const getPasswordStrength = () => {
      setPasswordStrength(zxcvbn(password).score);
    };
    getPasswordStrength();
  }, [password]);

  useEffect(() => {
    if (passwordStrength <= 1) {
      setPasswordStrengthValue("weak");
    }
    if (passwordStrength > 1) {
      setPasswordStrengthValue("medium");
    }
    if (passwordStrength > 3) {
      setPasswordStrengthValue("strong");
    }
  }, [passwordStrength]);

  return (
    <div
      className={`password-strength-checker ${
        isHidden ? "password-strength-checker--hidden" : ""
      } ${className}`}
    >
      <span
        className={`password-strength-checker__bar password-strength-checker__bar--${passwordStrengthValue}`}
      >
        <p
          className={`password-strength-checker__label ${
            passwordStrengthValue === "weak"
              ? "password-strength-checker__label--active"
              : ""
          }`}
        >
          Słabe
        </p>
        <p
          className={`password-strength-checker__label  ${
            passwordStrengthValue === "medium"
              ? "password-strength-checker__label--active"
              : ""
          } `}
        >
          Średnie
        </p>
        <p
          className={`password-strength-checker__label   ${
            passwordStrengthValue === "strong"
              ? "password-strength-checker__label--active"
              : ""
          }`}
        >
          Mocne
        </p>
      </span>
      <span
        className={`password-strength-checker__bar ${
          passwordStrength > 1
            ? `password-strength-checker__bar--${passwordStrengthValue}`
            : ""
        }`}
      ></span>
      <span
        className={`password-strength-checker__bar ${
          passwordStrength > 3
            ? `password-strength-checker__bar--${passwordStrengthValue}`
            : ""
        }`}
      ></span>
    </div>
  );
};

export default PasswordStrengthChecker;
