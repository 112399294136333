/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Field, Formik } from "formik";
import { Input, Button, Select, Textarea } from "components";
import * as Yup from "yup";
import { VALIDATION_MESSAGES, ROUTE_ENUM } from "enums";
import {
  SingleSectionElement,
  Drawing,
  HoleDimension,
  JoineryDimension,
  AdditionBottomWindow,
  AdditionSideWindow,
  WindowAccessories,
  WindowSills,
} from "features/addingMeasurement";
import {
  useStartMeasurement,
  usePutWindowsReplacementMeasurementValues,
} from "hooks/measurements";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useUploadFile } from "hooks/utils";
import { ScrollToError } from "utils";

interface WindowsReplacementMeasurementValuesType {
  measurementUuid: string | null;
  prevStep: () => void;
  measurementInfo: any;
  setStep(step: number): void;
}

const WindowsReplacementMeasurementValues = ({
  measurementUuid,
  prevStep,
  setStep,
  measurementInfo,
}: WindowsReplacementMeasurementValuesType) => {
  const navigate = useNavigate();
  const [isErrorDimension, setIsErrorDimension] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const initialMeasurementInfo = measurementInfo?.measurementInfo;
  const [formDraw, setFormDraw] = useState<any>();
  const [isAddNew, setIsAddNew] = useState(false);

  const goToList = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderUuid}`);
    sessionStorage.removeItem("canvasState");
  };

  const commentsWindowAdditionComment =
    initialMeasurementInfo?.windowAdditionUuids?.find(
      (item: any) => item.comment
    )?.comment;

  const startMeasurement = useStartMeasurement({
    onSuccess: (data) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("measurementUuid", data.measurement.uuid);
      newSearchParams.set(
        "measurementPage",
        data.measurement.lastFinishedPage + 1 || "2"
      );
      setSearchParams(newSearchParams, { replace: true });
      setStep(data.measurement.lastFinishedPage + 1 || 2);
    },
  });

  const mutation = useUploadFile({
    onSuccess: (data) => {
      if (isAddNew) {
        setIsAddNew(false);
      } else {
        goToList();
      }
    },
  });

  const { mutate } = usePutWindowsReplacementMeasurementValues({
    onSuccess: (data) => {
      if (formDraw !== "oldDraw") {
        mutation.mutate({
          parentUuid: data.measurement.measurementInfo.uuid,
          type: "drawingOfWindowDivision",
          files: [formDraw],
        });
      } else {
        goToList();
      }
    },
  });

  const handleOnSubmit = async (values: any) => {
    setFormDraw(values.drawing);
    delete values.drawing;
    mutate(values);
    if (isAddNew) {
      startMeasurement.mutate({
        typeOfMeasurementUuid: measurementInfo.typeOfMeasurementUuid,
        orderUuid,
      });
    }
  };

  const handleCheckValidateDimensions = (
    values: any,
    setFieldError: any,
    errors: any,
    setFieldValue: any
  ) => {
    const {
      joinerySizeWidth,
      windowSideAdditionSize,
      joinerySizeHeight,
      windowBottomAdditionSize,
      holeSizeHeight,
      holeSizeWidth,
      warningComment,
    } = values;

    if (
      joinerySizeHeight &&
      windowBottomAdditionSize &&
      joinerySizeWidth &&
      windowSideAdditionSize &&
      holeSizeHeight &&
      holeSizeWidth
    ) {
      const holeWidth = parseInt(holeSizeWidth, 10);
      const holeHeight = parseInt(holeSizeHeight, 10);
      const joineryHeight = parseInt(joinerySizeHeight, 10);
      const joineryWidth = parseInt(joinerySizeWidth, 10);
      const bottomAddition = parseInt(windowBottomAdditionSize, 10);
      const sideAddition = parseInt(windowSideAdditionSize, 10);

      const widthDifference = holeWidth - (joineryWidth + sideAddition);
      const heightDifference = holeHeight - (joineryHeight + bottomAddition);

      if (
        widthDifference >= 0 &&
        widthDifference <= 50 &&
        heightDifference >= 0 &&
        heightDifference <= 50
      ) {
        setIsErrorDimension(false);
      } else {
        if (!errors.warningComment && !warningComment) {
          setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
        }
        setIsErrorDimension(true);
      }
    }
    return false;
  };

  const initialValues = {
    measurementUuid: measurementUuid,
    roomName: initialMeasurementInfo?.roomName || "",
    holeSizeWidth: initialMeasurementInfo?.holeSizeWidth || "",
    holeSizeHeight: initialMeasurementInfo?.holeSizeHeight || "",
    joinerySizeWidth: initialMeasurementInfo?.joinerySizeWidth || "",
    joinerySizeHeight: initialMeasurementInfo?.joinerySizeHeight || "",
    // windowBottomAddition: initialMeasurementInfo?.windowBottomAddition || "",
    windowBottomAdditionSize:
      initialMeasurementInfo?.windowBottomAdditionSize || "",
    windowSideAdditionSize:
      initialMeasurementInfo?.windowSideAdditionSize || "",
    // windowSideAddition: initialMeasurementInfo?.windowSideAddition || "",
    windowAdditionUuids: initialMeasurementInfo?.windowAdditionUuids || [],
    typeOfSlidingWindow: initialMeasurementInfo?.typeOfSlidingWindow || "",
    comment: initialMeasurementInfo?.comment || "",
    windowComment: initialMeasurementInfo?.windowComment || "",
    commentsWindowAddition: commentsWindowAdditionComment || "",
    warningComment: initialMeasurementInfo?.warningComment || "",
    file: initialMeasurementInfo?.file || [],
    windowSill: initialMeasurementInfo?.windowSill || [],
  };

  const validationSchema = Yup.object().shape({
    holeSizeWidth: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    holeSizeHeight: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
    roomName: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    joinerySizeWidth: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    joinerySizeHeight: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    windowBottomAdditionSize: Yup.string().required(
      VALIDATION_MESSAGES.REQUIRED
    ),
    windowSideAdditionSize: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    drawing: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    warningComment: isErrorDimension
      ? Yup.string().required(VALIDATION_MESSAGES.REQUIRED)
      : Yup.string(),
  });

  return (
    <div>
      <div className="am-heading">Wartości pomiaru</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({
          handleSubmit,
          errors,
          values,
          submitCount,
          touched,
          validateField,
          setFieldError,
          setFieldValue,
        }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          handleCheckValidateDimensions(
            values,
            setFieldError,
            errors,
            setFieldValue
          );
          return (
            <form className="amfs__form form" onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                errors={errors}
                label="Rysunek podziału okien"
                name="drawing"
                component={Drawing}
              />
              <SingleSectionElement
                touched={touched}
                name="roomName"
                errors={errors}
              >
                <Field
                  label="Nazwa pomieszczenia"
                  name="roomName"
                  maxLength={30}
                  component={Input}
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                label="Wymiar otworu"
                component={HoleDimension}
              />
              <Field
                errors={errors}
                label="Wymiar stolarki"
                validateField={validateField}
                component={JoineryDimension}
              />
              <Field
                errors={errors}
                label="Dodatek dół okna"
                white
                name="windowBottomAddition"
                component={AdditionBottomWindow}
                validateField={validateField}
              />
              <Field
                errors={errors}
                validateField={validateField}
                label="Dodatek bok okna"
                white
                name="windowSideAddition"
                component={AdditionSideWindow}
                isErrorDimension={isErrorDimension}
              />
              <Field
                values={values}
                errors={errors}
                validateField={validateField}
                name="windowAdditionUuids"
                component={WindowAccessories}
              />
              <SingleSectionElement
                className="am-margin-top"
                touched={touched}
                name="typeOfSlidingWindow"
                errors={errors}
              >
                <Field
                  label="Rodzaj okna przesuwnego"
                  name="typeOfSlidingWindow"
                  placeholder="Wybierz"
                  component={Select}
                  options={[
                    { value: "hst", label: "HST" },
                    { value: "smartSlide", label: "Smart-slide" },
                    { value: "psk", label: "PSK" },
                  ]}
                  white
                />
              </SingleSectionElement>
              <Field
                errors={errors}
                validateField={validateField}
                component={WindowSills}
              />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comment"
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons am__buttons--last-step">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button
                  stroke
                  onClick={() => {
                    handleSubmit();
                    setIsAddNew(true);
                  }}
                  label="Zakończ i dodaj kolejny tego typu"
                />
                <Button onClick={() => handleSubmit()} label="Zakończ pomiar" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default WindowsReplacementMeasurementValues;
