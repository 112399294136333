export const convertTime = (time: string) => {
  if (!time) {
    return '0m'; // jeśli time jest undefined lub pusty, zwróć pusty string
  }

  const [hours, minutes] = time.split(':').map(Number);
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  const result = [];

  if (days > 0) {
    result.push(`${days}d`);
  }
  if (remainingHours > 0) {
    result.push(`${remainingHours}h`);
  }
  if (minutes > 0) {
    result.push(`${minutes}m`);
  }
  if (minutes === 0) {
    result.push(`${minutes}m`);
  }

  return result.join(' ');
};
