import { useEffect } from "react";
import { SVG } from "components";
import { SVG_TYPE } from "enums";
import "./password-validation.scss";

type ValidationItemProps = {
  correct: boolean;
  label: string;
};

function ValidationItem({ correct, label }: ValidationItemProps) {
  return (
    <div className="validation-item">
      <SVG type={correct ? SVG_TYPE.ACCEPT_GREEN : SVG_TYPE.CLOSE_CIRCLE} />
      <p>{label}</p>
    </div>
  );
}

type PasswordValidationProps = {
  password: string;
  className?: string;
  setIsValid?: (isValid: boolean) => void;
};

function PasswordValidation({
  password,
  className = "",
  setIsValid,
}: PasswordValidationProps) {
  useEffect(() => {
    if (setIsValid) {
      setIsValid(
        /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          /[0-9]/.test(password) &&
          password.replace(" ", "").length >= 8
      );
    }
  }, [password, setIsValid]);
  return (
    <div className={`validation-item__wrapper ${className}`}>
      <ValidationItem correct={/[A-Z]/.test(password)} label="1 duża litera" />
      <ValidationItem correct={/[a-z]/.test(password)} label="1 mała litera" />
      {/* <ValidationItem correct={/[!@#$%^&*(),.?":{}|<>]/.test(password)} label={'1 znak specjalny '} /> */}
      <ValidationItem correct={/[0-9]/.test(password)} label="1 cyfra" />
      <ValidationItem
        correct={password.replace(" ", "").length >= 8}
        label="minimum 8 znaków"
      />
    </div>
  );
}
export default PasswordValidation;
