import { Button, Modal } from "components";
import { useDeleteActivity } from "hooks/activity";

interface DeleteActivityType {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteActivity = ({ isOpen, onClose, data }: DeleteActivityType) => {
  const mutation = useDeleteActivity();

  const handleDeleteActivity = (uuid: string) => {
    mutation.mutate({ activitiesUuid: uuid });
    onClose();
  };

  return (
    <>
      <Modal
        className="delete-activity"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">Usuwanie czynności</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz usunąć czynność
            <span>{data.text}</span>?
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button
              onClick={() => handleDeleteActivity(data.uuid)}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteActivity;
