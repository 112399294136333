import React, { useEffect, useRef } from "react";
import { WorkingTime } from "features/orders";
import { useGetTimeManagement } from "hooks/activity-time";
import { useSearchParams } from "react-router-dom";

const ActivityTime = () => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const { data: timeManagement, refetch } = useGetTimeManagement(orderUuid);
  const intervalIdRef = useRef<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };

    fetchData();

    intervalIdRef.current = window.setInterval(async () => {
      await fetchData();
    }, 61000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [orderUuid, refetch]);

  return (
    <>
      <div className="active-order__heading">Czas pracy</div>
      {timeManagement &&
        timeManagement.length > 0 &&
        timeManagement.map((activity, index) => (
          <WorkingTime
            key={index}
            activity={activity}
            timeManagement={timeManagement[index]}
            label={activity.activities}
          />
        ))}
    </>
  );
};

export default ActivityTime;
