import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetOrderDetails } from "hooks/orders";
import { useGetMeasurements } from "hooks/measurements";
import { SVG_TYPE, ROUTE_ENUM } from "enums";
import { Button, SVG, ContextMenu, StaticAlert } from "components";
import { useNavigate } from "react-router";
import { DeleteMeasurement, CloseMeasurement } from "features/orders";
import "./active-order.scss";
import { ActivityTime } from "features/activity";

const ActiveOrder = () => {
  const [isOpenDelete, setIsOpenDelete] = useState({
    label: "",
    isOpen: false,
    uuid: "",
  });
  const [isCloseMeasurementOpen, setIsCloseMeasurementOpen] = useState(false);
  const [errorEndMeasurement, setErrorEndMeasurement] = useState({
    isError: false,
    errorMessage: "",
  });
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const page = searchParams.get("page");
  const orderDetailsUrl = `?page=${page}&orderUuid=${orderUuid}`;
  const { data: orderDetails } = useGetOrderDetails(orderUuid, true);
  const { data: measurements } = useGetMeasurements(orderUuid);
  const navigate = useNavigate();

  const handleAddMeasurement = () => {
    sessionStorage.removeItem("canvasState");
    navigate(`${ROUTE_ENUM.ADDING_MEASUREMENT}${orderDetailsUrl}`);
  };

  const handleGoToOrdersList = () => {
    navigate(`${ROUTE_ENUM.ORDERS_LIST}${orderDetailsUrl}`);
  };

  const handleCloseOrder = () => {
    const isAnyMeasurementIncomplete =
      measurements &&
      measurements.length > 0 &&
      measurements.some((item) => !item.isFinished);
    const isAnyMeasurementAvailable = measurements && measurements.length > 0;
    if (!isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndMeasurement({
        isError: true,
        errorMessage:
          "Aby zakończyć zlecenie musisz dodać przynajmniej jeden pomiar.",
      });
    } else if (isAnyMeasurementAvailable && isAnyMeasurementIncomplete) {
      setErrorEndMeasurement({
        isError: true,
        errorMessage:
          "Masz niedokończone pomiary. Uzupełnij brakujące dane, aby móc zakończyć zlecenie.",
      });
    } else if (isAnyMeasurementAvailable && !isAnyMeasurementIncomplete) {
      setErrorEndMeasurement({ isError: false, errorMessage: "" });
      setIsCloseMeasurementOpen(true);
    }
  };

  const measurementMenu = ({ status, label, uuid, lastFinishedPage }: any) => {
    return [
      {
        name: status ? "Edytuj" : "Dokończ",
        action: () => {
          if (status) {
            navigate(
              `${ROUTE_ENUM.ADDING_MEASUREMENT}${orderDetailsUrl}&measurementUuid=${uuid}&measurementPage=${lastFinishedPage}`
            );
          } else {
            navigate(
              `${
                ROUTE_ENUM.ADDING_MEASUREMENT
              }${orderDetailsUrl}&measurementUuid=${uuid}&measurementPage=${
                lastFinishedPage + 1
              }`
            );
          }
        },
      },
      {
        name: "Usuń",
        action: () => {
          setIsOpenDelete({ label: label, isOpen: true, uuid });
        },
      },
    ];
  };

  // const checkboxList = [
  //   {
  //     label: "Lorem ipsum dolor sit amety",
  //     value: "ceramicBlock",
  //     uuid: "ceramicBlock",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "woodenStructure",
  //     uuid: "woodenStructure",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "yTong",
  //     uuid: "yTong",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "silicate",
  //     uuid: "silicate",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  //   {
  //     label: "Lorem ipsum dolor sit amet",
  //     value: "other",
  //     uuid: "other",
  //   },
  // ];

  return (
    <>
      <DeleteMeasurement
        data={isOpenDelete}
        isOpen={isOpenDelete.isOpen}
        onClose={() =>
          setIsOpenDelete({
            isOpen: false,
            label: isOpenDelete.label,
            uuid: isOpenDelete.uuid,
          })
        }
      />
      <CloseMeasurement
        data={{ label: orderDetails?.name, uuid: "" }}
        isOpen={isCloseMeasurementOpen}
        onClose={() => setIsCloseMeasurementOpen(false)}
      />
      <div className="dashboard-tab-name dashboard-tab-name-with-back">
        <div
          className="dashboard-tab-name-with-back__icon"
          onClick={() => handleGoToOrdersList()}
        >
          <SVG type={SVG_TYPE.ARROW_BACK} />
        </div>
        {orderDetails?.name}
      </div>
      <div className="active-order__content">
        <div className="active-order__box active-order__box--left">
          <ActivityTime />
          <div className="active-order__bar">
            <div className="active-order__bar-heading">
              <div className="active-order__heading">Pomiary</div>
              <Button
                stroke
                svgType={SVG_TYPE.PLUS}
                size="medium"
                label={`Dodaj pomiar `}
                onClick={() => handleAddMeasurement()}
              />
            </div>
            <div className="active-order__measurement-list">
              {measurements &&
                measurements.length > 0 &&
                measurements.map((item: any) => {
                  return (
                    <div
                      key={item.uuid}
                      className="active-order__measurement-item"
                    >
                      <div className="active-order__measurement-item__name">
                        {item.measurementLabel}
                        {item.roomName ? ` - ${item.roomName}` : ""}
                      </div>
                      <div className="active-order__measurement-item__menu">
                        {!item.isFinished && (
                          <div className="active-order__measurement-badge">
                            Niedokończone
                          </div>
                        )}
                        <ContextMenu
                          className="dtb__user-submenu"
                          items={measurementMenu({
                            status: item.isFinished,
                            label: item.measurementLabel,
                            uuid: item.uuid,
                            lastFinishedPage: item.lastFinishedPage,
                          })}
                        >
                          <SVG type={SVG_TYPE.ELLIPSIS} />
                        </ContextMenu>
                      </div>
                    </div>
                  );
                })}
              <StaticAlert
                show={errorEndMeasurement.isError}
                label={errorEndMeasurement.errorMessage}
              />
            </div>
          </div>
          <Button
            label="Zakończ zlecenie"
            className="active-order__measurement-button"
            onClick={() => handleCloseOrder()}
          />
          {/* <div className="checklist">
            <div className="active-order__bar-heading">
              <div className="active-order__heading">Zużyte materiały</div>
              <Button
                stroke
                svgType={SVG_TYPE.PLUS}
                size="medium"
                label={`Dodaj materiał`}
                onClick={() => handleAddMeasurement()}
              />
            </div>
            <div className="active-order__heading checklist__heading">
              Checklista okna stan surowy
            </div>
            {checkboxList &&
              checkboxList.length > 0 &&
              checkboxList.map((item: any) => {
                return (
                  <CheckboxPart
                    className="checklist__item"
                    key={item.value}
                    label={item.label}
                    uuid={item.value}
                    onChange={(uuid) => console.log(uuid)}
                    required
                  />
                );
              })}
          </div> */}
        </div>
        <div className="active-order--right">
          {/* <div className="active-order__box"> */}
          <div className="active-order__box active-order__box--measurement">
            <div className="active-order__heading">Szczegóły zlecenia</div>
            <div className="active-order__details"></div>
            <div className="active-order__details-heading">
              Imię i nazwisko klienta
            </div>
            <div className="active-order__details-paragraph">
              {orderDetails?.clientName}{" "}
            </div>
            <div className="active-order__details-heading">Telefon</div>
            <div className="active-order__details-paragraph">
              {orderDetails?.phoneNumber}
            </div>
            <div className="active-order__details-heading">Adres Email</div>
            <div className="active-order__details-paragraph">
              {orderDetails?.email}
            </div>
            <div className="active-order__details-heading">Adres pomiaru</div>
            <div className="active-order__details-paragraph">
              {orderDetails?.address}
            </div>
            <div className="active-order__details-heading">Termin pomiaru</div>
            <div className="active-order__details-paragraph">
              {orderDetails?.date}, godz. {orderDetails?.start}-
              {orderDetails?.end}
            </div>
            <div className="active-order__details-heading">
              Uwagi dla pomiarowca
            </div>
            <div className="active-order__details-paragraph">
              {orderDetails?.comments}
            </div>
          </div>
          {/* <div className="active-order__box">
            <div className="checklist">
              <div className="active-order__heading checklist__heading">
                Checklista ogólna
              </div>
              {checkboxList &&
                checkboxList.length > 0 &&
                checkboxList.map((item: any) => {
                  return (
                    <CheckboxPart
                      className="checklist__item"
                      key={item.value}
                      label={item.label}
                      uuid={item.value}
                      onChange={(uuid) => console.log(uuid)}
                    />
                  );
                })}
              <StaticAlert
                className="checklist__alert"
                show={true}
                label={
                  "Masz niezaznaczone elementy. Uzupełnij brakujące dane, aby móc zakończyć zlecenie."
                }
              />
            </div>
          </div> */}
          <Button
            label="Zakończ zlecenie"
            className="active-order__details-button"
            onClick={() => handleCloseOrder()}
          />
        </div>
      </div>
    </>
  );
};

export default ActiveOrder;
