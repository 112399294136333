
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM, ROUTE_ENUM } from 'enums';
import { queryClient } from 'App';
import { useNavigate } from "react-router";

interface UpdateMeasurementDetailsType {
  uuid: string | null;
  date: string;
  start: string;
  end: string;
  comment: string;
  employeeUuid: string;
}

const useAddMeasurementOrder = (
  options?: UseMutationOptions< any, Error, UpdateMeasurementDetailsType>
) => {
  const navigate = useNavigate();
  return useMutation({
    ...options,
    mutationKey: ['addMesurementOrder'],
    mutationFn: async ({ uuid, date, start, end, comment, employeeUuid }) => {
      return axiosInstance.put(`/orders/determining-measurement/${uuid}`, {
        date,
        start,
        end,
        comment,
        employeeUuid,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie aktualizacji pomiaru...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'update-measurement',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Umówiono pomiar pomyślnie',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'update-measurement',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      navigate(ROUTE_ENUM.ORDERS_LIST);
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas umawiania pomiaru',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'update-measurement',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddMeasurementOrder;