import { useState, useEffect, useRef } from "react";
import SVG from "components/SVG";
import "./table-column-header-drop-down.scss";
import { SVG_TYPE } from "enums";
import { useSearchParams } from "react-router-dom";

interface TableColumnHeaderDropDownType {
  label: string;
  select: any;
  itemOnChange: (data: any) => void;
  parameter: string;
  removeActiveItem: () => void;
}

const TableColumnHeaderDropDown = ({
  label,
  select,
  itemOnChange,
  parameter,
  removeActiveItem,
}: TableColumnHeaderDropDownType) => {
  const [isExpand, setIsExpand] = useState(false);
  const [activeItem, setActiveItem] = useState({ label: "", value: "" });
  const [searchParams, setSearchParams] = useSearchParams();
  const linkParam = searchParams.get(parameter);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (select && select.length > 0) {
      const activeSelectElement = select?.find(
        (item: any) => item.value === linkParam
      );
      setActiveItem(activeSelectElement);
    }
  }, [select, linkParam]);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsExpand(false);
    }
  };

  useEffect(() => {
    if (isExpand) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpand]);

  const handleClickItem = (item: any) => {
    setIsExpand(false);
    itemOnChange(item);
    setActiveItem(item);
  };

  const handleRemoveActiveItem = () => {
    setActiveItem({ label: "", value: "" });
    removeActiveItem();
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(parameter);
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <div className="tchdd" ref={ref}>
      <div className="tchdd__header">
        <div className="tchdd__label" onClick={() => setIsExpand(!isExpand)}>
          {label}
          {activeItem?.label ? `: ${activeItem?.label}` : ""}
        </div>
        {activeItem?.label ? (
          <div className="tchdd__icons">
            <div
              className="tchdd__icon"
              onClick={() => handleRemoveActiveItem()}
            >
              <SVG type={SVG_TYPE.CLOSE} />
            </div>
            <div className="tchdd__icon" onClick={() => setIsExpand(!isExpand)}>
              <SVG type={SVG_TYPE.CHEVRON_DOWN} />
            </div>
          </div>
        ) : (
          <div className="tchdd__icons">
            <div className="tchdd__icon" onClick={() => setIsExpand(!isExpand)}>
              <SVG type={SVG_TYPE.CHEVRON_DOWN} />
            </div>
          </div>
        )}
      </div>
      <div
        className={`tchdd__select ${isExpand ? "tchdd__select--expand" : ""}`}
      >
        {select &&
          select.length > 0 &&
          select.map((item: { value: string; label: string }) => {
            return (
              <div
                key={item.value}
                className="tchdd__select-item"
                onClick={() => handleClickItem(item)}
              >
                {item.label}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TableColumnHeaderDropDown;
